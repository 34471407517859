import { cx } from 'lib/utils';

import styles from './heading.module.scss';

export const Heading = ({ children, variant, component, className, ...props }) => {
  const Component = component ?? variant;
  return (
    <Component className={cx(styles.heading, `${styles.heading}-${variant}`, className)} {...props}>
      {children}
    </Component>
  );
};
