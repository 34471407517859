import { HTMLProps } from 'react';

import { LinkProps } from 'next/link';

import { tw } from 'lib/utils';

import HtLink from './ht-link';

type Variant = 'primary' | 'secondary' | 'dark';
export const Link = ({
  children,
  href,
  variant = 'primary',
  rel = 'noopener noreferrer nofollow',
  shouldOpenNewTab = false,
  ...rest
}: LinkProps & HTMLProps<HTMLAnchorElement> & { variant: Variant; shouldOpenNewTab?: boolean }) => (
  <section className="contents">
    <HtLink
      href={href}
      rel={rel}
      target={shouldOpenNewTab ? '_blank' : '_self'}
      className={tw(
        'w-fit text-[1rem] leading-[21px] text-navy-40 hover:text-navy hover:underline active:text-lapis active:no-underline',
        variant === 'secondary' && 'text-navy',
        variant === 'dark' && 'hover:text-white'
      )}
      {...rest}
    >
      {children}
    </HtLink>
  </section>
);
