import * as React from 'react';

import { Button } from 'components/common-n4/button';
import logger from 'lib/logger';

const log = logger({ category: 'ProviderFlow-disclaimer' });

import { ProviderPopupLayout } from '../../layout';
import { StepIndicator } from '../../step-indicator';
import { ContentProps, UserAction } from '../../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Disclaimer: React.FC<ContentProps> = ({ dispatch, state, handleClose }) => {
  const onClose = () => {
    log.info('no-thanks');
    if (handleClose) {
      handleClose();
    }
  };
  return (
    <ProviderPopupLayout currentSlide={3}>
      <div className="flex flex-col items-center justify-between gap-14 px-7 py-10 lg:gap-0 lg:px-10 lg:py-6">
        <StepIndicator steps={4} currentStep={3} />
        <div className="">
          <div className="flex items-center justify-center text-center text-[32px] font-medium leading-9 tracking-tight text-navy lg:mt-10">
            Just one last thing!
          </div>
          <div className="mt-4 text-center text-[18px] font-light leading-5 tracking-tight text-navy-80">
            Before we call you with the names of local providers, we&apos;re required to show you this message:
          </div>
          <div className="mt-[32px] text-center text-[18px] font-light leading-5 tracking-tight text-navy lg:mt-12">
            By clicking “I got it” below, I agree to receive a phone call from HearingTracker&apos;s trusted discount partner.
          </div>
          <div className="mt-[16px] px-8 text-center text-[18px] font-light italic leading-5 tracking-tight text-navy lg:mt-6">
            (Don&apos;t worry, we&apos;ll only call once and there&apos;s no sales pitch)
          </div>
        </div>

        <div className="flex gap-2 self-stretch lg:mt-10">
          <Button.Primary
            className="!w-full !px-6 !py-[10px] !text-base !tracking-tight"
            buttonType="submit"
            onClick={() => dispatch({ type: UserAction.submit })}
          >
            I got it!
          </Button.Primary>
          <Button.Primary className="!w-full !bg-navy-5 !px-6 !py-[10px] !text-base !tracking-tight !text-navy" onClick={onClose}>
            No thanks
          </Button.Primary>
        </div>
      </div>
    </ProviderPopupLayout>
  );
};
