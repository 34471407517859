import React, { useState } from 'react';

import { storyblokEditable, type SbBlokData } from '@storyblok/react';

import fonts from 'components/layout/fonts';
import { useApiData } from 'hooks/use_api_data';
import { cx } from 'lib/utils';
import { Release } from 'types/release';

import styles from './hearing-aids.module.scss';

import { MultiCarousel } from '../multi-carousel/multi-carousel';
import { ProductCard } from '../product-card/product-card';

interface HearingAidsTabsProps {
  tag: string;
  selectedTag: string;
  onClick: (tag: string) => void;
}

const HearingAidsTabs: React.FC<HearingAidsTabsProps> = ({ tag, selectedTag, onClick }) => {
  return (
    <div className={cx(styles['tab-btn'], styles[`tab-btn${selectedTag === tag ? '--active' : ''}`])} onClick={() => onClick(tag)}>
      {tag}
    </div>
  );
};

interface ProductCarouselProps {
  products: Release[];
  tag?: string;
}

/*
// This interface needs to be fleshed out more; I just did the parts we need to
// get from the api release to the product card.
interface ReleaseTypeTemp {
  id: number;
  score?: number;
  audio: Audio;
  score_description: string;
  model_count: number;
  slug: string;
  name: string;
  image: ImgixImageProps;
  url: string;
  title: string;
  tags: string[];
  description: string;
}

const convertProduct = (release: ReleaseTypeTemp): ProductCardProps => ({
  id: release.id,
  slug: release.slug,
  score: release.score ? { amount: release.score, progress: (release.score / 5) * 100, size: 'small' } : undefined,
  url: `/hearing-aids/${release.slug}`,
  title: release.name,
  className: 'huh',
  description: release.score_description,
  audio: release.audio,
  image: release.image,
  sceneId: 'cafe',
});
*/

const ProductCarousel: React.FC<ProductCarouselProps> = ({ products, tag }) => {
  const productCardConfig = {
    mobile: { width: 357, gap: 20 },
    desktop: { width: 357, gap: 32 },
    wide: { width: 424, gap: 32 },
  };
  return (
    <MultiCarousel
      itemSpaceConfigs={productCardConfig}
      className="hearing-aids-carousel"
      items={products.map((product: Release) => (
        <ProductCard release={product} sceneId="cafe" key={product.slug} tag={tag} />
      ))}
    />
  );
};

interface Blok extends SbBlokData {
  title: string;
}

interface HearingAidsProps {
  blok: Blok;
}

interface Tag {
  id: number;
  description?: string;
  tag: string;
  configuration: Record<string, number | boolean>;
}

export const HearingAids: React.FC<HearingAidsProps> = ({ blok }) => {
  const apiData = useApiData();
  const releases: Release[] = apiData.releases;
  const tags: Tag[] = apiData.tags.filter((tag: Tag) => tag.configuration.home);
  const [selectedTag, setSelectedTag] = useState<string>(tags[0]?.tag);
  const products = releases.filter((release) => release.tags.some((t: string) => t === selectedTag));

  const handleTabClick = (tag: string) => {
    setSelectedTag(tag);
  };

  return (
    <section className={cx(styles['ht-hearing-aid'])} {...storyblokEditable(blok)}>
      <h2 className="font-semibold xs:mb-8 xs:text-[40px] xs:leading-[48px] xs:tracking-[-0.03em] md:mb-12 md:text-[2.5em] laptop:text-[3.5em]">
        Find the right hearing aids for you
      </h2>
      <div className={cx(fonts.hankenGrotesk.className, styles['ht-hearing-aid-header'])}>
        <div className={cx(styles['ht-hearing-aid-header-title'])}>{blok.title}</div>
        <div className={cx(styles['ht-hearing-aid-header-tabs'], 'mb-14')}>
          {tags.map((tag: Tag, index: number) => (
            <HearingAidsTabs key={index} tag={tag.tag} selectedTag={selectedTag} onClick={handleTabClick} />
          ))}
        </div>
      </div>

      <div className={cx(styles.carousel)}>{products && <ProductCarousel products={products} tag={selectedTag} />}</div>
    </section>
  );
};
