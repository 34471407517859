import * as React from 'react';

import * as ArticleFeatureCardPrimitives from './article-feature-card-primitives';

export type ArticleFeatureCardProps = React.PropsWithChildren<
  ArticleFeatureCardPrimitives.RootProps & ArticleFeatureCardPrimitives.HeaderProps & ArticleFeatureCardPrimitives.ContentProps
>;
export const ArticleFeatureCard: React.FC<ArticleFeatureCardProps> = ({ image, preload, url, score, title, body, children, ...props }) => (
  <ArticleFeatureCardPrimitives.Root url={url} {...props}>
    <ArticleFeatureCardPrimitives.Header image={image} preload={preload} url={url} score={score} />
    <ArticleFeatureCardPrimitives.Content>
      {!children && (
        <>
          <ArticleFeatureCardPrimitives.ContentTitle>{title}</ArticleFeatureCardPrimitives.ContentTitle>
          <ArticleFeatureCardPrimitives.ContentBody>{body}</ArticleFeatureCardPrimitives.ContentBody>
        </>
      )}
      {children}
    </ArticleFeatureCardPrimitives.Content>
  </ArticleFeatureCardPrimitives.Root>
);
