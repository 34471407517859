import ImageWrapper from 'components/common-n4/image';
import { ImageSource } from 'components/common-n4/image/types';
import { tw } from 'lib/utils';

interface ModelLevelButtonProps {
  title: string;
  image?: ImageSource;
  selected: boolean;
}

export const ModelLevelButton: React.FC<ModelLevelButtonProps> = ({ title, image, selected }) => {
  return (
    <div
      className={tw(
        'flex min-h-[80px] items-center rounded-[10px] px-3 py-2 shadow-[0_0_0_1px_#E7E9EC] laptop:px-[24px] laptop:pb-[8px] laptop:pt-[10px]',
        selected && 'shadow-[0_0_0_3px_#4A7FE5]'
      )}
    >
      {image && (
        <ImageWrapper
          image={image}
          sizingProps={{ fixed: true, fixedWidth: 64, fixedHeight: 64 }}
          imgProps={{ className: 'px-1 object-contain' }}
          origin="expert-review"
        />
      )}
      <div className="ml-2 text-left">
        <div className="laptop:leading-[120%]">
          <span className="text-[14px] font-light leading-normal tracking-tight text-navy laptop:whitespace-nowrap laptop:text-base laptop:font-normal">
            {title}
          </span>
        </div>
      </div>
    </div>
  );
};
