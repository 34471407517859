import { storyblokEditable } from '@storyblok/react';
import NextLink from 'next/link';

import ImageWrapper from 'components/common-n4/image';
import logger from 'lib/logger';

const log = logger({ category: 'hero' });

const Hero = ({ blok }) => {
  log.debug('blok: %o', blok);
  const images = blok.image || [];
  return (
    <section {...storyblokEditable(blok)} className={`ht-hero ${blok.className} ${blok.image_left ? 'ht-hero-left' : 'ht-hero-right'}`}>
      <div className="ht-section-layout">
        <article>
          <div className="ht-article-layout">
            <header>
              <div className="ht-header-layout">
                {blok.h1 && <h1>{blok.title}</h1>}
                {!blok.h1 && <h2>{blok.title}</h2>}
                <p className="h3">{blok.subtitle}</p>
                {(blok.buttonLinks || []).map((b, idx) => (
                  <NextLink prefetch={false} key={b._uid} href={b.link.url} className={`hero-link ${idx === 0 ? 'btn-primary' : ''}`}>
                    {b.label}
                  </NextLink>
                ))}
              </div>
            </header>
            <div className="ht-figure-layout">
              {images.length === 1 && (
                <figure>
                  <div className="ht-image-layout">
                    <ImageWrapper image={blok.image[0]} origin="hero" />
                  </div>
                </figure>
              )}
              {images.length > 1 && (
                <div className="ht-multi-image-layout">
                  {images.map((image) => (
                    <figure key={image._uid} className={image.className}>
                      <div className="ht-image-layout">
                        <ImageWrapper image={image} origin="hero" />
                        {/* eslint-disable react/no-danger */}
                        {image.text_overlay && <div dangerouslySetInnerHTML={{ __html: image.text_overlay }} />}
                        {/* eslint-enable react/no-danger */}
                      </div>
                    </figure>
                  ))}
                </div>
              )}
            </div>
          </div>
        </article>
      </div>
    </section>
  );
};

export default Hero;
