import * as React from 'react';

import { RadioGroup } from '@headlessui/react';

import { ImageSource } from 'components/common-n4/image/types';
import { StyledScrollbar } from 'components/common-n4/styled-scrollbar';
import { Level } from 'types/release';

import { ModelLevelButton } from './model-level-button';

interface SelectLevelProps {
  levels: Level[];
  selectedLevel: Level | null;
  onLevelSelected: (level: Level) => void;
  image: ImageSource | undefined;
}

const notSureOption = { name: 'not_sure' };

export const SelectLevel: React.FC<SelectLevelProps> = ({ levels, selectedLevel, onLevelSelected, image }) => {
  const [showShadow, setShowShadow] = React.useState(true);
  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleScroll = () => {
      const element = contentRef.current;
      if (element) {
        const isAtBottom = element.scrollHeight - element.scrollTop <= element.clientHeight;
        setShowShadow(!isAtBottom);
      }
    };

    const element = contentRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial check

      return () => element.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <div className="relative flex flex-1 overflow-hidden pb-6 laptop:block">
      <StyledScrollbar className="mt-[42px] w-full px-[26px] py-2 laptop:mt-[52px] laptop:h-[290px] laptop:px-[70px]" ref={contentRef}>
        <RadioGroup value={selectedLevel} onChange={onLevelSelected} className="flex-1">
          <div className="grid grid-cols-2 gap-x-[14px] gap-y-[20px] laptop:grid-cols-3 laptop:gap-5">
            {levels.map((level, index) => (
              <RadioGroup.Option key={index} value={level} className="cursor-pointer">
                {({ checked }) => <ModelLevelButton title={level.name} image={image} selected={checked} />}
              </RadioGroup.Option>
            ))}
            <RadioGroup.Option value={notSureOption} className="cursor-pointer">
              {({ checked }) => <ModelLevelButton title="I'm not sure" selected={checked} />}
            </RadioGroup.Option>
          </div>
        </RadioGroup>
      </StyledScrollbar>
      {showShadow && <div className="pointer-events-none absolute bottom-0 left-0 right-0 h-[150px] bg-gradient-to-t from-white"></div>}
    </div>
  );
};
