/* eslint-disable react/no-danger */
import { storyblokEditable } from '@storyblok/react';

const HTML = ({ blok }) => (
  <section
    {...storyblokEditable(blok)}
    data-name={blok.name}
    className="my-12 flex w-full flex-col justify-between 
      rounded-[20px] bg-white p-[24px_20px_40px_20px] shadow-xl md:max-w-[810px] md:flex-row 
      md:p-[32px_32px_40px_32px] desktop-xl:rounded-[22px] desktop-xl:p-[36px_36px_45px_36px]"
  >
    <div dangerouslySetInnerHTML={{ __html: blok.code }} />
  </section>
);

export default HTML;
