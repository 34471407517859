import { ReactElement } from 'react';

import startCase from 'lodash/startCase';

import OptimizedImage from './optimized-image';
import type { ImageUrlBuilderProps, ImageProps } from './types';

const toUrl = (builderProps: ImageUrlBuilderProps): string => {
  const { baseUrl, width, height, quality } = builderProps;
  // parse storyblok url and add parameters;
  // https://a.storyblok.com/f/45415/1200x628/e13dbaed23/best-hearing-aids-2022.jpg"
  // ["https:", "", "a.storyblok.com", "f", "45415", "1200x628", "e13dbaed23", "best-hearing-aids-2022.jpg"]
  //    0        1   2                  3     4        5            6             7
  const [, , , f, spaceId, dims, uid, name] = baseUrl.split('/');
  const url = ['https://a.storyblok.com', f, spaceId, dims, uid, name, 'm', `${width}x${height || 0}`, `filters:quality(${quality || 40})`].join('/');
  return url;
};

const SbLegacyImage = (props: ImageProps): ReactElement => {
  // this takes in an Asset from storyblok and returns an optimized image
  const { image, imgProps, sizingProps, origin = 'SbAssetImage', ...rest } = props;
  const src = image as string;
  const [, , , , , dims, , name] = src.split('/');
  const [nativeWidth, nativeHeight] = dims.split('x').map((d) => Number(d));
  const mergedImgProps = { ...imgProps, alt: imgProps?.alt || startCase(name.split('.')[0]) };
  const mergedSizingProps = { toUrl, ...sizingProps, nativeWidth, nativeHeight };
  if (nativeWidth && nativeHeight) {
    const aspectRatio = (1.0 * nativeWidth) / nativeHeight;
    if (Number.isNaN(aspectRatio)) {
      console.error('Bad aspect ratio for sb asset image: %s: nw: %o, nh: %o', src, nativeWidth, nativeHeight);
    } else {
      Object.assign(mergedSizingProps, { aspectRatio });
    }
  }
  return <OptimizedImage {...rest} image={src} sizingProps={mergedSizingProps} imgProps={mergedImgProps} origin={origin} />;
};

export default SbLegacyImage;
