import { storyblokEditable } from '@storyblok/react';

import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants.js';
import { ArticleFeatureCard } from 'components/common-n4/article-feature-card/article-feature-card';
import { useApiData } from 'hooks/use_api_data';

import styles from './hero-reviews.module.scss';

const HomeHearingAid = ({ blok }) => {
  const { models } = useApiData();
  const model = models[blok.form_factor];

  return (
    <ArticleFeatureCard
      className={styles.secondaryArticleCard}
      image={model.release.transparent_image || model.release.image}
      title={model.release.full_name}
      body={model.release.description}
      url={model.release.path}
      score={{
        amount: model.score,
        progress: (model.score / HEARING_AID_MODEL_SCORE_TOTAL) * 100,
      }}
      {...storyblokEditable(blok)}
    />
  );
};

export default HomeHearingAid;
