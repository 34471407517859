import React from 'react';

import { storyblokEditable, type SbBlokData } from '@storyblok/react';

import HtLink from 'components/common-n4/ht-link';
import ImageWrapper from 'components/common-n4/image';
import { ImageSource } from 'components/common-n4/image/types';
import { normalizeUrl } from 'lib/utils';

interface Model {
  id: number;
  name: string;
  full_name: string;
  slug: string;
}

interface Release {
  id: number;
  slug: string;
  name: string;
  full_name: string;
  path: string;
  image?: ImageSource;
  transparent_image?: ImageSource;
  models?: Model[];
}

interface AccessoryFunction {
  id: number;
  name: string;
  description?: string;
}

export interface AccessoryCardProps {
  id: number;
  image?: ImageSource;
  name: string;
  description?: string;
  price?: string;
  purchaseLink?: string;
  functions?: AccessoryFunction[];
  releases: Release[];
  blok: SbBlokData;
}

const AccessoryCard: React.FC<AccessoryCardProps> = ({ id, image, name, description, price, functions, releases, blok }) => {
  return (
    <section
      data-accessory-id={id}
      className="my-12 flex w-full max-w-full flex-col justify-between
    rounded-[20px] bg-white p-[24px_20px_40px_20px] shadow-xl md:w-[44.625rem] md:flex-row 
    md:p-[32px_32px_40px_32px] lg:w-[810px] desktop-xl:rounded-[22px] desktop-xl:p-[36px_36px_45px_36px]"
      {...storyblokEditable(blok)}
    >
      {image && (
        <div className="flex w-[300px] sm:items-start md:justify-center">
          <ImageWrapper
            image={image}
            origin="accessory-card"
            imgProps={{
              className: 'h-auto object-contain lg:w-[230px] w-[180px] max-h-[270px]',
            }}
          />
        </div>
      )}
      <div className="flex-start flex flex-col md:w-[450px] lg:w-[510px]">
        <span
          className="text-[32px] font-medium leading-[1.3] tracking-[-0.32px] 
        desktop-xl:text-[36px] desktop-xl:tracking-[-0.36px]"
        >
          {name}
        </span>
        {price && (
          <span
            className="mt-[6px] text-[21px] font-medium leading-[41.6px] tracking-[-0.62px] 
        text-lapis desktop-xl:mt-[7px] desktop-xl:text-[24px] desktop-xl:leading-[47px] desktop-xl:tracking-[-0.71px]"
          >
            Starting at ${price} USD each
          </span>
        )}
        {description && (
          <span
            className="mt-3 text-base font-light leading-[1.4] tracking-[-0.48px] 
        desktop-xl:text-[18px] desktop-xl:tracking-[-0.54px]"
          >
            {description}
          </span>
        )}

        <div
          className="no-scrollbar ::my-[32px] my-[24px] flex flex-wrap 
        items-start gap-[6px]  overflow-x-auto pt-4 md:pt-0 desktop-xl:my-[36px]"
        >
          {functions?.map((func) => (
            <span
              data-generic-accessory-id={func.id}
              title={`TODO: someone make this a popver: ${func.description}` /* TODO: make this a popup and add cursor pointer if present*/}
              key={func.id}
              className="flex w-max min-w-fit gap-2 rounded-[60px] bg-navy/5 p-2 px-4 text-[14px] font-medium
               leading-[1.4] tracking-[-0.42px] desktop-xl:text-[16px] desktop-xl:tracking-[-0.48px]"
            >
              {func.name}
            </span>
          ))}
        </div>
        {releases.length > 0 && (
          <div className="flex flex-col items-start gap-5 self-stretch border-t border-gray pt-6">
            <span
              className="text-[20px] font-medium leading-[1.4] tracking-[-0.6px] text-navy md:text-[18px] 
            md:leading-[1.2] md:tracking-[-0.54px]"
            >
              Compatible with:
            </span>
            <div className="flex flex-col items-center gap-5 self-stretch md:gap-2">
              {releases?.map((release) => (
                <HtLink
                  href={normalizeUrl({ url: release.path, origin: 'accessory/card' })}
                  key={release.slug}
                  className="flex w-full cursor-pointer items-center gap-2 rounded-[10px] hover:bg-navy/5 
                  md:rounded-[20px] md:p-3 desktop-xl:gap-[10px] desktop-xl:rounded-[22px] desktop-xl:p-[14px]"
                >
                  {(release.transparent_image || release.image) && (
                    <ImageWrapper
                      image={(release.transparent_image || release.image)!}
                      origin="accessory-card"
                      imgProps={{ className: 'w-[70px] desktop-xl:w-[80px] h-[70px] desktop-xl:h-[80px] object-contain' }}
                    />
                  )}
                  <div className="flex flex-col gap-1">
                    <span
                      className="w-full overflow-hidden text-[18px] font-semibold leading-[1.3] tracking-[-0.54px] text-navy 
                    desktop-xl:text-[20px] desktop-xl:tracking-[-0.6px]"
                    >
                      {release.name}
                    </span>
                    {release.models && (
                      <span
                        className="overflow-hidden text-[14px] font-medium leading-[1.4] tracking-[-0.42px] 
                      text-navy-60 desktop-xl:text-[16px] desktop-xl:tracking-[-0.47px]"
                      >
                        {release.models.map((m) => m.name).join(', ')}
                      </span>
                    )}
                  </div>
                </HtLink>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default AccessoryCard;
