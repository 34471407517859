import { storyblokEditable } from '@storyblok/react';

import ImageWrapper from 'components/common-n4/image';
import logger from 'lib/logger';
import { cx } from 'lib/utils';

// Can share from container if there are not many styles, otherwise use separate module per component
import styles from './home-container.module.scss';

const log = logger({ category: 'n4/home/testimonial' });

const Testimonial = ({ blok, story, ...props }) => {
  log.debug('blok: %o', { blok, props });
  return (
    <section className={cx(styles.testimonial, blok.className)} {...storyblokEditable(blok)} style={{ border: '1px solid lime', margin: 20 }}>
      <h3>Testimonial</h3>
      <ul>
        <li>title: {blok.title}</li>
        <li>name: {blok.name}</li>
        <li>text: {blok.text}</li>
      </ul>
      <ImageWrapper image={blok.image} imgProps={{ style: { width: 200, height: 'auto' } }} origin="home-testimonial" />
    </section>
  );
};

export default Testimonial;
