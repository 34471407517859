import { storyblokEditable } from '@storyblok/react';

import { ArticleFeatureCard } from 'components/common-n4/article-feature-card/article-feature-card';
import logger from 'lib/logger';

const log = logger({ category: 'n4/home/article' });

const HomeArticle = ({ blok, story, ...props }) => {
  log.debug('blok: %o', { blok, props });
  return <ArticleFeatureCard image={blok.image} title={blok.title} body={blok.description} url={blok.link.cached_url} {...storyblokEditable(blok)} />;
};

export default HomeArticle;
