import React, { type ReactEventHandler } from 'react';

import logger from 'lib/logger';

const log = logger({ category: 'UseImageLoader' });

export interface UseImageLoaderProps {
  ref: React.RefObject<HTMLImageElement>;
  onLoaded?: (debug: string) => void;
  origin: string;
}

const useImageLoader = (props: UseImageLoaderProps): ReactEventHandler<HTMLImageElement> | undefined => {
  const { ref, onLoaded, origin } = props;
  const [onLoadCalled, setOnLoadCalled] = React.useState(false);

  // Sometimes img.onload isn't called, if the image is cached or broken
  // We need to make sure onLoad is always called so set a timer and call
  // it if it wasn't previously called
  const localOnLoad: ReactEventHandler<HTMLImageElement> = React.useCallback(() => {
    if (onLoaded) {
      log.silly('onload (%s): local onload being called', origin);
      if (!onLoadCalled) {
        log.silly('onload (%s): calling normal onload due to image loaded', origin);
        onLoaded('normal onload');
        setOnLoadCalled(true);
      }
    }
  }, [onLoaded, onLoadCalled, origin]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;
    if (onLoaded && !onLoadCalled) {
      let tries = 0;
      const fn = () => {
        log.silly('onload (%s): in timer', origin);
        if (onLoadCalled) {
          log.silly('onload (%s): already called in timer', origin);
        } else if (ref.current?.complete) {
          log.silly('onload (%s): image complete and not already called so calling onload', origin);
          onLoaded('image complete onload');
          setOnLoadCalled(true);
        } else {
          tries += 1;
          if (tries > 10) {
            log.silly('onload (%s): still not called after 5 tries so calling it', origin);
            onLoaded('timed out onload');
            setOnLoadCalled(true);
          } else {
            // Try again in 3 seconds
            timer = setTimeout(fn, 1000);
          }
        }
      };
      timer = setTimeout(fn, 1000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [onLoaded, onLoadCalled, ref, origin]);

  return onLoaded ? localOnLoad : undefined;
};

export default useImageLoader;
