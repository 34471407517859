/*
 * This component can handle any of our types of images.
 *
 * <Image image={blok.uberImage } /> // storyblock uber image
 * <Image image={blok.asset} /> // storyblock asset
 * <Image image={blok.image} /> // storyblock legacy image; just has image as string url
 * <Image image={imgixImage} /> // imgix image
 * <Image image={`base64-encoded data`} />
 * <Image image={url} /> // anything else
 */
import { ReactElement } from 'react';

import { StaticImageData } from 'next/image';

import initTracer from 'lib/tracer';

import ImgixImage from './imgix-image';
import LocalImage from './local-image';
import OptimizedImage from './optimized-image';
import SbAssetImage from './sb-asset-image';
import SbLegacyImage from './sb-legacy-image';
import SbUberImage from './sb-uber-image';
import type { ImageProps, ImgixImageProps, AssetStoryblok, UberImageStoryblok } from './types';

const Image = (props: ImageProps): ReactElement | null => {
  const { image, origin, tracer = initTracer() } = props;
  tracer.trace('Image (%s): %o', origin, props);
  try {
    let imageType = null;
    let img: ReactElement | null = null;
    if ((image as UberImageStoryblok)?.component === 'UberImage') {
      imageType = 'uber';
      tracer.trace('found a storyblok uber image');
      const uberImage = image as UberImageStoryblok;
      tracer.trace('calling uberimage with %o -> %d', uberImage, tracer.raw.length);
      img = SbUberImage({ ...uberImage, tracer });
    } else if ((image as ImgixImageProps)?.imgix) {
      imageType = 'imgix';
      tracer.trace('found an imgix image');
      img = ImgixImage({ ...props, tracer });
    } else if (typeof image === 'string' && /storyblok/.test(image as string)) {
      imageType = 'legacy';
      tracer.trace('found a storyblok legacy image');
      img = SbLegacyImage({ ...props, tracer });
    } else if ((image as AssetStoryblok)?.filename) {
      imageType = 'asset';
      tracer.trace('found a storyblok asset image');
      img = SbAssetImage({ ...props, tracer });
    } else if (typeof image === 'string' && /http/.test(image as string)) {
      imageType = 'external';
      tracer.trace('found an external image');
      img = OptimizedImage({ ...props, tracer });
    } else if (typeof image === 'string' && /data.*base64/.test(image as string)) {
      imageType = 'base64';
      tracer.trace('found an inline image');
      img = OptimizedImage({ ...props, tracer });
    } else if (typeof image === 'string') {
      imageType = 'local';
      tracer.trace('found a local image');
      img = LocalImage({ ...props, tracer });
    } else if ((image as StaticImageData).src) {
      imageType = 'static';
      tracer.trace('found a static image');
      const { src } = image as StaticImageData;
      img = OptimizedImage({ ...props, tracer, image: src });
    } else if (typeof image === 'function') {
      imageType = 'react';
      tracer.trace('found a component image');
      // This works for svgs, but doesn't allow for any optimization so not very useful
      const MustBeSvg = image;
      if (props.sizingProps?.fixed) {
        const { fixedWidth, fixedHeight } = props.sizingProps;
        return (
          <div style={{ width: fixedWidth, height: fixedHeight }}>
            <MustBeSvg data-origin={origin} {...props.imgProps} />
          </div>
        );
      }
      return <MustBeSvg data-origin={origin} />;
    } else {
      imageType = 'unknown';
      tracer.trace('Could not find matching image type');
    }
    if (img === null) {
      // TODO: maybe return a generic dummy image
      tracer.trace('ERROR: null image: %o', image);
    }
    tracer.trace('image type: %s', imageType);
    return img;
  } catch (ex: unknown) {
    if ((ex as Error).message !== undefined) {
      tracer.trace('Exception in image: %s', (ex as Error).message);
    }
    if (process.env.NODE_ENV !== 'production') {
      throw ex;
    }
  } finally {
    tracer.log(origin);
  }
  return null;
};

export default Image;
