import { storyblokEditable } from '@storyblok/react';

import ImageWrapper from 'components/common-n4/image';
import logger from 'lib/logger';
import { cx } from 'lib/utils';

// Can share from container if there are not many styles, otherwise use separate module per component
import styles from './home-container.module.scss';

const log = logger({ category: 'n4/home/multi-cta-card' });

const MultiCtaCard = ({ blok, story, ...props }) => {
  log.debug('blok: %o', { blok, props });
  return (
    <section className={cx(styles['multi-cta-card'], blok.className)} {...storyblokEditable(blok)} style={{ border: '1px solid pink', margin: 20 }}>
      <h3>Multi-CTA Card</h3>
      <ul>
        <li>title: {blok.title}</li>
        <li>link text: {blok.link_text}</li>
        <li>link: {blok.link.cached_url}</li>
        <li>wide: {blok.wide}</li>
      </ul>
      <ImageWrapper image={blok.image} imgProps={{ style: { width: 200, height: 'auto' } }} origin="home-multi-cta-card" />
    </section>
  );
};

export default MultiCtaCard;
