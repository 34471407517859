import { tw } from 'lib/utils';

interface CustomDotProps {
  onClick?: () => void;
  active?: boolean;
}

export const CustomDot: React.FC<CustomDotProps> = ({ active, onClick }) => {
  return (
    <li className="flex-1">
      <button className={tw('h-[5px] w-full rounded-full bg-navy/20', active && 'bg-navy')} onClick={onClick}></button>
    </li>
  );
};
