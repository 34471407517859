/* eslint-disable @next/next/no-img-element */
import React from 'react';

import ImageWrapper from 'components/common-n4/image';
import logger from 'lib/logger';

const log = logger({ category: 'UberImage' });

const UberImage = ({ blok }) => {
  log.debug('blok %o', blok);
  return <ImageWrapper image={blok} origin="sb-uberimage" />;
};

export default UberImage;
