import * as React from 'react';

import { Button } from 'components/common-n4/button';
import { tw, validateEmail } from 'lib/utils';

import { ContentProps, ErrorCode, Step, SystemAction, UserAction } from '../../types';

export const Subscribe: React.FC<ContentProps> = ({ dispatch, state }) => {
  const emailInputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    emailInputRef.current?.focus();
  }, []);

  const handleSubmit = () => {
    if (!state.email) {
      dispatch({
        type: SystemAction.error,
        payload: { error: { code: ErrorCode.subscribe_error_no_email, message: 'Please provide your email', field: 'email' } },
      });
    } else if (!validateEmail(state.email)) {
      dispatch({
        type: SystemAction.error,
        payload: { error: { code: ErrorCode.subscribe_error_bad_email, message: 'Please provide a valid email such as jane.doe@gmail.com', field: 'email' } },
      });
    } else {
      dispatch({ type: UserAction.submit });
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    dispatch({ type: UserAction.change, payload: { email: value } });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className="flex min-h-[46dvh] max-w-full flex-col items-center justify-between p-10 lg:h-[530px] lg:w-[600px] lg:px-[104px] lg:py-8">
      <div>
        <div className="mt-5 flex items-center justify-center text-center text-[24px] font-medium leading-[120%] tracking-tight text-navy lg:mt-8 lg:leading-7">
          Sorry, we couldn&apos;t find a nearby provider offering this product.
        </div>
        <div className="mt-[14px] px-10 text-center text-base font-light leading-5 tracking-tight text-navy lg:mt-6 lg:px-0">
          Enter your email to be notified about discounted hearing aids available in <span>{state.postalCode}</span>.
        </div>
        <div className="mt-[40px] self-stretch lg:mt-[60px]">
          {state.error && (
            <div className="mb-[10px] ml-[18px] text-sm" style={{ color: '#c00' }}>
              {state.error.message}
            </div>
          )}
          <input
            className={tw(
              'remove-arrow max-h-[46px] w-full rounded-xl p-[16px] leading-5 shadow-[0_0_0_1px_#E7E9EC] focus:shadow-[0_0_0_2px_#4A7FE5] lg:px-4 lg:py-3',
              state.error && state.error.field === 'email' ? 'shadow-[0_0_0_2px_#ea3323]' : ''
            )}
            type="email"
            id="email"
            placeholder="Your Email address"
            value={state.email}
            onChange={handleEmailChange}
            onKeyDown={handleKeyDown}
            required
            ref={emailInputRef}
          />
          <Button.Primary
            className="mt-6 !w-full !px-6 !py-[12px] !text-[16px] !tracking-tight"
            buttonType="button"
            onClick={handleSubmit}
            isDisabled={state.step === Step.subscribing}
          >
            Submit
          </Button.Primary>
        </div>
      </div>

      <div className="text-[14px] tracking-tight text-navy-90 lg:font-medium">
        Not your ZIP Code?
        <span className="ml-1 cursor-pointer text-lapis underline" onClick={() => dispatch({ type: UserAction.restart, payload: { postalCode: '' } })}>
          Change
        </span>
      </div>
    </div>
  );
};
