import { useMemo, useState } from 'react';

import { ClassNameValue } from 'tailwind-merge';

import Stars from 'components/widgets/stars';
import { tw } from 'lib/utils';

import Star from './star';

const roundScoreToStars = (score: number) => {
  const s = Number(score.toFixed(1)); // scores are now on the 0-5 scale
  const f = Math.floor(s);
  const remainder = s - f;
  if (remainder < 0.25) {
    return f;
  }
  if (remainder > 0.76) {
    if (f + 1 < 5) {
      return f + 1;
    }
    return 5;
  }
  return f + 0.5;
};

interface RatingProps {
  scoreable: { id?: number; score: number };
  className?: ClassNameValue;
  starProps?: object;
  activeStarProps?: object;
  inactiveStarProps?: object;
  useSvgStars?: boolean;
  canSetRating?: boolean;
  userScore?: number;
  onStarClick?: (score: number) => void;
}

const Rating: React.FC<RatingProps> = ({
  scoreable,
  className,
  starProps,
  activeStarProps,
  inactiveStarProps,
  useSvgStars,
  canSetRating,
  userScore,
  onStarClick,
}) => {
  const activeStarPropsMerged = useMemo(() => ({ ...starProps, ...activeStarProps }), [activeStarProps, starProps]);
  const inactiveStarPropsMerged = useMemo(
    () => ({
      fill: '#14254610',
      ...starProps,
      ...inactiveStarProps,
    }),
    [inactiveStarProps, starProps]
  );
  const [virtualStars, setVirtualStars] = useState(0);
  const [isScoreSet, setIsScoreSet] = useState(false);

  const handleStarMouseOver = (index: number) => {
    setVirtualStars(index + 1);
  };

  const handleMouseOut = () => {
    if (isScoreSet) {
      setVirtualStars(userScore || 0);
      return;
    }
    setVirtualStars(0);
  };

  const handleStarClick = (index: number) => {
    setVirtualStars(index + 1);
    onStarClick?.(index + 1);
    setIsScoreSet(true);
  };

  if (!scoreable.score) {
    return null;
  }

  const score = scoreable.score.toFixed(1);
  const stars = roundScoreToStars(scoreable.score);

  return (
    <div className="rating" data-id={`review_${scoreable.id}`} data-score={score} data-score-raw={scoreable.score} data-score-rounded={stars}>
      {useSvgStars ? (
        <ul className={tw('flex gap-[6px]', className)} onMouseOut={handleMouseOut}>
          {[...Array(5)].map((star, index) =>
            canSetRating ? (
              <Star
                key={index}
                {...(index + 1 <= virtualStars ? activeStarPropsMerged : inactiveStarPropsMerged)}
                onClick={() => {
                  handleStarClick(index);
                }}
                onMouseOver={() => {
                  handleStarMouseOver(index);
                }}
              />
            ) : index + 1 - stars === 0.5 ? (
              <div className="relative" key={index}>
                <Star {...inactiveStarPropsMerged} />
                <Star {...activeStarPropsMerged} className="absolute top-0" style={{ clipPath: 'inset(0 50% 0 0)' }} />
              </div>
            ) : (
              <Star key={index} {...(index + 1 <= stars ? activeStarPropsMerged : inactiveStarPropsMerged)} />
            )
          )}
        </ul>
      ) : (
        <Stars max={5} value={stars} className={className} />
      )}
    </div>
  );
};

export default Rating;
