import * as React from 'react';

import { cx } from 'lib/utils';

interface SvgIconProps {
  icon: string;
  spin?: boolean;
  alt?: string;
}
const SvgIcon = ({ icon, spin, className, ...props }: React.ComponentProps<'i'> & SvgIconProps) => (
  <i className={cx(spin && 'spin', `svg-icon icon-${icon}`, className)} {...props} />
);

export default SvgIcon;
