import { storyblokEditable } from '@storyblok/react';

import logger from 'lib/logger';

const log = logger({ category: 'n4/ArticleCard' });

const ArticleCard = ({ blok, story, ...props }) => {
  log.debug('blok: %o', { blok, props, story });
  return (
    <section className={blok.className} {...storyblokEditable(blok)}>
      <h3>Article Card</h3>
      {JSON.stringify(blok)}
    </section>
  );
};

export default ArticleCard;
