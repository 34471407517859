import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import logger from 'lib/logger';
import { cx } from 'lib/utils';

import styles from './centered_content.module.scss';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'CenteredContent' });

// Added container and that centers it, not sure what else there is to do
const CenteredContent = ({ blok, story, ...props }) => (
  <section className={cx(styles['ht-centered-content-wrapper'], blok.className)}>
    <section className={styles['ht-centered-content']}>
      {blok.blocks
        ? blok.blocks.filter((b) => !b.hidden).map((b) => <StoryblokComponent blok={b} story={story} {...props} key={b._uid} {...storyblokEditable(b)} />)
        : null}
    </section>
  </section>
);

export default CenteredContent;
