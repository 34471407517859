/* eslint-disable max-len */
import * as React from 'react';

import * as Popover from '@radix-ui/react-popover';

import { Button } from 'components/common-n4/button';
import SvgIcon from 'components/ui/svg_icon';
import { tw, validatePostalCode } from 'lib/utils';

import styles from './zip.module.scss';

import { ProviderPopupLayout } from '../../layout';
import { StepIndicator } from '../../step-indicator';
import { ContentProps, ErrorCode, Step, SystemAction, UserAction, type Distance } from '../../types';

const distanceOptions = [
  { label: '25 miles', value: 25 },
  { label: '50 miles', value: 50 },
  { label: '75 miles', value: 75 },
  { label: '100 miles', value: 100 },
  { label: '150 miles', value: 150 },
];

interface HTMLInputElementWithVK extends HTMLInputElement {
  virtualKeyboardPolicy: 'auto' | 'manual' | '';
}

export const ZipEntry: React.FC<ContentProps> = ({ dispatch, state }) => {
  const [shouldOpenPopover, setShouldOpenPopover] = React.useState(false);

  const zipCodeInputRef = React.useRef<HTMLInputElementWithVK>(null);

  React.useEffect(() => {
    if ('virtualKeyboard' in navigator) {
      zipCodeInputRef.current!.virtualKeyboardPolicy = 'manual';
    }
    zipCodeInputRef.current?.focus();
    if ('virtualKeyboard' in navigator) {
      zipCodeInputRef.current!.virtualKeyboardPolicy = 'auto';
    }
  }, []);

  const handleSubmit = () => {
    if (!state.postalCode) {
      dispatch({
        type: SystemAction.error,
        payload: { error: { code: ErrorCode.user_error_no_zip, message: 'Please provide your zip code', field: 'postalCode' } },
      });
    } else if (!validatePostalCode(state.postalCode)) {
      dispatch({
        type: SystemAction.error,
        payload: { error: { code: ErrorCode.user_error_bad_zip, message: 'Zip code must be 5 digits', field: 'postalCode', postalCode: state.postalCode } },
      });
    } else {
      dispatch({ type: UserAction.submit });
    }
  };

  const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: postalCode } = event.target;
    /*
    let postalCode = value.replace(/\D/g, '');
    const zipCoderSize = 5;

    if (postalCode.length > zipCoderSize) {
      postalCode = postalCode.substring(0, zipCoderSize);
    }
    */
    dispatch({ type: UserAction.change, payload: { postalCode } });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  };

  const handleSelectDistance = (distance: Distance) => {
    const submitButton = document.getElementById('submit-button');
    setShouldOpenPopover(() => {
      dispatch({ type: UserAction.change, payload: { distance } });
      return false;
    });
    submitButton?.focus();
  };

  return (
    <ProviderPopupLayout currentSlide={1}>
      <div className="flex flex-col items-center justify-center px-7 pb-[30px] pt-10 lg:px-10 lg:py-6">
        <StepIndicator steps={4} currentStep={1} />
        <div className="mt-[24px] flex items-center justify-center text-center text-[32px] font-medium leading-[120%] tracking-tight text-navy lg:mt-8 lg:text-[28px] lg:leading-8">
          First, let&apos;s make sure there&apos;s an affiliated provider near you.
        </div>
        <div className="mt-[14px] flex items-center justify-center text-center text-[18px] font-light leading-[140%] tracking-tight text-navy lg:mt-3 lg:leading-6">
          Everything from testing , to ordering, to follow-up care, takes place at a participating hearing provider&apos;s office near you.
        </div>

        <div className={tw(styles['pop-up-content-zip-code'], 'mt-[30px] self-stretch lg:mt-14')}>
          {state.error && (
            <div className="mb-[10px] ml-[18px] text-sm" style={{ color: '#c00' }}>
              {state.error.message}
            </div>
          )}
          <input
            className={tw(
              state.error?.field === 'postalCode' ? styles.error : '',
              'remove-arrow w-full rounded-xl px-[12px] py-[18px] leading-5 shadow-[0_0_0_1px_#E7E9EC] focus:shadow-[0_0_0_2px_#4A7FE5] lg:max-h-[46px] lg:px-4 lg:py-3'
            )}
            type="text"
            placeholder="Enter ZIP Code"
            onKeyDown={handleKeyDown}
            onChange={handleZipCodeChange}
            value={state.postalCode}
            ref={zipCodeInputRef}
          />
        </div>

        <div className="mt-[24px] flex items-center justify-center gap-3 self-stretch lg:mt-4 lg:justify-start">
          <label htmlFor="options" className="text-center text-[14px] font-light text-navy-95">
            Set distance to:
          </label>
          <Popover.Root open={shouldOpenPopover} onOpenChange={setShouldOpenPopover} defaultOpen={false}>
            <Popover.Trigger asChild>
              <button
                type="button"
                className={tw('flex items-center gap-2 rounded-[10px] border border-[#14254633] bg-white px-[16px] py-[13px] align-middle lg:px-4 lg:py-3')}
              >
                <div className="font-smooth whitespace-nowrap text-base leading-tight text-navy">{state.distance} miles</div>
                <SvgIcon
                  icon="chevron-down-solid"
                  aria-hidden
                  className="relative mb-[1px] h-[10px] w-[10px]"
                  style={{ filter: 'invert(14%) sepia(41%) saturate(941%) hue-rotate(182deg) brightness(91%) contrast(75%)' }}
                />
              </button>
            </Popover.Trigger>
            <Popover.Content side="top" align="start" sideOffset={9} className="z-50">
              <ul className="flex w-[175px] flex-col items-start justify-end rounded-xl border border-[#14254633] bg-white p-2 shadow">
                {distanceOptions.map((dist) => (
                  <li key={dist.label} className="flex w-[159px] rounded-[10px] px-4 py-2 hover:bg-navy-5 active:bg-navy-5">
                    <button
                      type="button"
                      onClick={() => handleSelectDistance(dist.value as Distance)}
                      className="flex h-full w-full justify-start align-middle"
                    >
                      <span className="font-smooth text-sm font-medium leading-tight text-navy">{dist.label}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </Popover.Content>
          </Popover.Root>
        </div>
        <div className="mt-[40px] self-stretch lg:mt-14">
          <Button.Primary
            id={'submit-button'}
            className="!w-full !px-6 !py-[10px] !text-[17px] !tracking-tight"
            onClick={handleSubmit}
            buttonType="button"
            isDisabled={state.step === Step.searching}
          >
            {state.step === Step.searching ? 'Checking...' : 'Check my location'}
          </Button.Primary>
        </div>
      </div>
    </ProviderPopupLayout>
  );
};
