import * as React from 'react';

import { ClassNameValue } from 'tailwind-merge';

import { tw } from 'lib/utils';

interface StepIndicatorProps {
  steps: number;
  currentStep: number;
  className?: ClassNameValue;
}

export const StepIndicator: React.FC<StepIndicatorProps> = ({ steps, currentStep, className }) => {
  return (
    <ul className={tw('flex gap-[32px]', className)}>
      {Array(steps)
        .fill(null)
        .map((_, index) => (
          <li
            key={index}
            className={tw(
              'flex h-[24px] w-[24px] items-center justify-center rounded-full bg-navy-10 text-xs text-navy',
              index > 0 && 'relative before:absolute before:-left-[28px] before:block before:h-[2px] before:w-full before:bg-navy-10',
              currentStep - 1 >= index && 'bg-lapis text-white before:bg-lapis'
            )}
          >
            {index + 1}
          </li>
        ))}
    </ul>
  );
};
