import * as React from 'react';

import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { motion } from 'framer-motion';

import { NavSearch } from 'components/bloks/n4/nav/search';
import { Button } from 'components/common-n4/button';
import { Link } from 'components/common-n4/link';
import { Loader } from 'components/common-n4/loader';
import * as Popover from 'components/common-n4/popover';
import SvgIcon from 'components/ui/svg_icon';
import { useApiData } from 'hooks/use_api_data';
import logger from 'lib/logger';
import { cx, normalizeUrl } from 'lib/utils';

import styles from './panel_container.module.scss';

const log = logger({ category: 'nav/PanelContainer' });

/* eslint-disable-next-line no-unused-vars */
const PanelContainer = ({ blok, story, handleResetNavMenu, isMediumOrLessViewport, ...props }) => {
  log.debug('blok: %o', blok);

  const { articles } = useApiData();
  const { panels } = blok;

  const initialPanels = React.useMemo(
    () =>
      panels
        .map((panel) => {
          if (panel.component === 'nav-article') {
            const article = articles[panel.slug];
            if (article) {
              return { ...panel, ...article };
            }
            return null;
          }
          return panel;
        })
        .filter((ip) => !!ip),
    [panels, articles]
  );

  const { isSearch, searchTerm } = props;
  const [currentPanel, setCurrentPanel] = React.useState();
  const [shouldShowMobilePanel, setShouldShowMobilePanel] = React.useState(false);

  // observe height changes for animation
  const containerRef = React.useRef(null);
  /*
  React.useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        // container height + surrounding padding, ignore for search
      });
    });

    if (containerRef.current && !isMediumOrLessViewport) {
      observer.observe(containerRef.current);
    }

    if (isMediumOrLessViewport) {
      observer.disconnect();
    }

    return () => {
      observer.disconnect();
    };
  }, [isSearch, isMediumOrLessViewport]);
  */

  React.useEffect(() => {
    if (isSearch) {
      return;
    }

    setCurrentPanel(initialPanels?.[0]);
  }, [isSearch, initialPanels]);

  const handleClickPanel = (panel) => {
    log.debug('opening panel: %o', panel);

    setCurrentPanel(() => {
      if (isMediumOrLessViewport) {
        setShouldShowMobilePanel(true);
      }

      return panel;
    });
  };

  const expand =
    story?.full_slug?.endsWith('components/nav') &&
    !/no-expand/.test(story.content.debug) &&
    (typeof window === 'undefined' || !/no-expand/.test(window.location.search));
  const { close } = Popover.usePopoverContext(expand); // ignore popover in expanded mode

  const link = currentPanel?.link?.url || currentPanel?.link?.cached_url || '#';
  const shouldShowMobileOptions = !isSearch && isMediumOrLessViewport && !shouldShowMobilePanel;
  const shouldShowNonMobileOptions = !isSearch && !isMediumOrLessViewport;
  const shouldShowNonMobilePanel = !isMediumOrLessViewport && !shouldShowMobilePanel && !isSearch && currentPanel;
  const shouldShowLoader = !isMediumOrLessViewport && !isSearch && !currentPanel;
  const shouldShowPanelContent = isSearch || shouldShowMobilePanel || shouldShowNonMobilePanel || shouldShowLoader;

  return (
    <motion.section
      initial={{ opacity: 0, height: 0, y: 0 }}
      animate={{
        height: ['fit-content', 'fit-content'],
        y: [0, 12],
        opacity: [0, 1],
      }}
      className={styles.wrapper}
      style={expand ? { minWidth: 0 } : {}}
    >
      <section
        ref={containerRef}
        className={cx(
          styles['panel-container'],
          isMediumOrLessViewport && !isSearch && styles['panel-container--mobile'],
          isSearch && styles['panel-container--search'],
          blok.className
        )}
        style={expand ? { flexDirection: 'column', maxHeight: 10000, border: '0px solid red' } : {}}
        {...storyblokEditable(blok)}
      >
        {/* menu options */}
        {!expand && shouldShowNonMobileOptions ? (
          <section className={styles.options}>
            <section className={styles.menu}>
              <span>{blok.title}</span>
              <ul>
                {initialPanels.map((panel) => (
                  <li key={panel._uid}>
                    <button
                      type="button"
                      className={cx(styles.option, panel._uid === currentPanel?._uid && styles['option--active'])}
                      onClick={() => handleClickPanel(panel)}
                    >
                      {panel.title}
                    </button>
                  </li>
                ))}
              </ul>
            </section>
            {currentPanel && currentPanel.link_text ? (
              <section className={styles['all-options']}>
                <Link href={normalizeUrl({ url: link, origin: 'nav/panel_container' })} onClick={close}>
                  {currentPanel.link_text}
                </Link>
                <Button.IconLink
                  href={normalizeUrl({ url: link, origin: 'nav/panel_container' })}
                  className={styles['option-bottom']}
                  icon="arrow-right-regular"
                  onClick={close}
                />
              </section>
            ) : null}
          </section>
        ) : null}

        {!expand && shouldShowMobileOptions ? (
          <>
            <section className={styles.content}>
              <button
                type="button"
                onClick={() => {
                  setShouldShowMobilePanel(() => {
                    handleResetNavMenu();

                    return false;
                  });
                }}
              >
                <SvgIcon icon="chevron-left-solid" alt="back to initial menu panel" />
                Back
              </button>

              <ul className={styles.menu}>
                {initialPanels.map((panel) => (
                  <li key={panel._uid}>
                    <button
                      type="button"
                      className={cx(styles.option, panel._uid === currentPanel?._uid && styles['option--active'])}
                      onClick={() => handleClickPanel(panel)}
                    >
                      {panel.title}
                      <SvgIcon icon="chevron-right-solid" alt="menu button" />
                    </button>
                  </li>
                ))}
              </ul>
            </section>
            {currentPanel && currentPanel.link_text ? (
              <section className={styles['all-options']}>
                <Link href={normalizeUrl({ url: link, origin: 'nav/panel_container' })} onClick={close}>
                  {currentPanel.link_text}
                </Link>
                <Button.IconLink
                  href={normalizeUrl({ url: link, origin: 'nav/panel_container' })}
                  className={styles['option-bottom']}
                  icon="arrow-right-regular"
                  onClick={close}
                />
              </section>
            ) : null}
          </>
        ) : null}
        {/* selected panel content */}
        {!expand && shouldShowPanelContent && (
          <section key={currentPanel?._uid} className={styles.content}>
            {isSearch ? (
              <NavSearch story={story} initialPanels={initialPanels} searchTerm={searchTerm} isSearch={isSearch} handleResetSearch={handleResetNavMenu} />
            ) : null}

            {shouldShowNonMobilePanel || shouldShowMobilePanel ? (
              <StoryblokComponent
                handleResetPanel={() => setShouldShowMobilePanel(false)}
                blok={currentPanel}
                story={story}
                {...props}
                key={currentPanel?._uid}
                {...storyblokEditable(currentPanel)}
              />
            ) : null}

            {shouldShowLoader ? (
              <div className={styles.loader}>
                <Loader />
              </div>
            ) : null}
          </section>
        )}
        {expand &&
          shouldShowPanelContent &&
          initialPanels.map((panel) => (
            <section
              key={panel?._uid}
              className={styles.content}
              style={expand ? { marginBottom: 20, border: '1px solid #ccc', borderRadius: 4, padding: 10 } : {}}
            >
              {expand && <div style={{ fontSize: '1.5rem' }}>{panel.title}</div>}

              {shouldShowNonMobilePanel || shouldShowMobilePanel || expand ? (
                <StoryblokComponent
                  handleResetPanel={() => setShouldShowMobilePanel(false)}
                  blok={panel}
                  story={story}
                  {...props}
                  key={panel?._uid}
                  {...storyblokEditable(panel)}
                />
              ) : null}

              {shouldShowLoader ? (
                <div className={styles.loader}>
                  <Loader />
                </div>
              ) : null}
            </section>
          ))}
        {expand && blok.title === 'Search' && (
          <div style={{ border: '1px solid #ccc', borderRadius: 4, padding: 10, overflow: 'visible' }}>
            <NavSearch
              story={story}
              initialPanels={initialPanels}
              searchTerm={searchTerm}
              isSearch={isSearch || expand}
              handleResetSearch={handleResetNavMenu}
            />
          </div>
        )}
      </section>
    </motion.section>
  );
};

export default PanelContainer;
