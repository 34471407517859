import React from 'react';

export interface GalleryVimeoProps {
  videoId: string;
}

export const GalleryVimeo: React.FC<GalleryVimeoProps> = ({
  videoId,
  // emblaApi,
  // index
}) => {
  // const [ready, setReady] = useState(false);
  // const playerRef = useRef(null);

  // const onSelect = useCallback(() => {
  // TODO: Pause video when in a carousel. Might not be possible using iframe
  // if (!ready) {
  //   return;
  // }
  // if (!playerRef.current && index === emblaApi.selectedScrollSnap()) {
  //   playerRef.current = new window.YT.Player(`c-${videoId}`, {
  //     videoId,
  //     playerVars: {
  //       playsinline: 1,
  //       enablejsapi: 1,
  //     },
  //     events: {
  //       onReady: onPlayerReady,
  //     },
  //   });
  // }
  // if (index === emblaApi.previousScrollSnap() && index !== emblaApi?.selectedScrollSnap()) {
  //   playerRef.current.pauseVideo();
  // }
  // }, [ready, index, emblaApi, videoId]);

  // useEffect(() => {
  //   if (!emblaApi) return;

  //   onSelect();
  //   emblaApi.on('select', onSelect);
  // }, [emblaApi, onSelect]);

  if (!videoId) {
    return;
  }

  return (
    <div className="aspect-video overflow-hidden rounded-2xl">
      <iframe
        className="video_container h-full w-[800px] max-w-full"
        src={`https://player.vimeo.com/video/${videoId}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        allowFullScreen
        title="Best Way to Buy Hearing Aids"
      />
    </div>
  );
};
