import * as React from 'react';

import { Button } from 'components/common-n4/button';
import CheckedIcon from 'images/checked.svg';
import { tw } from 'lib/utils';

import { ProviderPopupLayout } from '../../layout';
import { ContentProps, UserAction } from '../../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Intro: React.FC<ContentProps> = ({ dispatch, state }) => {
  return (
    <ProviderPopupLayout currentSlide={0}>
      <div className="flex flex-col justify-center px-7 py-10 lg:px-10 lg:py-[40px]">
        {/* <div className="text-sm font-medium leading-5 tracking-tight text-lapis">Partner Offer</div> */}
        <div className="mt-[8px] text-[32px] font-medium leading-[38px] -tracking-[0.96px] text-navy lg:mt-3 lg:leading-9">
          Get Audeo Sphere with Local Care Included
        </div>
        <ul className="mt-[32px] flex flex-col gap-[12px] text-[16px] leading-[22px] tracking-tight text-navy lg:mt-8 lg:gap-4 lg:text-[18px] lg:leading-6">
          <li className="flex gap-[12px] lg:gap-3">
            <CheckedIcon className="h-[24px] w-[24px] shrink-0" />
            <span className="flex items-center">All inclusive pricing from $2498-$4798 a pair depending on the model</span>
          </li>
          <li className="flex gap-[12px] lg:gap-3">
            <CheckedIcon className="h-[24px] w-[24px] shrink-0" />
            <span className="flex items-center">Hearing tests, fine-tuning, ongoing support</span>
          </li>
          <li className="flex gap-[12px] lg:gap-3">
            <CheckedIcon className="h-[24px] w-[24px] shrink-0" />
            <span className="flex items-center">500+ locations nationwide</span>
          </li>
          <li className="flex gap-[12px] lg:gap-3">
            <CheckedIcon className="h-[24px] w-[24px] shrink-0" />
            <span className="flex items-center">10,000+ customers served</span>
          </li>
        </ul>
        <Button.Primary
          className={tw(
            'mt-[32px] !w-full self-start !px-[24px] !py-[14px] !text-[16px] !tracking-tight lg:mt-10 lg:!w-auto lg:!px-6 lg:!py-[10px] lg:!text-[17px]'
          )}
          buttonType="submit"
          onClick={() => dispatch({ type: UserAction.submit })}
        >
          Check local availability
        </Button.Primary>
      </div>
    </ProviderPopupLayout>
  );
};
