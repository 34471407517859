import { useMemo } from 'react';

import type { Scores } from 'types/release';

export const SUB_SCORE_KEYS = [
  'Build Quality',
  'Battery',
  'Bluetooth',
  'App Features',
  'Comfort',
  'Design',
  'Value',
  'Handling',
  'SoundScore',
  'Pro Support',
] as const;

export const useSubScore = (scores?: Scores) => {
  const subScoresWithScores = useMemo(
    () =>
      SUB_SCORE_KEYS.map(
        (subScoreKey) => (scores?.[subScoreKey] && scores?.[subScoreKey]?.comment ? { ...scores?.[subScoreKey], subScoreKey } : null),
        []
      ).filter(Boolean),
    [scores]
  );

  return subScoresWithScores;
};
