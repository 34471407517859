// This is only used for the home page, so we can make the original builder
// look like n4; stories we serve in next.js will use the original builder,
// so we don't have to change them. Home was the only page that uses the
// original builder that's tied to its style.
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import logger from 'lib/logger';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'Builder' });

// This is going away for next; will use NextContent & FullWidthContent
const BuilderNext = ({ blok, story, ...props }) => (
  // eslint-disable-next-line no-unused-vars
  <div className={`ht-builder ${blok.className || ''}`}>
    {blok.blocks
      ? blok.blocks.filter((b) => !b.hidden).map((b) => <StoryblokComponent blok={b} story={story} {...props} key={b._uid} {...storyblokEditable(b)} />)
      : null}
  </div>
);
export default BuilderNext;
