import * as React from 'react';

import { motion, useAnimation } from 'framer-motion';

import logger from 'lib/logger';

const log = logger({ category: 'circle_progress' });

const STROKE_WIDTH = 3.5; // Set the stroke width here so it can be used in calculations

interface CircleProgressProps {
  size?: string;
  progress: number;
  className?: string;
  baseColor?: string;
  progressColor?: string;
  isStatic?: boolean;
  isStartFromZero?: boolean;
}
export const CircleProgress: React.FC<CircleProgressProps> = React.forwardRef<SVGSVGElement, CircleProgressProps>(
  (
    {
      className,
      progress = 0,
      baseColor = '#E7E9EC',
      progressColor = '#4A7FE5',
      size = '100%', // Default to 100% to fill the container
      isStatic = false,
      isStartFromZero = false,
    },
    ref
  ) => {
    // Initialize animation controls
    const controls = useAnimation();
    // Define the size of the SVG drawing area
    const viewBoxSize = 100;
    // Calculate the radius of the circle based on the viewBox size and stroke width
    const radius = viewBoxSize / 2 - STROKE_WIDTH * 2;
    // Calculate the circumference of the circle
    const circumference = 2 * Math.PI * radius;

    React.useEffect(() => {
      if (isStatic) {
        return;
      }
      if (isNaN(circumference - (progress / 100) * circumference)) {
        log.error('Bad values passed into circle_progress: %o', { circumference, progress });
      }
      // Animate strokeDashoffset to reflect the current progress
      controls.start({
        strokeDashoffset: isStartFromZero
          ? [circumference, circumference - (progress / 100) * circumference]
          : circumference - (progress / 100) * circumference,
        transition: { duration: 1, ease: 'easeInOut' },
      });
    }, [isStatic, progress, circumference, controls, isStartFromZero]);

    if (isNaN(progress)) {
      return null;
    }

    if (isNaN(circumference - (progress / 100) * circumference)) {
      log.error('Bad values passed into circle_progress: %o', { circumference, progress });
    }

    return (
      <motion.svg
        width={size}
        height={size}
        viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        ref={ref}
        initial={{ strokeDashoffset: circumference }} // Initial value of strokeDashoffset representing 0% progress
        animate={controls} // Use the animation controls to trigger the animation
      >
        <circle cx={viewBoxSize / 2} cy={viewBoxSize / 2} r={radius} fill="#FFFFFF" />
        <circle cx={viewBoxSize / 2} cy={viewBoxSize / 2} r={radius} stroke={baseColor} strokeWidth={'7%'} />
        <motion.circle
          cx={viewBoxSize / 2}
          cy={viewBoxSize / 2}
          r={radius}
          stroke={progressColor}
          strokeWidth={'7%'}
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={isStatic ? circumference - (progress / 100) * circumference : undefined}
          transform={`rotate(-90 ${viewBoxSize / 2} ${viewBoxSize / 2})`}
        />
      </motion.svg>
    );
  }
);

CircleProgress.displayName = 'CircleProgress';
