import React from 'react';

import { Button } from 'components/common-n4/button';
import HtLink from 'components/common-n4/ht-link';
import ImageWrapper from 'components/common-n4/image';
import type { ImageSource } from 'components/common-n4/image/types';
import { cx, normalizeUrl } from 'lib/utils';

import styles from './article-feature-card-primitives.module.scss';

import { CircleScoreFluid, CircleScoreFluidProps } from '../circle-score-fluid';

export type RootProps = React.PropsWithChildren<{
  url?: string;
  extended?: boolean;
  className?: string;
}>;

export const Root: React.FC<RootProps> = ({ children, className, extended, url, ...props }) => (
  <HtLink
    href={normalizeUrl({ url, origin: 'article-feature-card' })}
    className={cx(styles.root, extended ? styles['root-extended'] : undefined, className)}
    {...props}
  >
    {children}
  </HtLink>
);

// TODO: we should pass in ImageProps here, not ImageSource
export type HeaderProps = React.PropsWithChildren<{
  url: string;
  preload?: boolean;
  className?: string;
  image: ImageSource;
  score: CircleScoreFluidProps;
}>;

export const Header: React.FC<HeaderProps> = ({ className, image, url, score, ...props }) => (
  <header className={cx(styles.header, className, !score && styles.articleCard)} {...props}>
    <ImageWrapper image={image} origin="article-feature-card-primitives" />
    {score && score.amount && (
      <CircleScoreFluid
        size="md"
        {...score}
        className={cx(styles.cardHeaderScore, score.className, 'shadow-[1px_2px_10px_0_rgba(0,0,0,0.15)]')}
        origin="common-n4/article-feature-card"
      />
    )}
    {url && <Button.Icon onClick={() => {}} href={url} className={styles.iconButtonLink} icon="arrow-right-regular" />}
  </header>
);

export type ContentTitleProps = React.PropsWithChildren<{
  className?: string;
}>;
export const ContentTitle: React.FC<ContentTitleProps> = ({ children, className, ...props }) => (
  <h5 className={cx(styles.title, className, 'line-clamp-2')} {...props}>
    {children}
  </h5>
);

export type ContentBodyProps = React.PropsWithChildren<{
  className?: string;
}>;
export const ContentBody: React.FC<ContentBodyProps> = ({ children, className, ...props }) => (
  <p className={cx(styles.body, className, 'line-clamp-3')} {...props}>
    {children}
  </p>
);

export type ContentProps = React.PropsWithChildren<{
  body?: string;
  title?: string;
  className?: string;
}>;

export const Content: React.FC<ContentProps> = ({ children, className, title, body, ...props }) => (
  <section className={cx(styles.content, className)} {...props}>
    {!children && !!title && <ContentTitle>{title}</ContentTitle>}
    {!children && !!body && <ContentTitle>{body}</ContentTitle>}
    {children}
  </section>
);
