import React, { useState } from 'react';

import { type SbBlokData, storyblokEditable } from '@storyblok/react';

import { Avatar } from 'components/common-n4/avatar';
import fonts from 'components/layout/fonts';
import { useIsMobileView } from 'hooks/use_is_mobile_view';
import { useWindowDimensions } from 'hooks/use_window_dimensions';
import { cx, formatMonthYearDate } from 'lib/utils';

import styles from './testimonial-card.module.scss';

const REVIEW_CARD_TYPE = {
  HOME_REVIEW_CARD: 'home-testimonial',
  PRODUCT_REVIEW_CARD: 'product-testimonial',
};

const REVIEW_CARD_MOBILE_MAX_WIDTH = 412;
const REVIEW_CARD_MOBILE_PADDING = 16;

export interface TestimonialProps {
  text: string;
  name: string;
  title?: string;
  image?: string;
  date?: string;
  readMoreText?: string;
  blok: SbBlokData;
}

interface TestimonialRatingProps {
  score: number;
  product: string;
  isMobile?: boolean;
}

interface TestimonialCardProps {
  testimonial: TestimonialProps;
  type?: 'product-testimonial' | 'home-testimonial';
  className?: string;
  rating?: TestimonialRatingProps;
}

const TestimonialRating: React.FC<TestimonialRatingProps> = ({ score, product, isMobile = false }) => {
  const className = isMobile ? styles.mobile : '';
  return (
    <section className={cx(styles['ht-testimonial-card-rating'], className)}>
      <div className={styles['ht-testimonial-card-rating-details']}>
        <span className={styles['ht-testimonial-card-rating-details-score']}>{score}/5</span>
        <span className={styles['ht-testimonial-card-rating-details-product']}>{product}</span>
      </div>
    </section>
  );
};

export const TestimonialCard: React.FC<TestimonialCardProps> = ({ testimonial, type, rating, className, ...props }) => {
  const isMobile = useIsMobileView();
  const screenType = isMobile ? 'mobile' : '';
  const [showMore, setShowMore] = useState<boolean>(false);
  const [text, setText] = useState<string>(testimonial.text);
  const { width } = useWindowDimensions(true);

  const handleShowMore = () => {
    setShowMore(!showMore);
    setText(!showMore ? `${testimonial.text} ${testimonial.readMoreText}`.trim() : testimonial.text);
  };

  return (
    <section
      style={{ width: width! < REVIEW_CARD_MOBILE_MAX_WIDTH ? width! - REVIEW_CARD_MOBILE_PADDING * 2 : '100%' }}
      className={cx(styles['ht-testimonial-card'], 'mb-8', fonts.hankenGrotesk.className, styles[type!], styles[screenType], className)}
      {...props}
      {...storyblokEditable(testimonial.blok)}
    >
      <div className={cx(styles['ht-testimonial-card-content'], styles[type!])}>
        {isMobile && rating && <TestimonialRating score={rating.score} product={rating.product} isMobile={isMobile} />}
        <section className={cx(styles['ht-testimonial-card-content-text'], styles[type!], styles[screenType])}>
          <p>
            {text}
            {type === REVIEW_CARD_TYPE.PRODUCT_REVIEW_CARD && testimonial.readMoreText && !isMobile && (
              <>
                &nbsp;
                <span className={styles['ht-testimonial-card-content-text-read-more']} onClick={handleShowMore}>
                  {!showMore ? 'Show more' : 'Show less'}
                </span>
              </>
            )}
          </p>
        </section>
        <div className={cx(styles['ht-testimonial-card-bottom'], styles[type!], styles[screenType])}>
          <section className={styles['ht-testimonial-card-testimonialer']}>
            {testimonial.image && (
              <Avatar.Root options={{ rounded: 'full', size: type === 'product-testimonial' ? 'sm' : 'lg' }}>
                <Avatar.Image src={testimonial.image} className={cx(styles['ht-testimonial-card-testimonialer-image'], styles[type!])} />
                <Avatar.Fallback />
              </Avatar.Root>
            )}
            <div className={styles['ht-testimonial-card-testimonialer-details']}>
              <span className={cx(styles['ht-testimonial-card-testimonialer-details-name'], styles[type!])}>{testimonial.name}</span>
              <span className={cx(styles['ht-testimonial-card-testimonialer-details-company'], styles[type!])}>
                {type === 'product-testimonial' ? formatMonthYearDate(testimonial?.date) : testimonial.title}
              </span>
            </div>
          </section>
          {type === REVIEW_CARD_TYPE.PRODUCT_REVIEW_CARD && rating && !isMobile && (
            <TestimonialRating score={rating.score} product={rating.product} isMobile={isMobile} />
          )}
        </div>
      </div>
    </section>
  );
};
