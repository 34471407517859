import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { Button } from 'components/common-n4/button';
import { propped } from 'lib/propped';
import { cx } from 'lib/utils';

import styles from './share-buttons.module.scss';

const utmDefaults = {
  medium: 'website',
  campaign: 'articleshare',
  content: 'sharebutton',
};

const NO_UTM = true; // not really relevant for links we create to ourselves

const utm = (url, source) =>
  NO_UTM
    ? url
    : `${url}&${Object.entries({ ...utmDefaults, source })
        .map(([key, value]) => `utm_${key}=${value}`)
        .join('&')}`;

// TODO: should the URLs use encodeURIComponent?
const FACEBOOK_SHARE = (url) => url && `https://www.facebook.com/sharer.php?u=${url}`;
const LINKEDIN_SHARE = (url) => url && `https://www.linkedin.com/shareArticle?url=${url}`;
const TWITTER_SHARE_URL = (url, title) => `https://twitter.com/share?url=${url}&text=${encodeURIComponent(title)}&via=hearingtracker`;
const REDDIT_SHARE_URL = (url) => `https://reddit.com/submit?url=${url}`;

const types = {
  facebook: { icon: 'facebook-f', global: true },
  linkedin: { icon: 'linkedin', global: true },
  twitter: { icon: 'twitter', global: true },
  reddit: { icon: 'reddit', global: true },
  instagram: { icon: 'instagram', global: false },
  youtube: { icon: 'youtube', global: false },
};

const fakeLink = 'https://www.hearingtracker.com';

const defaultLinks = Object.entries(types)
  .filter(([, value]) => value.global)
  .map(([type, value]) => ({ link: fakeLink, icon: value.icon, type }));

const getShareData = ({ url, type, title }) => {
  switch (type) {
    case 'facebook':
      return {
        type,
        link: utm(FACEBOOK_SHARE(url), 'facebook'),
        accountUrl: 'https://www.facebook.com/hearingtracker',
      };
    // TODO: guess we need to switch to X, stupid Elon
    case 'twitter':
      return {
        type,
        link: utm(TWITTER_SHARE_URL(url, title), 'twitter'),
      };
    case 'reddit':
      return {
        type,
        link: utm(REDDIT_SHARE_URL(url), 'reddit'),
      };
    case 'linkedin':
      return {
        type,
        link: utm(LINKEDIN_SHARE(url), 'linkedin'),
      };
    default: {
      return {};
    }
  }
};

// todo: add to .env.local dev vs prod link etc. if nothing exists yet
const BASE_PROD_URL = 'https://www.hearingtracker.com';

const ShareButtonsRoot = ({ links, className = '', copyLink = '#', shouldOpenNewTab = false, rel = 'noopener noreferrer nofollow', title }) => {
  const router = useRouter();
  const [shareLinks, setShareLinks] = useState(defaultLinks);

  // This is necessary because router.asPath returns the rewritten path until isReady is true
  // due to SSR, so it might return /stories/slug/A instead of /slug. Once isRead is true, it
  // should return whatever is in the URL bar.
  useEffect(() => {
    if (router.isReady) {
      const url = `${BASE_PROD_URL}${router.asPath}`;
      if (links) {
        setShareLinks(links.filter(({ type }) => types[type]));
      } else {
        setShareLinks(defaultLinks.map(({ type }) => getShareData({ url, type, title })));
      }
    }
  }, [router.isReady, router.asPath, links, title]);

  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(links ? copyLink : router.asPath);
    } catch (err) {
      console.error('Failed to copy URL: ', err);
    }
  };

  return (
    <section className={cx(styles.share, className)}>
      {!links && <Button.Icon onClick={handleCopyUrl} icon="link-simple-regular" className={styles.copy} />}
      {shareLinks.map(({ type, link }) => (
        <Button.IconLink key={type} href={link} icon={types[type].icon} className={cx(styles[type])} shouldOpenNewTab={shouldOpenNewTab} rel={rel} />
      ))}
    </section>
  );
};

const PrimaryDark = propped(ShareButtonsRoot, {
  className: styles['share--dark'],
});

export const ShareButtons = {
  Primary: ShareButtonsRoot,
  PrimaryDark,
};
