import React, { useMemo } from 'react';

import { Button } from 'components/common-n4/button';
import fonts from 'components/layout/fonts';
import { cx } from 'lib/utils';

import styles from './testimonials.module.scss';

import { TestimonialCard, TestimonialProps } from './testimonial-card/testimonial-card';

import { MultiCarousel } from '../multi-carousel/multi-carousel';

const testimonialCardConfig = {
  mobile: { width: 380, gap: 16 },
  desktop: { width: 520, gap: 32 },
  wide: { width: 520, gap: 32 },
};

interface HeaderProps {
  title: string;
  description: string;
  buttonText: string;
}

export const Header: React.FC<HeaderProps> = ({ title, description, buttonText }) => {
  return (
    <div className={styles.header}>
      <p className={styles.title}>{title}</p>
      <div className={styles.innerContainer}>
        <p className={styles.description}>{description}</p>
        <div className={styles.btnContainer}>
          <Button.Tertiary text={buttonText} className={styles.viewBtn} />
        </div>
      </div>
    </div>
  );
};

export type TestimonialsProps = React.PropsWithChildren<
  HeaderProps & {
    className?: string;
    testimonialList: TestimonialProps[];
  }
>;

export const Testimonials: React.FC<TestimonialsProps> = ({ title, description, buttonText, testimonialList, className }) => {
  const testimonialCardList = useMemo(
    () => testimonialList.map((testimonialItem: TestimonialProps, index) => <TestimonialCard key={index} testimonial={testimonialItem} />),
    [testimonialList]
  );

  return (
    <div className={cx(fonts.hankenGrotesk.className, styles['ht-testimonials'], className)}>
      <Header title={title} description={description} buttonText={buttonText} />
      <div className={styles.carouselContainer}>
        <MultiCarousel items={testimonialCardList} itemSpaceConfigs={testimonialCardConfig} />
      </div>
    </div>
  );
};
