import Head from 'next/head';

import AuthorTop from 'components/widgets/author_top';
import Breadcrumbs from 'components/widgets/breadcrumbs';
import logger from 'lib/logger';
import { normalizeUrl } from 'lib/utils';

import styles from './title_and_meta.module.scss';

const log = logger({ category: 'components/bloks/title_and_meta' });

const sup = (s) => {
  if (!/™/.test(s)) return s;

  const split = s.split('™');

  return (
    <span>
      {split[0]}
      <sup>TM</sup>
      {split[1]}
    </span>
  );
};

const TitleAndMeta = ({ blok, story, ...props }) => {
  log.debug('blok: %o, story: %o', blok, story.slug);
  const title = blok.google_title || blok.page_title;
  return (
    <>
      <Head>
        <title key="title">{title}</title>
        <meta key="meta_description" name="description" content={blok.meta_description} />
        <meta key="twitter_title" name="twitter:title" content={title} />
        <meta key="twitter_description" name="twitter:description" content={blok.meta_description} />
        <meta key="twitter_image" name="twitter:image" content={normalizeUrl({ url: blok.open_graph_image, origin: 'title_and_meta#open_graph' })} />
        <meta key="og_title" property="og:title" content={title} />
        <meta key="og_image" property="og:image" content={normalizeUrl({ url: blok.open_graph_image, origin: 'title_and_meta#poen_graph' })} />
        <meta key="og_description" property="og:description" content={blok.meta_description} />
      </Head>
      <section className={styles.title}>
        {props.layout !== 'n4' && props.breadcrumbs?.length && <Breadcrumbs breadcrumbs={props.breadcrumbs} />}
        {!blok.no_body_title && blok.page_title && <h1 className={blok.page_subtitle ? 'has-subtitle' : ''}>{sup(blok.page_title)}</h1>}
        {!!blok.page_subtitle && <h2>{sup(blok.page_subtitle)}</h2>}
      </section>
      <AuthorTop story={story} titleBlok={blok} />
    </>
  );
};

export default TitleAndMeta;
