/* eslint-disable max-len */
import * as React from 'react';

const SvgStar = ({ fill = '#4A7FE5', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 33 33" {...props}>
    <g clipPath="url(#star_svg__a)">
      <path
        fill={fill}
        d="M32.307 12.696a1.7 1.7 0 0 0-1.469-1.173l-9.264-.841-3.663-8.575a1.707 1.707 0 0 0-3.138.002l-3.663 8.573-9.266.841a1.71 1.71 0 0 0-1.467 1.173 1.7 1.7 0 0 0 .499 1.812l7.002 6.142-2.064 9.096a1.705 1.705 0 0 0 2.537 1.843l7.99-4.776 7.99 4.776a1.707 1.707 0 0 0 2.538-1.843l-2.065-9.096 7.003-6.14c.515-.451.711-1.163.5-1.814"
      />
    </g>
    <defs>
      <clipPath id="star_svg__a">
        <path fill="#fff" d="M.293.384h32.098v32.098H.293z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgStar;
