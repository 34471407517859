import * as React from 'react';

import { Button } from 'components/common-n4/button';
import IconLock from 'images/icon-lock.svg';
import TrustpilotStars from 'images/trustpilot-logo-stars.svg';
import USFlag from 'images/us-flag.svg';
import { tw, validatePhoneNumber } from 'lib/utils';

import styles from '../../shared.module.scss';

import { ProviderPopupLayout } from '../../layout';
import { StepIndicator } from '../../step-indicator';
import { ContentProps, ErrorCode, Step, SystemAction, UserAction } from '../../types';

interface HTMLInputElementWithVK extends HTMLInputElement {
  virtualKeyboardPolicy: 'auto' | 'manual' | '';
}

export const PhoneEntry: React.FC<ContentProps> = ({ dispatch, state }) => {
  const phoneInputRef = React.useRef<HTMLInputElementWithVK>(null);

  React.useEffect(() => {
    if ('virtualKeyboard' in navigator) {
      phoneInputRef.current!.virtualKeyboardPolicy = 'manual';
    }
    phoneInputRef.current?.focus();
    if ('virtualKeyboard' in navigator) {
      phoneInputRef.current!.virtualKeyboardPolicy = 'auto';
    }
  }, []);

  const handleSubmit = React.useCallback(() => {
    // validate phone number
    if (!state.phone) {
      dispatch({
        type: SystemAction.error,
        payload: { error: { code: ErrorCode.user_error_no_phone, message: 'Please provide your phone number', field: 'phone' } },
      });
    } else if (!validatePhoneNumber(state.phone)) {
      dispatch({
        type: SystemAction.error,
        payload: { error: { code: ErrorCode.user_error_bad_phone, message: 'Plesae enter a valid phone number', field: 'phone', phone: state.phone } },
      });
    } else {
      dispatch({ type: UserAction.submit });
    }
  }, [dispatch, state.phone]);

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSubmit]);

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const cursorPosition = event.target.selectionStart ?? 0;

    // Remove all non-digit characters
    const rawPhone = input.replace(/\D/g, '');

    // Format the phone number
    let formattedNumber = '';
    if (rawPhone.length > 0) {
      formattedNumber += `(${rawPhone.slice(0, 3)}`;
      if (rawPhone.length > 3) {
        formattedNumber += `) ${rawPhone.slice(3, 6)}`;
      }
      if (rawPhone.length > 6) {
        formattedNumber += `-${rawPhone.slice(6, 10)}`;
      }
    }

    // Calculate the new cursor position
    const formatCharsBefore = (pos: number) => {
      if (pos <= 3) return 1; // Account for opening parenthesis
      if (pos <= 6) return 3; // Account for closing parenthesis and space
      return 4; // Account for hyphen
    };

    const rawCursorPosition = input.slice(0, cursorPosition).replace(/\D/g, '').length;
    const newCursorPosition = rawCursorPosition + formatCharsBefore(rawCursorPosition);

    // Update the input value
    dispatch({ type: UserAction.change, payload: { phone: formattedNumber } });

    // Set the cursor position
    setTimeout(() => {
      event.target.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  const handleInputKeyDown = (event: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  const { providersFound } = state.searchResponse!;

  return (
    <ProviderPopupLayout currentSlide={2}>
      <div className="flex flex-col items-center justify-center px-7 pb-[14px] pt-10 lg:px-10 lg:py-6">
        <StepIndicator steps={4} currentStep={2} />
        <div className="mt-[28px] flex items-center justify-center text-center text-[24px] font-medium leading-7 text-navy lg:mt-10">
          Thanks! We have found {providersFound} participating provider{providersFound === 1 ? '' : 's'} near you!
        </div>

        <div className="mt-4 text-center text-[18px] font-light leading-6 tracking-tight text-navy-80">
          We’re ready to give you their info and answer your questions — just tell us how to reach you. There is no sales pitch, and the call can take less than
          2 minutes.
        </div>
        {state.error && (
          <div className="ml-[18px] mt-[10px] text-sm" style={{ color: '#c00' }}>
            {state.error.message}
          </div>
        )}

        <div className="relative mt-[28px] flex items-center self-stretch lg:mt-10">
          <input
            type="text"
            id="phone-input"
            value={state.phone}
            onChange={handlePhoneChange}
            onKeyDown={handleInputKeyDown}
            className={tw(
              'remove-arrow h-[54px] w-full rounded-[10px] border border-navy/10 px-12 py-4 leading-5',
              state.error && state.error.field === 'phone' ? styles.error : ''
            )}
            required
            placeholder="(xxx) xxx-xxxx"
            ref={phoneInputRef}
          />
          <span className={tw('absolute inset-y-0 start-0 flex items-center pl-[16px]', styles['input-prefix'])}>+1</span>
          <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center pr-[16px]">
            <USFlag />
          </div>
        </div>
        <div className="mt-[28px] self-stretch lg:mt-6">
          <Button.Primary
            className="!w-full !px-6 !py-[10px] !text-[17px] !tracking-tight"
            onClick={handleSubmit}
            buttonType="submit"
            isDisabled={state.step === Step.submitting}
          >
            Get my provider info
          </Button.Primary>
          <div className="mt-4 flex items-center justify-center gap-2">
            <IconLock />
            <span className="pt-[2px] text-[10px] font-light leading-3 tracking-tight text-black">Your information stored securely.</span>
          </div>
        </div>
        {/* <hr className="my-[28px] self-stretch border-t border-navy-10 lg:my-6" /> */}
        <div className="mt-8 flex justify-center gap-4">
          <TrustpilotStars width={74} />
          <div className="text-[12px] tracking-tight text-black lg:text-xs">600+ reviews on independent platforms</div>
        </div>
      </div>
    </ProviderPopupLayout>
  );
};
