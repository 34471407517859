import React, { useCallback, useState, useRef } from 'react';

import { motion, useMotionValueEvent, useScroll } from 'framer-motion';

import fonts from 'components/layout/fonts';
import SvgIcon from 'components/ui/svg_icon';
import { cx } from 'lib/utils';

import styles from './hero-lower-header.module.scss';

export type HeroLowerHeaderProps = React.PropsWithChildren<{
  className?: string;
  headline: string;
  titleRight: string;
  textRight: string;
}>;

export const HeroLowerHeader: React.FC<HeroLowerHeaderProps> = ({ className, headline, titleRight, textRight }) => {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({ target: container, offset: ['start end', 'end 0.75'] });
  const [scrollProgress, setScrollProgress] = useState<number>(0);
  const wordsCount = headline.split(' ').length;

  useMotionValueEvent(scrollYProgress, 'change', (latest) => {
    setScrollProgress(latest);
  });

  const calculateWidth = useCallback(
    (index: number) => {
      const totalSpans = wordsCount;
      const step = 1 / totalSpans;

      switch (true) {
        case scrollProgress >= index * step:
          return '100%';
        case scrollProgress >= (index - 1) * step:
          return `${((scrollProgress - (index - 1) * step) / step) * 100}%`;
        default:
          return '0%';
      }
    },
    [scrollProgress, wordsCount]
  );

  return (
    <div className={cx(fonts.hankenGrotesk.className, styles['ht-hero-lower-section-header'], className)}>
      <div ref={container} className={styles.headline}>
        {headline.split(' ').map((word, index) =>
          word.includes('Ⓡ') ? (
            <div key={index}>
              {word.split('Ⓡ')}
              <sup className="-top-[13px] left-[2px] text-[12px] lg:-top-[1.5em] lg:left-[1.2px] lg:text-[19px]">Ⓡ</sup>
              <motion.span style={{ display: 'inline-block', width: calculateWidth(index + 1), overflow: 'visible' }}>
                {word.split('Ⓡ')}
                <sup className="-top-[13px] left-[2px] text-[12px] lg:-top-[1.5em] lg:left-[1.2px] lg:text-[19px]">Ⓡ</sup>
                &nbsp;
              </motion.span>
              &nbsp;
            </div>
          ) : (
            <div key={index}>
              {word}
              <motion.span style={{ display: 'inline-block', width: calculateWidth(index + 1) }}>{word}&nbsp;</motion.span>
              &nbsp;
            </div>
          )
        )}
      </div>
      <motion.div className={styles.rightContainer} style={{ opacity: scrollYProgress }}>
        <div className={styles.textContainerRight}>
          <p className={styles.titleRight}>{titleRight}</p>
          <p className={styles.textRight}>{textRight}</p>
        </div>
        <SvgIcon icon={'lab-tested-logo-regular'} aria-hidden className={styles.label} />
      </motion.div>
    </div>
  );
};
