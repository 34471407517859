import * as React from 'react';

import * as Dialog from '@radix-ui/react-dialog';

import fonts from 'components/layout/fonts';
import IconCross from 'images/icon-cross.svg';
import { cx, tw } from 'lib/utils';

export interface ModalProps extends React.PropsWithChildren {
  isOpen?: boolean;
  overlayBackgroundColor?: string;
  contentBackgroundColor?: string;
  classes?: ModalClasses;
  triggerChild?: React.ReactNode;
  onClose?: () => void;
  origin: string;
}

interface ModalClasses {
  overlay?: string;
  panel?: string;
}

// TODO: use DialogOverlay so the backdrop covers the nav, otherwise the close gets hidden
// overlay should not encompass the content; then you need to style the content to be centered
// against Dialog.Content
// TODO: also rethink how to do this with triggers instead of isOpen
export const Modal: React.FC<ModalProps> = ({
  isOpen = true,
  onClose,
  children,
  classes,
  overlayBackgroundColor,
  contentBackgroundColor,
  triggerChild = undefined,
  origin,
}) => {
  const [open, setOpen] = React.useState(isOpen);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen, origin]);

  const onOpenChange = (stateOpen: boolean) => {
    if (onClose) {
      if (!stateOpen) {
        onClose();
      }
    } else {
      setOpen(stateOpen);
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      {triggerChild && <Dialog.Trigger asChild>{triggerChild}</Dialog.Trigger>}
      <Dialog.Portal>
        <Dialog.Overlay
          className={cx('no-scrollbar fixed inset-0 z-1000 overflow-y-scroll bg-navy bg-opacity-80', classes?.overlay)}
          style={overlayBackgroundColor ? { backgroundColor: overlayBackgroundColor } : {}}
        >
          <div className="bottom-0 overflow-y-auto md:inset-0">
            <div className="flex min-h-screen items-end justify-center text-center md:items-center ">
              <Dialog.Content className="focus-visible:outline-none" aria-describedby={undefined}>
                <Dialog.Title />
                <Dialog.Close className={tw('absolute right-4 top-5 flex items-center text-white md:right-[38px]')}>
                  <span className={tw('text-base', fonts.hankenGrotesk.className)}>Close</span>
                  <div className={'p-4'}>
                    <IconCross />
                  </div>
                </Dialog.Close>
                <div
                  className={tw(
                    'relative mt-20 w-screen transform overflow-y-auto rounded-t-[30px] bg-white text-left align-middle shadow-xl transition-all md:mt-0',
                    'no-scrollbar focus-visible:outline-none md:max-w-max md:rounded-[30px] ',
                    classes?.panel,
                    fonts.hankenGrotesk.className
                  )}
                  style={contentBackgroundColor ? { backgroundColor: contentBackgroundColor } : {}}
                >
                  {children}
                </div>
              </Dialog.Content>
            </div>
          </div>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
