import { twMerge } from 'tailwind-merge';

import { WINDOW_DIMENSIONS } from 'hooks/use_window_dimensions';
import { fetchApi } from 'lib/ht_api';

import logger from './logger';

const log = logger({ category: 'utils' });

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const formatCurrency = (value, options = {}) => {
  const { twoDecimals = false, currencySymbol = true, commas = true } = options;
  const parts = currencyFormatter.formatToParts(value);
  const currency = parts.find((p) => p.type === 'currency');

  let rest = parts.filter((p) => !/currency|decimal|fraction/.test(p.type)).map((p) => p.value);

  if (twoDecimals) {
    const fraction = parts.find((p) => p.type === 'fraction');
    const decimal = parts.find((p) => p.type === 'decimal');

    if (fraction) {
      rest.push(decimal ? decimal.value : '', fraction.value.padEnd(2, '0'));
    } else {
      rest.push(decimal ? decimal.value : '', '00');
    }
  }

  // Handle commas
  if (!commas) {
    rest = rest.join('').replace(/,/g, '').split('');
  }

  // Return with or without currency symbol
  return currencySymbol ? `${currency.value}${rest.join('')}` : rest.join('');
};

const pluralize = (count, word) => (count === 1 ? `1 ${word}` : `${count} ${word}s`);

// turns logic within className into a string to make sure no 'undefined' etc. show up in the DOM
const cx = (...classes) => classes.filter(Boolean).join(' ');
const tw = (...classes) => twMerge(classes.filter(Boolean).join(' '));

const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach((fn) => typeof fn === 'function' && fn(...args));

const formatSecondsToTime = (secondsFloat) => {
  const totalSeconds = Math.round(secondsFloat);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'long', year: 'numeric' };

  return new Intl.DateTimeFormat('en-US', options).format(date);
};

// get month year from date string
const formatMonthYearDate = (dateString) => {
  const date = new Date(dateString);
  const options = { month: 'long', year: 'numeric' };

  return new Intl.DateTimeFormat('en-GB', options).format(date);
};

// Useful so you can do logTrue(...) && return-value in a single-line return to avoid adding a {} scope.
const logTrue = (message, ...params) => {
  // eslint-disable-next-line no-console
  console.log(message, ...params);
  return true;
};

const getWindowDimensionType = (size) => {
  if (!Number.isFinite(size)) return null;

  if (size <= 767) {
    return 'mobile';
  }
  if (size >= 1400 && size < 1640) {
    return 'desktop';
  }
  if (size >= 1640) {
    return 'wide';
  }

  const dimension = WINDOW_DIMENSIONS.find(({ limit }) => size <= limit);

  return dimension ? dimension.type : null;
};

// With asPath, you can get:
// Warning: Prop `href` did not match. Server: "/foo/0" Client: "/foo"
// This is because on the server asPath returns the rewrite url which may have /0 or /1 due to
// our variants logic. I need to figure out how to consistently get the same URL on the client
// and server. This is a temporary way that just brute force strips off the trailing rewrite stuff.
const tempFixRouterAsPath = (url) => {
  const noHash = url.slice(-1) === '#' ? url.slice(0, -1) : url;
  if (noHash.slice(-2) === '/A' || noHash.slice(-2) === '/B') {
    return noHash.slice(0, -2);
  }
  return noHash;
};

const refreshCachedPage = async () => {
  const response = await fetchApi({
    path: '/cache/revalidate',
    variables: { slug: window.location.pathname, sync: true },
    fallback: 'error',
    origin: 'refreshCachedPage',
  });
  if (response === 'error') {
    // eslint-disable-next-line no-alert
    alert('Revalidate failed');
  } else {
    window.location.reload();
  }
};

const htmlToMarkdown = (type, text) => {
  switch (type) {
    case 'p':
      return text.replace(/<\/?p>/g, '\n\n').trim();
    default:
      return text;
  }
};

const formatPhoneNumber = (number) => {
  let formattedNumber = '';

  if (number.length > 0) {
    formattedNumber += `(${number.substring(0, 3)}`;
    if (number.length >= 3) {
      formattedNumber += ') ';
    }
  }
  if (number.length > 3) {
    formattedNumber += number.substring(3, 6);
    if (number.length >= 6) {
      formattedNumber += '-';
    }
  }
  if (number.length > 6) {
    formattedNumber += number.substring(6, 10);
  }

  return formattedNumber;
};

const validatePostalCode = (postalCode) => {
  const pattern = /^\d{5}$/;
  return pattern.test(postalCode);
};

const validatePhoneNumber = (phoneNumber) => {
  const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
  return phonePattern.test(phoneNumber);
};

const validateEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

const getYoutubeVideoId = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};

const getYoutubeThumbnail = (videoId) => `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;

const normalizeUrl = ({ url, origin }) => {
  if (!url || typeof url !== 'string') {
    log.warn('[%s]: normalizeUrl passed invalid url [%o]', origin, url);
    return '';
  }
  if (url.startsWith('https')) {
    return url;
  }
  if (url.startsWith('http:')) {
    return url.replace('http:', 'https:');
  }
  if (url.startsWith('//')) {
    // probably the storyblok ones that start with //, or maybe an old http one
    return `https:${url}`;
  }
  if (url.startsWith('/')) {
    // absolute urls
    return url;
  }
  // these are probably story slugs, e.g. best-hearing-aids
  if (url.startsWith('stories/')) {
    return url.replace(/^stories/, '');
  }
  return `/${url}`;
};

const titleHn = ({ title, heading }) => {
  if (!title) {
    return null;
  }
  if (heading === 'h2') {
    return <h2>{title}</h2>;
  }
  if (heading === 'h3') {
    return <h3>{title}</h3>;
  }
  if (heading === 'h4') {
    return <h4>{title}</h4>;
  }
  if (heading === 'h5') {
    return <h5>{title}</h5>;
  }
  if (heading === 'h6') {
    return <h6>{title}</h6>;
  }
  return <h3>{title}</h3>;
};

export {
  callAll,
  currencyFormatter,
  cx, // for regular scss classes
  formatCurrency,
  formatDate,
  formatMonthYearDate,
  formatPhoneNumber,
  formatSecondsToTime,
  getWindowDimensionType,
  getYoutubeThumbnail,
  getYoutubeVideoId,
  htmlToMarkdown,
  logTrue,
  normalizeUrl,
  pluralize,
  refreshCachedPage,
  tempFixRouterAsPath,
  titleHn,
  tw, // for tailwind classes
  validateEmail,
  validatePhoneNumber,
  validatePostalCode,
};
