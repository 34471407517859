import * as React from 'react';

import { storyblokEditable } from '@storyblok/react';
import NextLink from 'next/link';

import ImageWrapper from 'components/common-n4/image';
import SvgIcon from 'components/ui/svg_icon';
import { useApiData } from 'hooks/use_api_data';
import { useIsMediumOrMobileView } from 'hooks/use_is_mobile_view';
import { fetchApi } from 'lib/ht_api';
import logger from 'lib/logger';
import { formatDate, normalizeUrl } from 'lib/utils';

import styles from '../nav/article.module.scss';
/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'nav/Article' });

/* eslint-disable-next-line no-unused-vars */
const NavArticle = ({ blok, ...props }) => {
  log.debug('blok: %o', blok);
  const { slug, large } = blok;
  const { articles } = useApiData();
  const [article, setArticle] = React.useState(articles[blok.slug]);
  const [hasImageLoaded, setHasImageLoaded] = React.useState(false);
  const isMediumOrLessViewport = useIsMediumOrMobileView();
  const [titleOverride, imageOverride] = [blok.title_override, blok.image_override];
  const image = imageOverride && imageOverride.id ? imageOverride : normalizeUrl({ url: article.image, origin: `nav-expanded@#article:${blok.slug}` });

  React.useEffect(() => {
    if (article && article.slug === slug) {
      return;
    }
    if (articles[slug]) {
      setArticle(articles[slug]);
      return;
    }
    const fn = async () => {
      const results = await fetchApi({
        path: 'articles/meta_from_storyblok.json',
        variables: { slug, version: 'published' },
        fallback: {},
        origin: 'nav-expanded/article',
      });
      log.info('slug change %s -> %o', slug, results);
      if (!results.error) {
        setArticle(results);
      }
    };
    fn();
  }, [article, slug, articles]);

  if (!article) return null;

  log.debug('article: %o', article);

  const date = article.republished_at || article.published_at;

  return (
    <div className={styles.wrapper} {...storyblokEditable(blok)} style={{ display: 'flex', flexDirection: 'column' }}>
      <button
        type="button"
        style={{
          fontSize: '.9rem',
          background: '#40c6c4',
          color: 'white',
          textAlign: 'left',
          padding: 2,
          paddingLeft: 4,
          maxHeight: '1.5rem',
          display: 'flex',
        }}
      >
        <SvgIcon icon="grip-dots-regular" /> {article.slug}
      </button>
      <NextLink href={normalizeUrl({ url: article.slug, origin: `nav-expanded/article:${blok.slug}` })} rel={article.noindex ? 'nofollow' : undefined}>
        {!isMediumOrLessViewport && large ? (
          <section className={styles['article--row']}>
            <figure>
              {image ? (
                <ImageWrapper
                  image={image}
                  imgProps={{ alt: article.title, loading: 'eager' }}
                  onLoaded={() => setHasImageLoaded(true)}
                  origin="nav-expanded-article-lg"
                />
              ) : null}
              {!image || (image && !hasImageLoaded) ? <div className={styles.placeholder} /> : null}
              <div className={styles.icon}>
                <SvgIcon icon="arrow-right-regular" />
              </div>
            </figure>
            <section className={styles.details}>
              <h4>{titleOverride || article.title}</h4>
              {article.description ? <p>{article.description}</p> : <p>Description</p>}
            </section>
          </section>
        ) : (
          <section className={styles['article--column']}>
            <figure>
              {image ? (
                <ImageWrapper
                  image={image}
                  imgProps={{ alt: article.title, loading: 'eager' }}
                  onLoaded={() => setHasImageLoaded(true)}
                  origin="nav-expanded-article-md"
                />
              ) : null}
              {!image || (image && !hasImageLoaded) ? <div className={styles.placeholder} /> : null}
              <div className={styles.icon}>
                <SvgIcon icon="arrow-right-regular" />
              </div>
            </figure>
            <section className={styles.details}>
              <h4>{titleOverride || article.title}</h4>
              {date ? <div className={styles.date}>{formatDate(date)}</div> : null}
            </section>
          </section>
        )}
      </NextLink>
    </div>
  );
};

export default NavArticle;
