import HtLink from 'components/common-n4/ht-link';

// breadcrumbs should be { label, path }
const Breadcrumbs = ({ breadcrumbs }) => (
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      {breadcrumbs.map((c, idx) => (
        <li key={c.label} className={`breadcrumb-item text-truncate ${idx === breadcrumbs.length - 1 ? 'active' : ''}`}>
          {c.path && (
            <HtLink href={c.path}>
              <span>{c.label}</span>
            </HtLink>
          )}
          {!c.path && <span>{c.label}</span>}
        </li>
      ))}
    </ol>
  </nav>
);

export default Breadcrumbs;
