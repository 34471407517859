import { ReactElement } from 'react';

import ImgixImage from './imgix-image';
import OptimizedImage from './optimized-image';
import SbAssetImage from './sb-asset-image';
import SbLegacyImage from './sb-legacy-image';
import type { ImageProps, ImageSizingProps, ImgProps, ImgixImageProps, UberImageStoryblok } from './types';

const SbUberImage = (uberImage: UberImageStoryblok): ReactElement | null => {
  // These are all the fields defined on the Storyblok uberImage component
  const { image, asset, imgix, filename, title, alt, fixed, minWidth, maxWidth, resolutionCount, quality, height, sizes, className, tracer } = uberImage;
  tracer.trace('In uberimage: %o', uberImage);

  // Of the SB props, these are related to sizing
  const sizingProps: ImageSizingProps = { fixed, sizes, quality };

  // Storyblok returns all these numbers as strings so have to conver them
  if (minWidth) {
    sizingProps.minWidth = Number(minWidth);
  }
  if (maxWidth) {
    sizingProps.maxWidth = Number(maxWidth);
  }
  if (resolutionCount) {
    sizingProps.resolutionCount = Number(resolutionCount);
  }
  if (height) {
    sizingProps.nativeHeight = height === 'auto' ? height : Number(height);
  }

  // Of the SB props, these are related to the img
  const imgProps: ImgProps = { alt, className, title };

  tracer.trace('Getting type of uberimage: %o', uberImage);

  // UberImage can be an asset, legacy SB image, local file name (rare), or imgix key (even rarer)
  if (asset && asset.filename) {
    // Storyblok asset
    const allProps: ImageProps = { image: asset, sizingProps, imgProps, origin: 'UberImage/SbAsset' };
    return <SbAssetImage {...allProps} tracer={tracer} />;
  }

  if (image) {
    // Storyblok legacy asset - image is just a url string
    const allProps: ImageProps = { image, sizingProps, imgProps, origin: 'UberImage/SbLegacy' };
    return <SbLegacyImage {...allProps} tracer={tracer} />;
  }

  if (imgix) {
    // Imgix image
    const imgixImage: ImgixImageProps = {
      imgix,
      nativeWidth: sizingProps.minWidth,
      nativeHeight: sizingProps.nativeHeight || 'auto',
    };
    const allProps: ImageProps = { image: imgixImage, sizingProps, imgProps, origin: 'UberImage/imgix' };
    return <ImgixImage {...allProps} tracer={tracer} />;
  }

  if (filename) {
    // local path or url
    const allProps: ImageProps = { image: filename, sizingProps, imgProps, origin: 'UberImage/filename' };
    return <OptimizedImage {...allProps} tracer={tracer} />;
  }

  tracer.trace('ERROR: uberimage mssing image: %o', uberImage);
  return null;
};

export default SbUberImage;
