/* Components used in N4 pages. Most should be dynamic but as more of the site uses this,
 * they can be moved to standard so they're loaded in the main bundle.
 * Trying non-dynamic - since these pages are pre-rendered, not sure what dynamic really
 * buys us. Dynamic could be used for content that is loaded in response to some user
 * action or for something that is only used on one page. Maybe this will get rid of
 * those annoying hydration errors.
 */
import ProductContainer from './product-container';

const components = {
  'n4-product-container': ProductContainer,
};

export default components;
