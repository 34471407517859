import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import logger from 'lib/logger';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'NextContent' });

const NextContent = ({ blok, story, ...props }) => (
  <div className={`ht-next-content ${blok.className || ''}`}>
    {blok.blocks
      ? blok.blocks.filter((b) => !b.hidden).map((b) => <StoryblokComponent blok={b} story={story} {...props} key={b._uid} {...storyblokEditable(b)} />)
      : null}
  </div>
);

export default NextContent;
