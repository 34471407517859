import { storyblokEditable } from '@storyblok/react';

import ImageWrapper from 'components/common-n4/image';
import logger from 'lib/logger';
import { cx } from 'lib/utils';

// Can share from container if there are not many styles, otherwise use separate module per component
import styles from './home-container.module.scss';

const log = logger({ category: 'n4/home/generic-cta' });

const GenericCta = ({ blok, story, ...props }) => {
  log.debug('blok: %o', { blok, props });
  return (
    <section className={cx(styles['generic-cta'], blok.className)} {...storyblokEditable(blok)} style={{ border: '1px solid tan', margin: 20 }}>
      <h3>Generic CTA</h3>
      <ul>
        <li>title: {blok.title}</li>
        <li>description: {blok.description}</li>
        <li>small text: {blok.small_text}</li>
        <li>button text: {blok.button_texttexttexttexttexttexttexttexttext}</li>
        <li>button link: {blok.button_link.cached_url}</li>
        <li>sponsored: {blok.sponsored}</li>
        <li>columnize: {blok.columnize}</li>
        <li>text position: {blok.text_position}</li>
        <li>image position: {blok.image_position}</li>
      </ul>
      <ImageWrapper image={blok.image} imgProps={{ style: { width: 200, height: 'auto' } }} origin="home-cta" />
    </section>
  );
};

export default GenericCta;
