import { PropsWithChildren } from 'react';

import { ClassNameValue } from 'tailwind-merge';

import { tw } from 'lib/utils';

interface SectionProps extends PropsWithChildren {
  id?: string;
  title?: string;
  className?: ClassNameValue;
  titleClassName?: ClassNameValue;
}

interface SectionTitleProps extends PropsWithChildren {
  className?: ClassNameValue;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({ children, className }) => (
  <h2 className={tw('text-[24px] font-medium text-navy lg:text-[2rem]', className)}>{children}</h2>
);

export const Section: React.FC<SectionProps> = ({ title, children, className, titleClassName, id }) => (
  <section className={tw('mt-8 border-t border-neutral-300 pt-12', className)} id={id}>
    {title && <SectionTitle className={tw('pb-8', titleClassName)}>{title}</SectionTitle>}
    {children}
  </section>
);
