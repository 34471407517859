import * as React from 'react';

import { motion, useAnimation } from 'framer-motion';

const STROKE_WIDTH = 3.5; // Set the stroke width here so it can be used in calculations

// Function to calculate x and y positions for a given angle (in degrees)
const calculatePosition = (angleInDegrees: number, center: number, radius: number) => {
  const angleInRadians = (angleInDegrees * Math.PI) / 180; // Convert angle to radians
  const x = center + radius * Math.cos(angleInRadians); // Calculate x position
  const y = center + radius * Math.sin(angleInRadians); // Calculate y position
  return { x, y };
};

// Function to generate the arc path
const generateArcPath = (startAngle: number, arcLength: number, center: number, radius: number) => {
  const start = calculatePosition(startAngle, center, radius); // Start of the arc
  const end = calculatePosition(startAngle + arcLength, center, radius); // End of the arc

  // Arc path using SVG's "A" command (arc to)
  const largeArcFlag = arcLength > 180 ? 1 : 0; // Determine if the arc should be "large"
  return `M ${start.x},${start.y} A ${radius},${radius} 0 ${largeArcFlag} 1 ${end.x},${end.y}`;
};

interface CircleSpinnerProps {
  size?: string;
  className?: string;
  baseColor?: string;
  arcColor?: string;
  arcLength?: number;
}
export const CircleSpinner: React.FC<CircleSpinnerProps> = React.forwardRef<SVGSVGElement, CircleSpinnerProps>(
  (
    {
      className,
      baseColor = '#E7E9EC',
      arcColor = '#4A7FE5',
      arcLength = 100,
      size = '100%', // Default to 100% to fill the container
    },
    ref
  ) => {
    // Initialize animation controls
    const controls = useAnimation();
    // Define the size of the SVG drawing area
    const viewBoxSize = 100;
    // Calculate the radius of the circle based on the viewBox size and stroke width
    const radius = viewBoxSize / 2 - STROKE_WIDTH * 2;
    const center = viewBoxSize / 2; // Center of the SVG (same for cx and cy of the circle)

    React.useEffect(() => {
      controls.start({
        d: Array.from({ length: 361 }, (_, i) => generateArcPath(i, arcLength, center, radius)), // Animate arc around the circle,
        transition: {
          repeat: Infinity,
          duration: 1.5,
          ease: 'linear',
        },
      });
    }, [arcLength, center, controls, radius]);

    return (
      <motion.svg
        width={size}
        height={size}
        viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        ref={ref}
      >
        <circle cx={viewBoxSize / 2} cy={viewBoxSize / 2} r={radius} fill="#FFFFFF" />
        <circle cx={viewBoxSize / 2} cy={viewBoxSize / 2} r={radius} stroke={baseColor} strokeWidth={'7%'} />
        <motion.path
          d={generateArcPath(0, arcLength, center, radius)} // Initial arc path
          stroke={arcColor}
          strokeWidth="7"
          strokeLinecap="round"
          fill="transparent"
          animate={controls}
        />
      </motion.svg>
    );
  }
);

CircleSpinner.displayName = 'CircleSpinner';
