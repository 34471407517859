/* eslint-disable max-len */
import React, { Fragment, useCallback, useMemo, useState } from 'react';

import { Tab, Transition, Listbox } from '@headlessui/react';
import * as Popover from '@radix-ui/react-popover';
import { ClassNameValue } from 'tailwind-merge';

import ImageWrapper from 'components/common-n4/image';
import { ViewMoreButton } from 'components/common-n4/view-more-button';
import fonts from 'components/layout/fonts';
import IconChevronDownSm from 'images/icon-chevron-down-sm.svg';
import IconInfoSm from 'images/icon-info-sm.svg';
import logger from 'lib/logger';
import { cx, tw } from 'lib/utils';
import type { Model, Accessory } from 'types/release';

const log = logger({ category: 'n4/Accessories' });

const FEATURES_TO_SHOW_INITIALLY = 6;

interface AccessoryCardProps {
  accessory: Accessory;
  className: ClassNameValue;
}

const AccessoryCard: React.FC<AccessoryCardProps> = ({ accessory, className }) => {
  return (
    <div className={tw('w-full text-navy', className)}>
      <div className="flex justify-center">
        <ImageWrapper
          key={accessory.name}
          image={accessory.image}
          origin="Accessories"
          imgProps={{ className: 'bg-white rounded-2xl border border-navy/20 h-[240px] w-full object-contain p-3' }}
        />
      </div>
      <h3 className="mt-4 text-[20px] font-medium -tracking-[0.6px] lg:mt-6 lg:text-xl">{accessory.name}</h3>
      <ul className="mt-[12px] flex flex-wrap gap-[6px] lg:mt-3">
        {accessory.functions.map((fn) => (
          <li key={fn.name}>
            <Popover.Root>
              <div className="flex items-center gap-2 rounded-full bg-navy/5 px-4 py-2 text-[14px] -tracking-[0.42px] lg:text-sm">
                {fn.name}
                {fn?.description && (
                  <Popover.Trigger key={fn.name}>
                    <IconInfoSm />
                  </Popover.Trigger>
                )}
              </div>
              <Popover.Portal>
                <Popover.Content className="PopoverContent" sideOffset={5} side="top">
                  <div className="flex max-w-[270px] flex-col gap-[4px] rounded-[8px] bg-white px-[12px] py-[8px] shadow-sm lg:max-w-[500px] lg:gap-2 lg:rounded-lg lg:p-4">
                    <p className="text-[12px] -tracking-[0.48px] text-navy lg:text-base">{fn?.name}</p>
                    <p className="text-[16px] -tracking-[0.42px] text-navy/80 lg:text-sm">{fn?.description}</p>
                  </div>
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </li>
        ))}
      </ul>
      <p className="mt-[24px] text-base leading-[1.6] -tracking-[0.48px] opacity-80 lg:mt-6 lg:text-base">{accessory.description}</p>
    </div>
  );
};

interface AccessoriesTabPanelProps {
  accessories: Accessory[];
  isShowing: boolean;
}

const AccessoriesTabPanel: React.FC<AccessoriesTabPanelProps> = ({ accessories, isShowing }) => {
  const [isViewMore, setIsViewMore] = useState<boolean>(false);

  return (
    <>
      <Transition
        as={Fragment}
        show={isShowing}
        enter="transform transition duration-[400ms]"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 rotate-0 scale-100"
        leaveTo="opacity-0 scale-95 "
      >
        <div className="grid gap-x-10 gap-y-12 lg:grid-cols-3">
          {accessories.map((accessory, index) => (
            <AccessoryCard key={accessory.name} accessory={accessory} className={!isViewMore && index >= FEATURES_TO_SHOW_INITIALLY && 'hidden'} />
          ))}
        </div>
      </Transition>
      <ViewMoreButton
        isViewMore={isViewMore}
        onViewMoreButtonClick={() => setIsViewMore((currentIsViewMore) => !currentIsViewMore)}
        className="mt-12"
        negativeStateText="Show more accessories"
        positiveStateText="Show fewer accessories"
        hidden={(accessories?.length || 0) <= FEATURES_TO_SHOW_INITIALLY}
      />
    </>
  );
};

interface ModelsListboxProps {
  models: Model[];
  selectedIndex: number;
  onChange: (index: number) => void;
}

const ModelsListbox: React.FC<ModelsListboxProps> = ({ models, onChange, selectedIndex }) => {
  return (
    <Listbox as="div" className="relative inline-block w-full text-left lg:w-[250px]" onChange={onChange}>
      <div>
        <Listbox.Button className="relative flex w-full items-center justify-between rounded-full border border-black/10 px-5 py-3 text-left">
          <span className="block truncate text-[16px] -tracking-[0.48px] text-navy lg:text-base">
            {selectedIndex === 0 ? 'All models' : models[selectedIndex - 1].name}
          </span>
          <IconChevronDownSm />
        </Listbox.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Tab.List as={Fragment}>
          <Listbox.Options className="absolute right-0 w-full origin-top-right divide-y divide-neutral-300 rounded-xl border border-neutral-300 lg:mt-[2px] lg:w-[250px]">
            <Listbox.Option value={0}>
              {({ active }) => (
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={tw(
                        'w-full rounded-t-xl px-4 py-[9px] text-left text-[16px] lg:text-base',
                        active || selected ? 'bg-lapis text-white' : 'bg-cream text-navy'
                      )}
                    >
                      <span>All models</span>
                    </button>
                  )}
                </Tab>
              )}
            </Listbox.Option>
            {models.map((model, index) => (
              <Listbox.Option key={model.id} value={index + 1}>
                {({ active }) => (
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={tw(
                          'w-full px-4 py-[9px] text-left text-[16px] lg:text-base',
                          index === models.length - 1 && 'rounded-b-xl',
                          active || selected ? 'bg-lapis text-white' : 'bg-cream text-navy'
                        )}
                      >
                        {model.name}
                      </button>
                    )}
                  </Tab>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Tab.List>
      </Transition>
    </Listbox>
  );
};

interface AccessoriesProps {
  models: Model[];
  releaseName: string;
}

export const Accessories: React.FC<AccessoriesProps> = ({ models, releaseName }) => {
  log.debug('models: %o', models);
  const [isShowing, setIsShowing] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const accessoriesOfAllModels = useMemo(() => {
    const seen = new Set();
    return models
      .flatMap((model) => model.accessories)
      .filter((accessory) => {
        if (seen.has(accessory.name)) {
          return false;
        }
        seen.add(accessory.name);
        return true;
      });
  }, [models]);

  const modelsWithAtLeastOneAccessory = useMemo(() => models.filter(({ accessories }) => accessories.length > 0), [models]);

  const onTabClick = useCallback(() => {
    setIsShowing(false);
    setTimeout(() => setIsShowing(true), 100);
  }, []);

  if (!accessoriesOfAllModels.length) {
    return null;
  }

  return (
    <Tab.Group
      as="div"
      className={cx(fonts.hankenGrotesk.className, 'flex max-w-[1344px] flex-col gap-9 pb-[24px] lg:gap-12 lg:pb-16')}
      selectedIndex={selectedIndex}
      onChange={setSelectedIndex}
    >
      <div className="flex flex-col justify-between lg:flex-row lg:gap-48">
        <p className="pt-[16px] text-[16px] -tracking-[0.48px] text-navy lg:pt-4 lg:text-base">
          {modelsWithAtLeastOneAccessory.length > 1
            ? `The following accessories are compatible with devices in the ${releaseName} product family. Use the blue buttons to show accessories compatible with a specific model.`
            : `The following accessories are compatible with the ${releaseName}.
`}
        </p>
        {models.length > 1 && (
          <div className="flex max-w-[575px] items-center gap-4 pt-[24px] lg:pt-0">
            <h2 className="hidden whitespace-nowrap text-base -tracking-[0.54px] lg:block">Compatible with:</h2>
            <ModelsListbox
              models={modelsWithAtLeastOneAccessory}
              selectedIndex={selectedIndex}
              onChange={(index: number) => {
                if (index === selectedIndex) {
                  return;
                }
                setSelectedIndex(index);
                onTabClick();
              }}
            />
          </div>
        )}
      </div>
      <Tab.Panels as="section">
        <Tab.Panel className={tw('flex flex-col justify-end', accessoriesOfAllModels.length > 3 && 'min-h-[900px]')}>
          <AccessoriesTabPanel accessories={accessoriesOfAllModels} isShowing={isShowing} />
        </Tab.Panel>
        {modelsWithAtLeastOneAccessory.map((model) => (
          <Tab.Panel key={model.name} className={tw('flex min-h-[478px] flex-col justify-end', modelsWithAtLeastOneAccessory.length > 3 && 'min-h-[900px]')}>
            <AccessoriesTabPanel accessories={model.accessories} isShowing={isShowing} />
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};
