import { type ReactElement } from 'react';

import OptimizedImage from './optimized-image';
import type { ImageUrlBuilderProps, ImgixImageProps, ImageProps } from './types';

const toUrl = (builderProps: ImageUrlBuilderProps): string => {
  const { baseUrl, width, height, quality, trim } = builderProps;
  const args = {
    auto: 'format',
    fit: 'crop',
    crop: 'top,center',
    quality,
    width,
    height,
    trim,
  };
  const params = Object.entries(args)
    .filter(([, v]) => !!v)
    .reduce((a, [k, v]) => [...a, `${k}=${v}`], [] as string[])
    .join('&');
  const url = `https://${process.env.NEXT_PUBLIC_IMGIX_URL}/${baseUrl}?${params}`;
  return url;
};

const ImgixImage = (props: ImageProps): ReactElement => {
  const { sizingProps, image, origin, ...rest } = props;
  const { imgix, nativeWidth, nativeHeight } = image as ImgixImageProps;
  const mergedSizingProps = { ...sizingProps, nativeWidth, nativeHeight, toUrl };
  if (nativeWidth && nativeHeight && nativeHeight !== 'auto') {
    const aspectRatio = (1.0 * nativeWidth) / nativeHeight;
    if (Number.isNaN(aspectRatio)) {
      console.error('Bad aspect ratio for imgix: %s: nw: %o, nh: %o', imgix, nativeWidth, nativeHeight);
    } else {
      Object.assign(mergedSizingProps, { aspectRatio });
    }
  }
  return <OptimizedImage {...rest} image={imgix} sizingProps={mergedSizingProps} origin={origin || `imgix-${imgix}`} />;
};

export default ImgixImage;
