/* These are components that aren't used on every page so are loaded using dynamic imports
 * to keep the main bundle smaller.
 */
import dynamic from 'next/dynamic';

const components = {
  Accessory: dynamic(() => import(/* webpackChunkName: 'SbAccessory' */ 'components/bloks/unused/accessory')),
  'Article Card': dynamic(() => import(/* webpackChunkName: 'SbArticleCard' */ 'components/bloks/unused/article_card')),
  'Audio File': dynamic(() => import(/* webpackChunkName: 'SbAudioFile' */ 'components/bloks/unused/audio_file')),
  'Brand Card': dynamic(() => import(/* webpackChunkName: 'SbBrandCard' */ 'components/bloks/unused/brand_card')),
  'Brand Releases': dynamic(() => import(/* webpackChunkName: 'SbBrandReleases' */ 'components/bloks/unused/brand_releases')),
  'Call to Action Block': dynamic(() => import(/* webpackChunkName: 'SbCallToActionBlock' */ 'components/bloks/unused/call_to_action_block')),
  'Call to action button': dynamic(() => import(/* webpackChunkName: 'SbCallToActionButton' */ 'components/bloks/unused/call_to_action_button')),
  'Chart Tool': dynamic(() => import(/* webpackChunkName: 'SbChartTool' */ 'components/bloks/unused/chart_tool')),
  'Cited Blockquote': dynamic(() => import(/* webpackChunkName: 'SbCitedBlockquote' */ 'components/bloks/unused/cited_blockquote')),
  CSS: dynamic(() => import(/* webpackChunkName: 'SbCSS' */ 'components/bloks/unused/css')),
  // directory goes away when we migrate to compare page
  DirectoryContainer: dynamic(() => import(/* webpackChunkName: 'SbDirectoryContainer' */ 'components/bloks/directory_container')),
  FAQ: dynamic(() => import(/* webpackChunkName: 'SbFAQ' */ 'components/bloks/faq')),
  'Form Factors': dynamic(() => import(/* webpackChunkName: 'SbFormFactors' */ 'components/bloks/unused/form_factors')),
  'Hearing Aids': dynamic(() => import(/* webpackChunkName: 'SbHearingAids' */ 'components/bloks/unused/hearing_aids')),
  'Hearing aids by feature': dynamic(() => import(/* webpackChunkName: 'SbHearingAidsByFeature' */ 'components/bloks/unused/hearing_aids_by_feature')),
  'Inline Image': dynamic(() => import(/* webpackChunkName: 'SbInlineImage' */ 'components/bloks/unused/inline_image')),
  Image: dynamic(() => import(/* webpackChunkName: 'SbImage' */ 'components/bloks/image')),
  Markdown: dynamic(() => import(/* webpackChunkName: 'SbMarkdown' */ 'components/bloks/markdown')),
  Marquee: dynamic(() => import(/* webpackChunkName: 'SbMarquee' */ 'components/bloks/marquee')),
  'Nested Blocks': dynamic(() => import(/* webpackChunkName: 'SbNestedBlocks' */ 'components/bloks/nested_blocks')),
  'Prices by Release': dynamic(() => import(/* webpackChunkName: 'SbPricesByRelease' */ 'components/bloks/unused/prices_by_release')),
  'Prices by Technology Level': dynamic(() => import(/* webpackChunkName: 'SbPricesByTechnologyLevel' */ 'components/bloks/unused/prices_by_technology_level')),
  'Product Review': dynamic(() => import(/* webpackChunkName: 'SbProductReview' */ 'components/bloks/unused/product_review')),
  'Pros and Cons': dynamic(() => import(/* webpackChunkName: 'SbProsAndCons' */ 'components/bloks/unused/pros_and_cons')),
  'Release Card Custom': dynamic(() => import(/* webpackChunkName: 'SbReleaseCardCustom' */ 'components/bloks/unused/release_card_custom')),
  'Release Card': dynamic(() => import(/* webpackChunkName: 'SbReleaseCard' */ 'components/bloks/unused/release_card')),
  Sidebar: dynamic(() => import(/* webpackChunkName: 'SbSidebar' */ 'components/bloks/unused/sidebar')),
  Table: dynamic(() => import(/* webpackChunkName: 'SbTable' */ 'components/bloks/unused/table')),
  Timeline: dynamic(() => import(/* webpackChunkName: 'SbTimeline' */ 'components/bloks/unused/timeline')),
  'h2 with subtitle': dynamic(() => import(/* webpackChunkName: 'SbH2WithSubtitle' */ 'components/bloks/unused/h2_with_subtitle')),
  staff_card: dynamic(() => import(/* webpackChunkName: 'SbStaffCard' */ 'components/bloks/unused/staff_card')),
  star_rating: dynamic(() => import(/* webpackChunkName: 'SbStarRating' */ 'components/bloks/unused/star_rating')),
  static_page_author: dynamic(() => import(/* webpackChunkName: 'SbStaticPageAuthor' */ 'components/bloks/unused/static_page_author')),
  static_page_author_old: dynamic(() => import(/* webpackChunkName: 'SbStaticPageAuthorOld' */ 'components/bloks/unused/static_page_author_old')),
  youtube: dynamic(() => import(/* webpackChunkName: 'SbYoutube' */ 'components/bloks/youtube')),
};

export default components;
