import * as React from 'react';

import { storyblokEditable } from '@storyblok/react';
import NextLink from 'next/link';

import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants.js';
import { CircleScore } from 'components/common-n4/circle-score';
import ImageWrapper from 'components/common-n4/image';
import * as Popover from 'components/common-n4/popover';
import Currency from 'components/widgets/currency';
import { useApiData } from 'hooks/use_api_data';
import { useIsMediumOrMobileView } from 'hooks/use_is_mobile_view';
import logger from 'lib/logger';
import { normalizeUrl } from 'lib/utils';

import styles from './product.module.scss';

import ExpandedProduct from '../nav-expanded/product';

const log = logger({ category: 'nav/Product' });

const NavProduct = ({ blok, story, ...props }) => {
  log.debug('blok: %o', blok);
  const apiData = useApiData();
  const product = apiData.models[blok.form_factor];
  const isMediumOrMobileView = useIsMediumOrMobileView();
  const expand =
    story?.full_slug?.endsWith('components/nav') &&
    !/no-expand/.test(story.content.debug) &&
    (typeof window === 'undefined' || !/no-expand/.test(window.location.search));
  const { close } = Popover.usePopoverContext(expand); // ignore popover in expanded mode

  if (expand) {
    return <ExpandedProduct blok={blok} story={story} {...props} />;
  }

  if (!product?.path) return null;

  const { image, full_name: fullName, name, path, release, score } = product;

  return (
    <div className={`${styles.wrapper} nav-product-wrapper`} {...storyblokEditable(blok)}>
      <NextLink
        prefetch={false}
        href={normalizeUrl({ url: path, origin: 'nav/product' })}
        onClick={close}
        data-track
        data-event-name="nav-product"
        data-event-release={release.slug}
      >
        <section className={styles.product}>
          <figure className="p-1">
            {image ? (
              <>
                <ImageWrapper image={image} imgProps={{ alt: name || fullName, loading: 'eager' }} sizingProps={{ maxWidth: 320 }} origin="nav-product" />
                {score && !isMediumOrMobileView ? (
                  <CircleScore progress={(score / HEARING_AID_MODEL_SCORE_TOTAL) * 100} amount={score} isRight isSmall origin="nav/product" />
                ) : null}
              </>
            ) : (
              <div className={styles.placeholder} />
            )}
          </figure>
          <section className={styles.details}>
            <h4>{release.full_name}</h4>
            {release.description && <div className={styles.description}>{release.description}</div>}
            <div className={styles.price}>
              <Currency
                price={product.price?.purchase_price}
                fallback="Price not available"
                prefix="From "
                suffix={product.price?.leasing ? ' / mth' : ' a pair'}
              />
            </div>
          </section>
        </section>
      </NextLink>
    </div>
  );
};

export default NavProduct;
