import { storyblokEditable } from '@storyblok/react';

import { ArticleFeatureCard } from 'components/common-n4/article-feature-card/article-feature-card';

import styles from './hero-reviews.module.scss';

const HomeArticle = ({ blok }) => (
  <ArticleFeatureCard
    className={styles.secondaryArticleCard}
    image={blok.image}
    title={blok.title}
    body={blok.description}
    url={blok.link.cached_url}
    {...storyblokEditable(blok)}
  />
);

export default HomeArticle;
