import styles from './background.module.scss';

export const BackgroundVideo = () => (
  <div className={styles.background}>
    <video autoPlay loop muted playsInline preload="auto" className={styles.video} poster="/images/design/ht-intro-poster.jpg">
      <source src="/images/design/ht-intro.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div className={styles.overlay} />
  </div>
);
