/* eslint-disable max-len */
import { PropsWithChildren } from 'react';

export const TooltipTitle: React.FC<PropsWithChildren> = ({ children }) => <p className="text-[12px] -tracking-[0.36px] text-lapis lg:text-base">{children}</p>;
export const TooltipSubtitle: React.FC<PropsWithChildren> = ({ children }) => <p className="text-[16px] text-navy lg:text-sm">{children}</p>;
export const TooltipDescription: React.FC<PropsWithChildren> = ({ children }) => <p className="text-sm text-navy">{children}</p>;

export const Tooltip: React.FC<PropsWithChildren> = ({ children }) => (
  <div className="flex max-w-[270px] flex-col gap-[4px] rounded-[8px] bg-white px-[12px] py-[8px] shadow-sm lg:max-w-[500px] lg:gap-1 lg:rounded-lg lg:px-4 lg:py-3">
    {children}
  </div>
);
