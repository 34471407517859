import React from 'react';

import { StoryblokComponent, storyblokEditable, type SbBlokData } from '@storyblok/react';

import { buildToc } from 'lib/storyblok';

interface Blok extends SbBlokData {
  blocks: SbBlokData[];
}

interface StoryContent {
  blocks: SbBlokData[];
}

interface Story {
  content: StoryContent;
  cv: number;
}

type TocType = Record<string, string>;

interface SidebarProps {
  blok: Blok;
  story: Story;
  tableOfContents?: TocType;
}

interface TocProps {
  toc: TocType;
}

const Toc: React.FC<TocProps> = ({ toc }) =>
  Object.entries(toc).length > 0 && (
    <ul>
      <li>Table of Contents</li>
      {Object.entries(toc).map(([id, title]) => (
        <li key={id}>
          <a href={`#${id}`}>{title}</a>
        </li>
      ))}
    </ul>
  );

const Sidebar: React.FC<SidebarProps> = ({ blok, story, tableOfContents }) => {
  const { blocks } = blok;
  const [toc, setToc] = React.useState<TocType | undefined>(tableOfContents);

  const disableToc = !!story.content?.blocks?.find((b) => b.component === 'n4-page-config')?.disable_toc;

  // This is for storyblok visual editor to update the TOC as the story changes
  React.useEffect(() => {
    setToc(buildToc({ story }));
  }, [story]);

  return (
    <section {...storyblokEditable(blok)}>
      {!disableToc && toc && <Toc toc={toc} />}
      {blocks.map((b) => (
        <StoryblokComponent key={b._uid} blok={b} story={story} {...storyblokEditable(b)} />
      ))}
    </section>
  );
};

export default Sidebar;
