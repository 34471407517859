import React from 'react';

import NextLink from 'next/link';

import { Button } from 'components/common-n4/button';
import ImageWrapper from 'components/common-n4/image';
import { ImageSource } from 'components/common-n4/image/types';
import fonts from 'components/layout/fonts';
import { useWindowDimensions } from 'hooks/use_window_dimensions';
import { cx, normalizeUrl } from 'lib/utils';

import styles from './feature-cta-card.module.scss';

const PRODUCT_CARD_MOBILE_MAX_WIDTH = 354;
const PRODUCT_CARD_MOBILE_PADDING = 18;

export interface BackgroundImageProps {
  image: ImageSource;
}

const BackgroundImage: React.FC<BackgroundImageProps> = ({ image }) => (
  <div className={styles.image}>
    <div className={styles.overlay} />
    <ImageWrapper image={image} origin={'feature-cta-card'} />
  </div>
);

export type RootProps = React.PropsWithChildren<{
  url: string;
  variant?: 'merigold' | 'sky' | 'lime' | 'lavender';
  extended?: boolean;
  image?: ImageSource;
  className?: string;
}>;

export const Root: React.FC<RootProps> = ({ variant = 'merigold', extended = false, image, url, className, children, ...props }) => {
  const { width } = useWindowDimensions(true);
  return (
    <NextLink
      prefetch={false}
      href={normalizeUrl({ url, origin: 'home/feature-cta-card#root' })}
      className={cx(
        fonts.hankenGrotesk.className,
        !extended && styles[variant],
        extended ? styles['ht-feature-cta-card--extended'] : styles['ht-feature-cta-card--default']
      )}
      style={{ width: width! < PRODUCT_CARD_MOBILE_MAX_WIDTH && !extended ? width! - PRODUCT_CARD_MOBILE_PADDING * 2 : '100%' }}
      {...props}
    >
      {image && <BackgroundImage image={image} />}
      {children}
    </NextLink>
  );
};

export interface TitleProps {
  title: string;
}

export const Title: React.FC<TitleProps> = ({ title }) => {
  return <h6 className={cx(styles.title, fonts.hankenGrotesk.className)}>{title}</h6>;
};

export interface ActionProps {
  url?: string;
  hint?: string;
}

export const Action: React.FC<ActionProps> = ({ url, hint }) => {
  return (
    <div className={styles.action}>
      {hint && <p className={cx(styles.hint, fonts.hankenGrotesk.className)}>{hint}</p>}
      {url && <Button.Icon onClick={() => {}} className={styles.iconButtonLink} icon="arrow-right-regular" />}
    </div>
  );
};
