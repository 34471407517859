import { storyblokEditable } from '@storyblok/react';
import ReactMarkdown from 'react-markdown';

import styles from './blockquote.module.scss';

const Blockquote = ({ blok }) => (
  <section {...storyblokEditable(blok)} className={styles.blockquote}>
    <blockquote>
      <ReactMarkdown>{blok.quote}</ReactMarkdown>
    </blockquote>
    {blok.author && <cite>{blok.author}</cite>}
  </section>
);

export default Blockquote;
