import React from 'react';

import { storyblokEditable, type SbBlokData } from '@storyblok/react';

import { ImageSource } from 'components/common-n4/image/types';

import { MultiCtaCards, MultiCtaCardProps } from './multi-cta';

interface Blok extends SbBlokData {
  cards: MultiCtaCardProps[];
}

interface MultiCtaProps {
  blok: Blok;
}

const MultiCta: React.FC<MultiCtaProps> = ({ blok }) => {
  const multiCtaCardList = blok.cards.map((card) => ({
    component: card.component,
    _uid: card._uid,
    title: card.title,
    link: { cached_url: card.link.cached_url },
    image: card.image as ImageSource,
    link_text: card.link_text,
    wide: card.wide,
  }));

  return (
    <section {...storyblokEditable(blok)}>
      <MultiCtaCards cards={multiCtaCardList} />
    </section>
  );
};

export default MultiCta;
