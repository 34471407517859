import * as React from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import ImageWrapper from 'components/common-n4/image';
import AARPLogo from 'images/logo-aarp.svg';
import ForbesLogo from 'images/logo-forbes.svg';
import NYTLogo from 'images/logo-nyt.svg';
import PubMedLogo from 'images/logo-pubmed.svg';
import { tw } from 'lib/utils';

import styles from './shared.module.scss';

// import { ProviderFlowContext } from './provider-flow';

const SlideOne = () => (
  <>
    <span className="text-[12px] leading-4 tracking-tight">As seen in:</span>
    <div className="mt-3 grid grid-cols-2 grid-rows-2 gap-[8px] lg:gap-4">
      <div className="flex h-[48px] items-center justify-center rounded-[10px] bg-navy/20 backdrop-blur-xl lg:h-[56px]">
        <NYTLogo />
      </div>
      <div className="flex h-[48px] items-center justify-center rounded-[10px] bg-navy/20 backdrop-blur-xl lg:h-[56px]">
        <ForbesLogo />
      </div>
      <div className="flex h-[48px] items-center justify-center rounded-[10px] bg-navy/20 backdrop-blur-xl lg:h-[56px]">
        <AARPLogo />
      </div>
      <div className="flex h-[48px] items-center justify-center rounded-[10px] bg-navy/20 backdrop-blur-xl lg:h-[56px]">
        <PubMedLogo className="h-[28px] lg:h-[36px]" />
      </div>
    </div>
  </>
);

const SlideTwo = () => (
  <div className="rounded-[20px] bg-navy/20 p-[24px] backdrop-blur-xl">
    <div className="pr-20 text-[20px] font-medium tracking-tight lg:pr-12 lg:text-2xl">“The easiest way to save money on state-of-the-art hearing aids”</div>
    <div className="mt-[20px] flex items-start gap-[12px] lg:mt-8 lg:gap-3">
      <div className="shrink-0">
        <ImageWrapper image="available/abram-thumbnail.jpg" origin="test-page" imgProps={{ className: 'rounded-full !static !w-[42px]' }} />
      </div>
      <div className="pr-16">
        <div className="text-[14px] leading-[140%] tracking-tight">Abram Bailey</div>
        <div className="text-[12px] font-light leading-[130%] tracking-tight text-white/80">Founder of HearingTracker — America’s #1 Hearing Aid Website</div>
      </div>
    </div>
  </div>
);

const slides = [SlideOne, SlideTwo];

interface ProviderPopupLayoutProps extends React.PropsWithChildren {
  currentSlide?: number;
}

export const ProviderPopupLayout: React.FC<ProviderPopupLayoutProps> = ({ children, currentSlide }) => {
  // const { currentSlide } = React.useContext(ProviderFlowContext);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     setCurrentSlide?.(1);
  //   }, 5000);
  // }, [setCurrentSlide]);

  return (
    <section className="gap-3 p-3 lg:grid lg:max-w-[930px] lg:grid-cols-[minmax(440px,1fr)_1fr]">
      <div
        className={tw(styles['pop-up-image'], 'flex aspect-[1.08] flex-col justify-end overflow-hidden rounded-[24px] pb-4 lg:aspect-auto lg:min-h-[515px]')}
      >
        <div className={tw('flex w-[200%] text-white transition-transform duration-500 ease-out', [2, 3, 4].includes(currentSlide || 0) && '-translate-x-1/2')}>
          {slides.map((Slide, index) => (
            <div key={index} className="flex w-1/2 flex-col justify-end px-6">
              <Slide />
            </div>
          ))}
        </div>
        {/* <div className="nav mt-4 flex justify-center">
          <ul className="flex gap-2 rounded-full bg-white/10 px-[10px] py-[6px] backdrop-blur-xl">
            {slides.map((_, index) => (
              <li key={index} className={tw('h-[4px] w-[24px] rounded-full bg-white/20', index === currentSlide && 'bg-white')} />
            ))}
          </ul>
        </div> */}
      </div>
      <AnimatePresence>
        <motion.div initial={{ x: 50, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: -50, opacity: 0 }} transition={{ ease: 'easeOut' }}>
          {children}
        </motion.div>
      </AnimatePresence>
    </section>
  );
};
