import React, { useMemo, useRef } from 'react';

import { motion, useScroll } from 'framer-motion';

import fonts from 'components/layout/fonts';
import { useWindowDimensions, WINDOW_DIMENSION_TYPE } from 'hooks/use_window_dimensions';
import { cx, getWindowDimensionType } from 'lib/utils';

import styles from './hero-lower-cards.module.scss';

import { FeatureCtaCard, FeatureCtaCardProps } from '../../feature-cta-card/feature-cta-card';
import { MultiCarousel } from '../../multi-carousel/multi-carousel';

interface HeroLowerCardsProps {
  className?: string;
  title: string;
  heroCardList: FeatureCtaCardProps[];
}

export const HeroLowerCards: React.FC<HeroLowerCardsProps> = ({ className, title, heroCardList }) => {
  const { width } = useWindowDimensions();
  const containerRef = useRef(null);
  const dimensionType = getWindowDimensionType(width);
  const { scrollYProgress } = useScroll({ target: containerRef, offset: ['start end', 'end 1'] });

  const heroLowerCardConfig = {
    mobile: { width: 318, gap: 18 },
    desktop: { width: 318, gap: 24 },
    wide: { width: 474, gap: 24 },
  };

  const heroLowerCardList = useMemo(
    () =>
      heroCardList.map((herocard: FeatureCtaCardProps, index) => (
        <FeatureCtaCard key={index} variant={herocard.variant} title={herocard.title} image={herocard.image} url={herocard.url} />
      )),
    [heroCardList]
  );

  return (
    <motion.div
      ref={containerRef}
      style={{ opacity: scrollYProgress }}
      className={cx(fonts.hankenGrotesk.className, styles['ht-hero-lower-content'], className)}
    >
      <p className={styles.title}>{title}</p>
      {dimensionType === WINDOW_DIMENSION_TYPE.desktop || dimensionType === WINDOW_DIMENSION_TYPE.wide ? (
        <div className={styles.cardContainer}>{heroLowerCardList}</div>
      ) : (
        <div className={styles.carouselContainer}>
          <MultiCarousel items={heroLowerCardList} itemSpaceConfigs={heroLowerCardConfig} className="hero-lower-card-carousel" />
        </div>
      )}
    </motion.div>
  );
};
