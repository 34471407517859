import React, { useCallback, useEffect, useState } from 'react';

import { type EmblaCarouselType } from 'embla-carousel';

import IconArrowLeft from 'images/icon-arrow-left.svg';
import IconArrowRight from 'images/icon-arrow-right.svg';
import { cx } from 'lib/utils';

import styles from './gallery.module.scss';

interface UsePrevNextButtonsType {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
}

export const usePrevNextButtons = (emblaApi: EmblaCarouselType | undefined): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect();
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

type PropType = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const PrevButton: React.FC<PropType> = (props) => {
  const { className, ...restProps } = props;

  return (
    <button className={cx(styles['gallery__nav-button'], styles['gallery__nav-button--prev'], className)} type="button" {...restProps}>
      <IconArrowLeft />
    </button>
  );
};

export const NextButton: React.FC<PropType> = (props) => {
  const { className, ...restProps } = props;

  return (
    <button className={cx(styles['gallery__nav-button'], styles['gallery__nav-button--next'], className)} type="button" {...restProps}>
      <IconArrowRight />
    </button>
  );
};
