import { type SbBlokData, storyblokEditable } from '@storyblok/react';

import fonts from 'components/layout/fonts';
import { cx } from 'lib/utils';

import styles from './index.module.scss';

import { HeroLowerHeader } from './hero-lower-header/hero-lower-header';

import { HeroLowerCards } from '../hero-lower/hero-lower-cards/hero-lower-cards';

interface ColorCardProps {
  text: string;
  image: string;
  link: { url: string };
}

interface BlokProps extends SbBlokData {
  className?: string;
  headline: string;
  title_right: string;
  text_right: string;
  color_cards: ColorCardProps[];
}

interface HeroLowerProps {
  blok: BlokProps;
}

const HeroLower: React.FC<HeroLowerProps> = ({ blok }) => {
  const variants: ('merigold' | 'sky' | 'lime' | 'lavender')[] = ['merigold', 'sky', 'lime', 'lavender'];
  const LowerCardList = blok.color_cards.map((card: ColorCardProps, index: number) => ({
    title: card.text,
    image: card.image,
    url: card.link.url,
    variant: variants[index % 4],
  }));
  return (
    <section id="explore" className={cx(styles['ht-hero-lower'], blok.className, fonts.hankenGrotesk.className)} {...storyblokEditable(blok)}>
      <HeroLowerHeader headline={blok.headline} titleRight={blok.title_right} textRight={blok.text_right} />
      <HeroLowerCards title={'How can we help?'} heroCardList={LowerCardList} />
    </section>
  );
};

export default HeroLower;
