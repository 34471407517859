import * as React from 'react';

import { ContentProps } from '../../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Subscribed: React.FC<ContentProps> = ({ dispatch, state, handleClose }) => {
  const response = state.subscribeResponse!;
  return (
    <div className="flex min-h-[50dvh] max-w-full flex-col items-center justify-center p-10 lg:h-[530px] lg:w-[600px] lg:px-[120px] lg:py-8">
      <div className="">
        <div className="flex items-center justify-center text-center text-[32px] font-medium leading-[120%] tracking-tight text-navy lg:leading-7">
          {response?.message || 'Thanks! We’ll be in touch.'}
        </div>
        <div className="mt-4 text-center text-[16px] font-light leading-[130%] tracking-tight text-navy-90">
          {response?.caption || 'We’ve received your information and we will notify you if any discounted hearing aids become available'}
        </div>
      </div>
    </div>
  );
};
