import { useWindowDimensions } from './use_window_dimensions';

export const useIsMobileView = () => {
  const { width } = useWindowDimensions();

  return Boolean(width <= 767);
};

export const useIsMediumOrMobileView = () => {
  const { width } = useWindowDimensions(true);

  return width === null ? false : Boolean(width <= 991);
};

export const useIsLargeOrLess = () => {
  const { width } = useWindowDimensions();

  return Boolean(width <= 1199);
};

export const useIsWideScreenView = () => {
  const { width } = useWindowDimensions();

  return Boolean(width >= 1600);
};
