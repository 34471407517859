/*
 * This is just for layout information that is accessed via props; no display;
 * Note: some layout comes from the old Title & Meta and N4 Meta bloks, but
 * we're migrating to use n4-layout-options for that.
 */
import logger from 'lib/logger';

const log = logger({ category: 'n4/page_config' });

const PageConfig = ({ blok, story, ...props }) => {
  log.debug('blok: %o, story: %o', blok, story.slug);
  log.debug('pageConfig: %o', props);
  return null;
};

export default PageConfig;
