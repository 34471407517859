import { useEffect, useState } from 'react';

import throttle from 'lodash/throttle';

export const WINDOW_DIMENSION_TYPE = {
  mobile: 'mobile',
  sm: 'sm',
  md: 'md',
  lg: 'lg,',
  laptop: 'laptop',
  desktop: 'desktop',
  wide: 'wide',
};

// based on sizes set in variables.scss file
export const WINDOW_DIMENSIONS = [
  { limit: 767, type: WINDOW_DIMENSION_TYPE.sm },
  { limit: 991, type: WINDOW_DIMENSION_TYPE.md },
  { limit: 1199, type: WINDOW_DIMENSION_TYPE.lg },
  { limit: 1399, type: WINDOW_DIMENSION_TYPE.laptop },
  { limit: 1400, type: WINDOW_DIMENSION_TYPE.desktop },
  { limit: 1640, type: WINDOW_DIMENSION_TYPE.wide },
];

export const useWindowDimensions = (shouldResetInitialValues) => {
  const [windowDimensions, setWindowDimensions] = useState({
    // defaults for server side rendering
    // updated to use the mobile size that google probably uses
    width: shouldResetInitialValues ? null : 412,
    height: shouldResetInitialValues ? null : 823,
  });

  useEffect(() => {
    const throttledHandler = throttle(() => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 300);

    throttledHandler();

    window.addEventListener('resize', throttledHandler);

    return () => window.removeEventListener('resize', throttledHandler);
  }, []); // Empty array ensures that effect is only run on mount

  return windowDimensions;
};
