import SvgIcon from 'components/ui/svg_icon';

const PopoverIcon = ({ title, content, icon = 'question-circle', className, ...props }) => (
  <span
    data-bs-toggle="popover"
    title={title}
    data-bs-content={content}
    data-bs-html
    data-bs-trigger="hover"
    className={[className, 'popover-icon'].join(' ')}
    {...props}
  >
    <SvgIcon icon={icon} />
  </span>
);

export default PopoverIcon;
