// Store various configuration options that change rarely enough that
// doing a new deploy is worth it.

// These are domains where we don't want to addd our own UTM to the links
export const PARTNER_DOMAINS = [
  'afge.org',
  'apple.com',
  'asha.org',
  'ata.org',
  'baaudiology.org',
  'consumerreports.org',
  'costco.com',
  'gov.uk',
  'hearadvisor.com',
  'hearingloss.org',
  'hearingtracker.com',
  'heart.org',
  'hearweb.com',
  'hopkinsmedicine.org',
  'jamanetwork.com',
  'karger.com',
  'lww.com   ',
  'mayoclinic.org',
  'militaryaudiology.org',
  'naag.org',
  'nature.com',
  'nejm.org',
  'nhs.uk',
  'npr.org',
  'nytimes.com',
  'oticon.com',
  'pennmedicine.org',
  'phonak.com',
  'phonakpro.com',
  'psoriasis.org',
  'redcross.org',
  'resound.com',
  'rheumatology.org',
  'sagepub.com',
  'sonova.com',
  'specialolympics.org',
  'starkey.com',
  'statnews.com',
  'thelancet.com',
  'tpahq.org',
  'ucsfhealth.org',
  'uhhospitals.org',
  'umms.org',
  'vumc.org',
  'who.int',
  'wikipedia.org',
  'youtube.com',
  'hearingtrack.com',
  'audicus.com',
  /.*.gov$/,
  /.*.edu$/,
];

export const BRAND_HUB_PAGES = {
  'hear.com': '/hearing-aids/hear-com',
  Audicus: '/hearing-aids/audicus-hearing-aids',
  Beltone: '/hearing-aids/beltone',
  Cochlear: '/cochlear-implants/cochlear-ltd',
  Jabra: '/hearing-aids/jabra-enhance',
  Lexie: '/hearing-aids/lexie',
  Oticon: '/hearing-aids/oticon',
  Phonak: '/hearing-aids/phonak',
  ReSound: '/hearing-aids/resound',
  Sennheiser: '/otc-hearing-aids/sennheiser',
  Signia: '/hearing-aids/signia',
  Sony: '/hearing-aids/sony',
  Starkey: '/hearing-aids/starkey',
  Unitron: '/hearing-aids/unitron',
  Widex: '/hearing-aids/widex',
};
