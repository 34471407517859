import React from 'react';

import { AudioContext } from 'components/common-n4/audio/audio_context';

export const useAudio = ({ sceneId } = {}) => {
  const context = React.useContext(AudioContext);

  React.useEffect(() => {
    context.setSceneId(sceneId);
  }, [sceneId, context]);

  if (!context) {
    throw new Error('useAudio must be used within an AudioProvider');
  }

  return context;
};
