import { useState, useEffect, useRef } from 'react';

import SvgIcon from 'components/ui/svg_icon';
import { cx } from 'lib/utils';

import styles from './accordion.module.scss';

const AccordionItem = ({ header, content, isActive, setCurrentActive, childAccordionHeight = 0, handleChildAccordionToggle = null }) => {
  const bodyRef = useRef(null);

  const [height, setHeight] = useState(isActive ? 'auto' : '0');

  useEffect(() => {
    if (!bodyRef.current) return;

    const bodyHeight = isActive ? childAccordionHeight + bodyRef.current.scrollHeight : 0;

    handleChildAccordionToggle?.({ key: header, value: bodyHeight });
    setHeight(`${bodyHeight}${bodyHeight === 0 ? '' : 'px'}`);
  }, [isActive, header, childAccordionHeight, handleChildAccordionToggle]);

  return (
    <section className={styles.item}>
      <section className={cx(styles.header, isActive && styles['header--open'])}>
        <h5>
          <button
            type="button"
            className={cx(styles.button, isActive && styles['button--open'])}
            onClick={() => (isActive ? setCurrentActive(null) : setCurrentActive(header))}
          >
            {header}
          </button>
        </h5>
        <section ref={bodyRef} style={{ maxHeight: isActive ? height : '0' }} className={cx(styles.body, isActive && styles['body--open'])}>
          <section className={styles.content}>{content}</section>
        </section>
      </section>
      <div className={styles['icon-container']} onClick={() => (isActive ? setCurrentActive(null) : setCurrentActive(header))}>
        <SvgIcon icon={isActive ? 'minus-solid' : 'plus-solid'} alt="dropdown button" className={cx(styles.icon, isActive && styles['icon--rotate-180'])} />
      </div>
    </section>
  );
};

export const Accordion = ({ data, childAccordionHeight, handleChildAccordionToggle }) => {
  const [currentActive, setCurrentActive] = useState(null);

  return (
    <section className={styles.accordion}>
      {data.map((item) => (
        <AccordionItem
          key={item.header}
          childAccordionHeight={childAccordionHeight}
          handleChildAccordionToggle={handleChildAccordionToggle}
          isActive={currentActive === item.header}
          setCurrentActive={setCurrentActive}
          {...item}
        />
      ))}
    </section>
  );
};
