import React from 'react';

import { CircleProgress } from 'components/bloks/n4/images/circle_progress';
import SvgIcon from 'components/ui/svg_icon';
import { useAudio } from 'hooks';
import { formatSecondsToTime } from 'lib/utils';

export const Audio = ({ audio }) => {
  const audioRef = React.useRef(null);
  const [currentTime, setCurrentTime] = React.useState('0:00');
  const [duration, setDuration] = React.useState('0:00');
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  const { currentlyPlaying, setCurrentlyPlaying } = useAudio();

  const updateProgress = React.useCallback(() => {
    if (!audioRef.current) return;
    setProgress(audioRef.current.currentTime / audioRef.current.duration);
  }, []);

  const handlePlay = () => {
    if (!audioRef.current) return;

    // if there is any audio playing, pause it.
    currentlyPlaying?.audio?.pause();

    let updatedTime = 0;
    if (currentlyPlaying?.id === audio?.id && currentTime && currentTime === duration) {
      audioRef.current.currentTime = 0;
    }

    // cancel progress bar animation of any last existing audio.
    if (currentlyPlaying?.audio) {
      audioRef.current.currentTime = currentlyPlaying.audio.currentTime;
      updatedTime = currentlyPlaying.audio.currentTime;
    }
    // throttle current time updates to every one second to avoid extra re-renders
    const timeFormatted = formatSecondsToTime(updatedTime);
    setCurrentTime(timeFormatted);

    // play the audio
    audioRef.current.play();
    // local state knows audio is playing
    setIsPlaying(true);
    // context knows audio is playing so another Audio component can cancel.
    setCurrentlyPlaying({
      id: audio.id,
      audio: audioRef.current,
    });
  };

  const handlePause = React.useCallback(() => {
    if (!audioRef.current) return;

    // to avoid calling pause method twice unnecessarily when another audio element calls to pause this method via currentlyPlaying?.pause();
    if (isPlaying) audioRef.current.pause();

    setIsPlaying(false);
  }, [isPlaying]);

  const handleMetadataLoaded = () => {
    if (audioRef.current && audioRef.current.duration) {
      setDuration(formatSecondsToTime(audioRef.current.duration));
    }
  };

  React.useEffect(() => {
    const audioEl = audioRef.current;

    if (audioEl) {
      audioEl.addEventListener('ended', handlePause);
      // to trigger handlePause when another Audio component triggers pause in the DOM to the audio in this component.
      audioEl.addEventListener('pause', handlePause);
      audioEl.addEventListener('timeupdate', updateProgress);
    }

    return () => {
      if (audioEl) {
        audioEl.removeEventListener('ended', handlePause);
        audioEl.removeEventListener('pause', handlePause);
        audioEl.removeEventListener('pause', updateProgress);
      }
    };
  }, [handlePause, updateProgress]);

  if (!audio?.url) return null;

  return (
    <>
      <button type="button" className="flex self-start" onClick={isPlaying ? handlePause : handlePlay} aria-label={isPlaying ? 'play audio' : 'pause audio'}>
        <div className="flex h-[59px] w-[59px] items-center justify-center rounded-full bg-white shadow md:h-[46px] md:w-[46px]">
          <figure className="relative m-0 flex items-center justify-center rounded-full md:h-[54px] md:min-h-[54px] md:w-[53px] md:min-w-[53px]">
            <CircleProgress size={49} progress={progress * 100} isStatic />
            <figure className="absolute bottom-0 left-[6px] right-0 top-[-2px] m-auto h-[13.25px] min-h-[13.25px] w-[13.25px] min-w-[13.25px] md:left-[4px]">
              <SvgIcon className="md:scale-[0.828]" icon={isPlaying ? 'pause' : 'play'} />
            </figure>
          </figure>
        </div>
      </button>
      <audio ref={audioRef} src={audio.url} type="audio/m4a" preload="none" onLoadedMetadata={handleMetadataLoaded} />
    </>
  );
};
