import React from 'react';

import { Button } from 'components/common-n4/button';
import { CircleScoreFluid } from 'components/common-n4/circle-score-fluid';
import HtLink from 'components/common-n4/ht-link';
import ImageWrapper from 'components/common-n4/image';
import { ImageSource } from 'components/common-n4/image/types';
import fonts from 'components/layout/fonts';
import { AudioButton } from 'components/page/directory/audio_button/audio_button';
import { ProductInfoPopupViaApi } from 'components/page/directory/product-info/product-info-popup';
import Price from 'components/widgets/price';
import { useWindowDimensions } from 'hooks/use_window_dimensions';
import { cx, normalizeUrl } from 'lib/utils';
import { Release } from 'types/release';

import styles from './product-card.module.scss';

const PRODUCT_CARD_MOBILE_MAX_WIDTH = 397;
const PRODUCT_CARD_MOBILE_PADDING = 20;

interface ProductImageProps {
  releaseId: number;
  modelId?: number;
  image: ImageSource;
  url: string;
  slug: string;
  tag?: string;
}

export const ProductImage: React.FC<ProductImageProps> = ({ releaseId, image, url, slug, tag, modelId }) => {
  const [isProductInfoShowing, setIsProductInfoShowing] = React.useState(false);
  // Note: don't track impressions on these because the title impression will cover it, but do track clicks
  return (
    <div className={styles.image}>
      <ProductInfoPopupViaApi releaseId={releaseId} isOpen={isProductInfoShowing} setIsOpen={setIsProductInfoShowing} modelId={modelId} />
      <HtLink
        href={normalizeUrl({ url, origin: 'home/product-card#productImage' })}
        data-track={tag === 'Featured'}
        data-event-name="home-featured-product"
        data-event-release={slug}
        data-origin="home-featured"
        data-event-target="image"
      >
        <ImageWrapper image={image} origin="featured-articles" />
      </HtLink>
      <Button.Icon className={styles.infoButton} icon="magnifying-glass-plus-02-regular" onClick={() => setIsProductInfoShowing(true)} />
    </div>
  );
};

export type TitleProps = React.PropsWithChildren<{
  url: string;
  slug: string;
  tag?: string;
}>;

export const Title: React.FC<TitleProps> = ({ url, slug, tag, children }) => {
  return (
    <HtLink
      href={normalizeUrl({ url, origin: 'home/product-card#title' })}
      className={cx(fonts.hankenGrotesk.className, styles.title)}
      data-track={tag === 'Featured'}
      data-track-impression
      data-event-name="home-featured-product"
      data-event-release={slug}
      data-origin="home-featured"
      data-event-target="title"
    >
      {children}
    </HtLink>
  );
};

export interface PriceProps {
  price: number;
}

export type DescriptionProps = React.PropsWithChildren<{
  url?: string;
}>;

export const Description: React.FC<DescriptionProps> = ({ url, children }) => {
  return (
    <p className={cx(styles.description)}>
      {children}
      {url && (
        <HtLink href={normalizeUrl({ url, origin: 'home/product-card#description' })} className={styles['see-more']}>
          Read more
        </HtLink>
      )}
    </p>
  );
};

export type ProductCardProps = React.PropsWithChildren<{
  release: Release;
  sceneId: string;
  className?: string;
  tag?: string;
}>;

export const ProductCard: React.FC<ProductCardProps> = ({ release, sceneId, className, tag }) => {
  const { width } = useWindowDimensions(true);

  let model;
  if (tag) {
    const models = release.models.filter((m) => m.tags.some((t) => t === tag));
    if (models.length === 1) {
      model = models[0];
    }
  }

  const score = model && model.score ? model.score : release.score;

  return (
    <div
      style={{ width: width! < PRODUCT_CARD_MOBILE_MAX_WIDTH ? width! - PRODUCT_CARD_MOBILE_PADDING * 2 : '100%' }}
      className={cx(fonts.hankenGrotesk.className, styles['ht-product-card'], className)}
    >
      <ProductImage
        image={model && model.image ? model.image : release.image}
        url={release.path}
        releaseId={release.id}
        modelId={model?.id}
        slug={release.slug}
        tag={tag}
      />

      {score && score > 0 && (
        <CircleScoreFluid
          size="md"
          amount={score || 0}
          progress={(score / 5) * 100}
          className="home-product-scorecard"
          isStartFromZero
          origin="home/product-card"
        />
      )}

      <div className={styles.content}>
        <div className={styles.row}>
          <div className={cx(styles.col)}>
            <Title tag={tag} slug={release.slug} url={release.path}>
              {model ? model.full_name : release.full_name}
            </Title>
            <span className={styles.price}>
              <Price
                prefix="From "
                suffix=" a pair"
                loading="Loading price..."
                releaseSlug={release.slug}
                modelId={model?.id}
                price={model?.price || release.price}
                noGeo
                origin="home/product-card"
              />
            </span>
          </div>
          <AudioButton sceneId={sceneId} url={release.audio?.url} />
        </div>
        <Description url={release.path}>{release.description}</Description>
      </div>
    </div>
  );
};
