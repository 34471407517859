import * as React from 'react';

import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { motion } from 'framer-motion';

import { Loader } from 'components/common-n4/loader';
import SvgIcon from 'components/ui/svg_icon';
import { useIsMediumOrMobileView } from 'hooks/use_is_mobile_view';
import logger from 'lib/logger';
import { cx } from 'lib/utils';

/* eslint-disable-next-line no-unused-vars */
import styles from './panel.module.scss';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'nav/Panel' });

const TYPE = {
  product: 'nav-product',
  article: 'nav-article',
  customLink: 'nav-custom-link',
};

const LIMIT = 6;
const LIMIT_WITH_CTA = 4;
const LIMIT_LARGE = 3;
const dataHasSmallCTA = (data) => data.find((item) => item.component === TYPE.customLink && item.call_to_action && !item.large);
const dataWithSmallCTA = (data, cta) => {
  const dataSliced = data.slice(0, LIMIT_WITH_CTA);

  const doesCurrentDataIncludeSmallCTA = dataSliced.find((item) => item._uid === cta._uid);

  return doesCurrentDataIncludeSmallCTA ? dataSliced : [...dataSliced, cta];
};
const dataWithLimit = (data, isRow = false) => {
  switch (data?.[0]?.component) {
    case TYPE.product:
      return data.slice(0, LIMIT);
    case TYPE.customLink:
    case TYPE.article: {
      if (isRow) {
        return data.slice(0, LIMIT_LARGE);
      }

      const smallCTA = dataHasSmallCTA(data);

      return smallCTA ? dataWithSmallCTA(data, smallCTA) : data.slice(0, LIMIT);
    }
    default:
      return [];
  }
};

/* eslint-disable-next-line no-unused-vars */
const NavPanel = ({ blok, story, handleResetPanel, ...props }) => {
  log.debug('blok: %o', blok);
  const { panel_items: items, panel_tabs: tabs } = blok;
  const [currentTab, setCurrentTab] = React.useState();
  const hasItems = Boolean(items?.length);
  const isItemsRow = hasItems && items[0].large;
  const itemsWithLimit = dataWithLimit(items, isItemsRow);
  const isMediumOrLessViewport = useIsMediumOrMobileView();
  const hasTabs = Boolean(tabs?.length);

  const handleSetTab = (tab) => {
    log.debug('opening tab: %o', tab);

    if (!tab) {
      return;
    }

    const tabData = {
      ...tab,
      items: dataWithLimit(tab.items),
    };

    setCurrentTab(tabData);
  };

  React.useEffect(() => {
    handleSetTab(tabs?.[0]);
  }, [tabs]);

  const expand =
    story?.full_slug?.endsWith('components/nav') &&
    !/no-expand/.test(story.content.debug) &&
    (typeof window === 'undefined' || !/no-expand/.test(window.location.search));

  return (
    <section className={cx(styles.panel, !hasTabs && styles['panel--mobile'])} {...storyblokEditable(blok)}>
      {hasItems || hasTabs ? (
        <button
          type="button"
          className={styles.back}
          onClick={() => {
            handleResetPanel();
          }}
        >
          <SvgIcon icon="chevron-left-solid" alt="back to initial menu panel" />
          Back
        </button>
      ) : null}
      {/* list of items */}
      {hasItems ? (
        <motion.section
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          exit={{ opacity: 0 }}
          transition={{ opacity: { duration: 0.25 } }}
          className={cx(
            isMediumOrLessViewport || !isItemsRow ? styles['items--column'] : styles['items--row'],
            isMediumOrLessViewport && styles['items--column--article-and-custom-link']
          )}
          style={expand ? { maxHeight: 10000 } : {}}
        >
          {!itemsWithLimit ? (
            <div className={styles.loader}>
              <Loader />
            </div>
          ) : (
            itemsWithLimit.map((item) => <StoryblokComponent key={item._uid} blok={item} {...props} story={story} {...storyblokEditable(item)} />)
          )}
        </motion.section>
      ) : null}
      {/* panel with tabs */}
      {hasTabs && !expand && (
        <section className={styles.tabs}>
          <ul>
            {tabs.map((tab) => (
              <li key={tab._uid}>
                <button type="button" onClick={() => handleSetTab(tab)} className={cx(styles.tab, tab._uid === currentTab?._uid && styles['tab--active'])}>
                  {tab.title}
                </button>
              </li>
            ))}
          </ul>
          {currentTab && <StoryblokComponent id={currentTab?._uid} blok={currentTab} {...props} story={story} {...storyblokEditable(currentTab)} />}
        </section>
      )}
      {hasTabs && expand && (
        <section className={styles.tabs}>
          {tabs.map((tab) => (
            <div key={tab._uid} style={{ marginBottom: 20 }}>
              <button type="button" onClick={() => handleSetTab(tab)} className={cx(styles.tab, styles['tab--active'])}>
                {tab.title}
              </button>
              <StoryblokComponent id={tab?._uid} blok={tab} {...props} story={story} {...storyblokEditable(tab)} />
            </div>
          ))}
        </section>
      )}
    </section>
  );
};

export default NavPanel;
