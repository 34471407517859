import React, { useCallback, useEffect, useState } from 'react';

import { type EmblaOptionsType } from 'embla-carousel';
import ClassNames from 'embla-carousel-class-names';
import useEmblaCarousel from 'embla-carousel-react';

import ImageWrapper from 'components/common-n4/image';
import type { ImageSource } from 'components/common-n4/image/types';
import { Modal } from 'components/common-n4/modal';
import fonts from 'components/layout/fonts';
import IconCross from 'images/icon-cross.svg';
import logger from 'lib/logger';
import { cx, tw } from 'lib/utils';

import styles from './gallery.module.scss';

import { PrevButton, NextButton, usePrevNextButtons } from './gallery-nav-buttons';
import { GalleryVimeo } from './gallery-vimeo';
import { GalleryYoutube } from './gallery-youtube';

export interface GalleryProps {
  media: Medium[];
  isOpen: boolean;
  onOpen?: () => void;
  onClose: () => void;
  classes?: GalleryClasses;
  options?: EmblaOptionsType;
}

interface Medium {
  type: 'ht-image' | 'image' | 'video' | 'vimeo' | 'youtube';
  src?: string;
  image?: ImageSource;
  caption?: string;
  videoId?: string;
}

interface GalleryClasses {
  panel?: string;
}

const log = logger({ category: 'gallery' });

const MediaCount: React.FC<{ className: string; total: number; activeIndex: number }> = (props) => (
  <div className={tw('inline-flex rounded-3xl bg-white/5 px-[10px] py-1 ', props.className)}>
    <span className="text-base font-medium text-white md:text-sm">
      {props.activeIndex + 1}/{props.total}
    </span>
  </div>
);

const CloseButton: React.FC<{ className: string; onClose: () => void }> = (props) => (
  <button className={tw('flex items-center gap-3 text-white', props.className)} onClick={props.onClose}>
    <span className="text-base">Close</span>
    <IconCross />
  </button>
);

const loadScript = () => {
  const src = 'https://www.youtube.com/iframe_api';
  if (document.querySelector(`[src="${src}"]`)) {
    log.debug('script already loaded');
    return;
  }
  log.debug('loading script');
  const tag = document.createElement('script');
  tag.setAttribute('id', 'youtube-iframe-api');
  tag.src = src;
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);
};

export const Gallery: React.FC<GalleryProps> = ({ media, isOpen, onOpen, onClose, options, classes }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, ...options }, [ClassNames()]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelect = useCallback(() => {
    setSelectedIndex(emblaApi?.selectedScrollSnap() || 0);
  }, [emblaApi]);

  const hasOneItemOnly = useCallback(() => {
    return media.length === 1;
  }, [media.length]);

  // Need this check since embla carousel will disable loop if only 2 slides
  const hasTwoItemsOnly = useCallback(() => {
    return media.length === 2;
  }, [media.length]);

  const renderMediumByType = useCallback(
    (medium: Medium, index: number) => {
      switch (medium.type) {
        case 'ht-image': {
          const { image } = medium;
          return (
            <div className={styles.gallery__slide__img}>
              <ImageWrapper image={image!} origin="gallery-ht-image" />
            </div>
          );
          break;
        }
        case 'image':
          return (
            <div className={styles.gallery__slide__img}>
              <ImageWrapper image={medium.src!} origin="gallery-image" />
            </div>
          );
          break;
        case 'video':
          return (
            <div className={styles.gallery__slide__video}>
              <video src={medium.src} controls></video>;
            </div>
          );
          break;
        case 'vimeo':
          return <GalleryVimeo videoId={medium.videoId!} />;
          break;
        case 'youtube':
          return <GalleryYoutube videoId={medium.videoId} emblaApi={emblaApi} index={index} />;
          break;
        default:
          break;
      }
    },
    [emblaApi]
  );

  useEffect(() => {
    const hasYoutube = media.some((medium) => medium.type === 'youtube');
    if (hasYoutube) {
      loadScript();
    }
  }, [media]);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect();
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  return (
    <Modal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      contentBackgroundColor="transparent"
      classes={{
        panelContainer: 'pointer-events-auto',
        panel: cx('max-w-full shadow-none rounded-none transform-none', fonts.hankenGrotesk.className, classes?.panel),
      }}
    >
      <section className={cx(styles.gallery, hasOneItemOnly() && styles['gallery--single-item'])}>
        <div className={styles.gallery__viewport} ref={emblaRef}>
          <div className={cx(styles.gallery__container, !hasOneItemOnly() && 'mr-6 md:mr-10')}>
            {media.map((medium, index) => (
              <div
                className={tw(
                  styles.gallery__slide,
                  'w-full pl-6 md:pl-10',
                  hasTwoItemsOnly() ? '' : !hasOneItemOnly() ? 'pl-6 md:w-3/4 md:pl-10' : 'px-6 md:px-0'
                )}
                key={index}
              >
                <figure className="flex h-full items-center justify-center">
                  <div className={cx(hasOneItemOnly() && 'flex h-full flex-1 flex-col items-center justify-center')}>
                    {renderMediumByType(medium, index)}
                    {medium.caption && <figcaption className="mt-4 border-l-2 border-lapis px-2 text-base text-white">{medium.caption}</figcaption>}
                  </div>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </section>
      <>
        {!hasOneItemOnly() && (
          <>
            <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} className="fixed left-8 top-1/2 hidden -translate-y-1/2 md:flex" />
            <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} className="fixed right-8 top-1/2 hidden -translate-y-1/2 md:flex" />
            <MediaCount className="fixed left-1/2 top-8 -translate-x-1/2 md:top-7" total={media.length} activeIndex={selectedIndex} />
          </>
        )}
        <CloseButton className="fixed right-8 top-9 md:top-8" onClose={onClose} />
      </>
    </Modal>
  );
};
