import { ClassNameValue } from 'tailwind-merge';

import IconArrowRight from 'images/icon-arrow-right.svg';
import { tw } from 'lib/utils';

interface ViewMoreButtonProps {
  isViewMore: boolean;
  onViewMoreButtonClick: () => void;
  className?: ClassNameValue;
  positiveStateText?: string;
  negativeStateText?: string;
  hidden?: boolean;
}

export const ViewMoreButton: React.FC<ViewMoreButtonProps> = ({
  isViewMore,
  onViewMoreButtonClick,
  className,
  positiveStateText,
  negativeStateText,
  hidden = false,
}) =>
  !hidden && (
    <button className={tw('flex w-full items-center justify-center gap-[16px]', className)} onClick={onViewMoreButtonClick}>
      <span className="text-xl font-light text-navy">{isViewMore ? positiveStateText || 'View less' : negativeStateText || 'View more'}</span>
      <div className="rounded-full bg-navy p-[15px]">
        <IconArrowRight className={isViewMore ? '-rotate-90' : 'rotate-90'} />
      </div>
    </button>
  );
