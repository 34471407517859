import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

const DidYouKnow = ({ blok, story, ...props }) => (
  <div>
    Title: {blok.title}
    {blok.markdown?.length > 0 && <StoryblokComponent blok={blok.markdown[0]} story={story} {...props} {...storyblokEditable(blok.markdown)} />}
  </div>
);

export default DidYouKnow;
