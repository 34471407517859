import NextLink from 'next/link';

import { normalizeUrl } from 'lib/utils';

import styles from './buttons.module.scss';

export const Buttons = ({ blok }) => (
  <div className={styles.buttons}>
    <NextLink prefetch={false} className={styles.compare} href={normalizeUrl({ url: blok.primary_button_link.cached_url, origin: 'home/hero#buttons' })}>
      <span>{blok.primary_button_text ?? 'Compare Hearing Aids'}</span>
    </NextLink>
    <NextLink prefetch={false} className={styles.new} href={normalizeUrl({ url: blok.secondary_button_link.cached_url, origin: 'home/hero#buttons' })}>
      <span>{blok.secondary_button_text ?? 'Need new Hearing Aids?'}</span>
    </NextLink>
  </div>
);
