import logger from './logger';

const defaultLog = logger({ category: 'ht_api' });

const baseUrl = process.env.API_URL;
const FRIENDLY_ERROR = 'An unexpected error occurred. Please contact support.';

// TODO: security if needed

const mapToParams = (map, first = true) => {
  if (Object.keys(map).length) {
    const params = Object.entries(map)
      .filter(([, v]) => !!v)
      .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
      .join('&');
    if (first) {
      return `?${params}`;
    }
    return `&${params}`;
  }
  return '';
};

/*
 * api should return either:
 * { <successful result> } -> worked
 * or
 * { error } -> handled error, display to user
 * or
 * { exception, error } -> unhandled exception, display generic message to user
 *
 * fallback says what to return if there is an exception
 * handled errors will always be returned
 * if fallback is 'friendly', return a generic friendly error
 */
const fetchApi = async ({ path, version = 'v1', method = 'GET', variables = {}, fallback, origin, log = defaultLog }) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };
  if (/POST|PUT|PATCH/i.test(method)) {
    options.body = JSON.stringify(variables);
  }
  const first = !/\?/.test(path);
  const params = method.toLowerCase() === 'get' ? mapToParams({ ...variables, 'x-requestId': log.requestId, origin }, first) : '';
  const url = `${baseUrl}${version}${path[0] === '/' ? '' : '/'}${path}${params}`;
  // Note to self: url is long to log, but it's handy to grab and test in the browser
  log.info('fetch: %o', { url, variables, requestId: log.requestId, origin });
  try {
    const result = await fetch(url, options);
    const json = await result.json();
    if (json.exception) {
      log.error('handled exception: url: %s, params: %o, ex: %o, origin: %s', url, params, json, origin);
      if (fallback === 'friendly') {
        return {
          error: FRIENDLY_ERROR,
          ...json,
        };
      }
      if (fallback) {
        return fallback;
      }
      throw new Error('Unhandled exception in fetch api');
    }
    return json;
  } catch (ex) {
    log.error('unhandled exception: url: %s, params: %o, ex: %o, origin: %s', url, params, ex, origin);
    if (fallback === 'friendly') {
      return {
        exception: ex.message,
        error: FRIENDLY_ERROR,
      };
    }
    if (fallback) {
      return fallback;
    }
    throw ex;
  }
};

/* eslint-disable import/prefer-default-export -- because I think we'll be adding more */
export { fetchApi };
