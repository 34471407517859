import type { ModelFeatureValue, ModelFeatureDetail, LevelFeatureValue, LevelFeatureDetail } from 'types/release';

export const getFeatureNameForFeature = (featureDetail: ModelFeatureDetail | LevelFeatureDetail) => {
  return featureDetail.name || ('proprietary_name' in featureDetail && featureDetail.proprietary_name) || featureDetail.generic_name;
};

export const getValueForFeature = (
  featureValues: ModelFeatureValue[] | LevelFeatureValue[],
  featureDetail: ModelFeatureDetail | LevelFeatureDetail | undefined
) => {
  const match = featureValues.find((f) => f.id === featureDetail?.id);
  if (!match) return 'N/A';
  return match.value || '✓';
};
