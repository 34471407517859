import * as React from 'react';

import { motion } from 'framer-motion';

import { Modal, type ModalProps } from 'components/common-n4/modal';
import IconArrowLeftNavy from 'images/icon-arrow-left-navy.svg';
import IconCross from 'images/icon-cross.svg';
import { tw } from 'lib/utils';
import { Level, Model } from 'types/release';

// import { PleaseSignIn } from './please-sign-in';
import { ReviewMessage } from './review-message';
import { SelectLevel } from './select-level';
import { SelectModel } from './select-model';

import { CustomerReviewsContext } from '../customer-reviews';

const steps = ['SELECT_MODEL', 'SELECT_LEVEL', 'REVIEW_MESSAGE', 'PLEASE_SIGN_IN', 'LOG_IN'];

interface RatingPopUpProps extends ModalProps {
  onSubmit: (result: { selectedModel: Model | null; selectedLevel: Level | null; reviewMessage: string; userScore?: number }) => Promise<boolean>;
}

export const RatingPopUp: React.FC<RatingPopUpProps> = ({ isOpen, onClose: onCloseProp, onSubmit }) => {
  const { models, levels, image, releaseName } = React.useContext(CustomerReviewsContext);

  const resetCurrentStep = React.useCallback(() => {
    if (models.length === 1 && levels.length === 1) {
      return 2;
    } else if (models.length === 1) {
      return 1;
    } else {
      return 0;
    }
  }, [models, levels]);

  const resetSelectedModel = React.useCallback(() => {
    return models.length === 1 ? models[0] : null;
  }, [models]);

  const resetSelectedLevel = React.useCallback(() => {
    return levels.length === 1 ? levels[0] : null;
  }, [levels]);

  const [currentStep, setCurrentStep] = React.useState(() => {
    return resetCurrentStep();
  });
  const [selectedModel, setSelectedModel] = React.useState<Model | null>(() => {
    return resetSelectedModel();
  });
  const [selectedLevel, setSelectedLevel] = React.useState<Level | null>(() => {
    return resetSelectedLevel();
  });
  const [reviewMessage, setReviewMessage] = React.useState<string>('');

  const resetRatingInputs = React.useCallback(() => {
    resetSelectedModel();
    resetSelectedLevel();
    setReviewMessage('');
  }, [resetSelectedLevel, resetSelectedModel]);

  const title = React.useMemo(() => {
    switch (currentStep) {
      case 0:
        return 'Which model did you purchase?';
        break;
      case 1:
        return 'Which technology level did you purchase?';
        break;
      case 2:
        return `Please tell us what did you like or dislike about the ${releaseName}?`;
        break;
      default:
        break;
    }
  }, [currentStep, releaseName]);

  const onBackButtonClick = React.useCallback(() => {
    if (currentStep === 0) {
      onCloseProp();
      resetRatingInputs();
    } else {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  }, [currentStep, onCloseProp, resetRatingInputs]);

  const onClose = React.useCallback(() => {
    onCloseProp();
    setTimeout(() => {
      resetRatingInputs();
      resetCurrentStep();
    }, 300);
  }, [onCloseProp, resetCurrentStep, resetRatingInputs]);

  const onModelSelected = React.useCallback((model: Model) => {
    setSelectedModel(model);
  }, []);

  const onLevelSelected = React.useCallback((level: Level) => {
    setSelectedLevel(level);
  }, []);

  const onReviewMessageChange = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReviewMessage(e.target.value);
  }, []);

  const onNextButtonClick = React.useCallback(() => {
    setCurrentStep((prevStep) => (prevStep === steps.length - 1 ? prevStep : prevStep + 1));
  }, []);

  const onSubmitButtonClick = React.useCallback(async () => {
    const success = await onSubmit({ selectedModel, selectedLevel, reviewMessage });
    // TODO: make success a toast; failure an inline message so they can try again.
    if (success) {
      alert(`We greatly appreciate you taking the time to provide a product review for your ${releaseName}!
        Your feedback will help our community make better informed decisions when selecting their next set of hearing aids.`);
      onClose();
    } else {
      alert('Something went wrong. Please try again and if the problem persists, contact support@hearingtracker.com');
    }
  }, [onClose, onSubmit, reviewMessage, selectedLevel, selectedModel, releaseName]);

  const shouldDisableNextButton = React.useCallback(() => {
    return (currentStep === 0 && !selectedModel) || (currentStep === 1 && !selectedLevel) || (currentStep === 2 && !reviewMessage);
  }, [currentStep, selectedLevel, selectedModel, reviewMessage]);

  let content;
  switch (steps[currentStep]) {
    case 'SELECT_MODEL':
      content = <SelectModel models={models} selectedModel={selectedModel} onModelSelected={onModelSelected} />;
      break;
    case 'SELECT_LEVEL':
      content = <SelectLevel levels={levels} selectedLevel={selectedLevel} onLevelSelected={onLevelSelected} image={image} />;
      break;
    case 'REVIEW_MESSAGE':
      content = <ReviewMessage reviewMessage={reviewMessage} onReviewMessageChange={onReviewMessageChange} />;
      break;
    default:
      content = null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      classes={{
        panelContainer: 'items-end laptop:items-center',
        panel: 'max-w-[880px] laptop:h-auto rounded-t-[30px] rounded-b-none laptop:rounded-[40px] transform-none h-[88%] flex laptop:block',
        overlay: 'laptop:bg-navy-80',
      }}
    >
      {currentStep <= 2 && (
        <section className="flex flex-col">
          <div className="relative px-[26px] pt-[33px] laptop:px-[70px] laptop:pt-[57px]">
            <div className="flex flex-col items-center px-6 laptop:px-0">
              <div className="text-[14px] leading-snug tracking-tight text-lapis laptop:text-sm">Step {currentStep + 1}</div>
              <h4 className="mt-3 text-center text-[32px] font-medium leading-tight tracking-tight text-navy">{title}</h4>
            </div>
          </div>
          {content}
          <div>
            <motion.div
              className={tw('relative top-[3px] h-[6px] w-0 rounded-e-full bg-lapis')}
              animate={{ width: `${((currentStep + 1) / 3) * 100 * 0.9}%` }}
              transition={{ ease: 'easeOut' }}
            ></motion.div>
            <div className="flex justify-between px-[26px] py-[16px] shadow-lg laptop:px-12 laptop:pb-6 laptop:pt-4">
              <button className={tw('flex items-center justify-center gap-3 text-[16px] laptop:text-base')} onClick={onBackButtonClick}>
                <IconArrowLeftNavy />
                Back
              </button>
              <button
                className={tw(
                  'rounded-full bg-lapis px-[24px] py-[10px] text-[16px] text-white laptop:px-6 laptop:text-base',
                  shouldDisableNextButton() && 'opacity-50'
                )}
                onClick={currentStep === 2 ? onSubmitButtonClick : onNextButtonClick}
                disabled={shouldDisableNextButton()}
              >
                {currentStep === 2 ? 'Submit' : 'Next'}
              </button>
            </div>
          </div>
        </section>
      )}
      {/* {currentStep === 3 && <PleaseSignIn onBackButtonClick={onBackButtonClick} onNextButtonClick={onNextButtonClick} />} */}

      <button className="fixed right-8 top-9 flex items-center gap-3 text-white md:top-8" onClick={onClose}>
        <span className="text-[14px] laptop:text-base">Close</span>
        <IconCross />
      </button>
    </Modal>
  );
};
