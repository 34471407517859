// top-level container for N4 pages
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import logger from 'lib/logger';
import { cx } from 'lib/utils';

import styles from './home-container.module.scss';

const log = logger({ category: 'n4/home/container' });

/*
 * blok has the current component and its children
 * story has the entire "page"
 * props has anything we load from our API (or anywhere else)
 *   usually these are build-time, so fairly static data
 *   for dynamic data, fetch it from the api in a useEffect
 *   props has a variant variable that is 'A' or 'B' if there are any A/B elements on the page
 *
 * StoryblokComponent will render the children bloks of this blok. Those are all mapped to React components in
 * the project.
 */
const HomeContainer = (p) => {
  const { blok, story, ...props } = p;
  const bloks = blok.blocks.filter((b) => !b.hidden);

  log.debug('blok: %o', blok);
  return (
    <section className={cx(styles['n4-container'], blok.className)} {...storyblokEditable(blok)}>
      <section>{bloks ? bloks.map((b) => <StoryblokComponent key={b._uid} blok={b} story={story} {...props} {...storyblokEditable(b)} />) : null}</section>
    </section>
  );
};

export default HomeContainer;
