import React, { PropsWithChildren } from 'react';

import { ClassNameValue } from 'tailwind-merge';

import fonts from 'components/layout/fonts';
import { tw } from 'lib/utils';

interface PanelProps extends PropsWithChildren {
  className?: ClassNameValue;
}

export const Panel: React.FC<PanelProps> = ({ children, className }) => {
  return <section className={tw(fonts.hankenGrotesk.className, 'rounded-2xl border border-neutral-300 bg-white px-6 py-7', className)}>{children}</section>;
};
