import { currencyFormatter } from 'lib/utils';

const Currency = ({ price, fallback, prefix = '', suffix = '' }) => {
  if (!price) {
    return fallback;
  }
  const parts = currencyFormatter.formatToParts(price);
  const currency = parts.find((p) => p.type === 'currency');
  const rest = parts.filter((p) => !/currency|decimal|fraction/.test(p.type)).map((p) => p.value);
  return (
    <span>
      {prefix}
      {currency && <span className="symbol">{currency.value}</span>}
      <span>{rest.join('')}</span>
      {suffix}
    </span>
  );
};

export default Currency;
