const getCookie = (name) => {
  const cookie = document.cookie
    .split(';')
    .map((c) => c.trim())
    .find((c) => c.split('=')[0].trim() === name);
  if (cookie) {
    return cookie.split('=')[1].trim();
  }
  return null;
};

const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; Expires=${date.toUTCString()}`;
  }
  if (window.location.hostname !== 'localhost') {
    document.cookie = `${name}=${value || ''}${expires}; Path=/; Domain=.hearingtracker.com;`;
  } else {
    document.cookie = `${name}=${value || ''}${expires}; Path=/; `;
  }
};

const eraseCookie = (name) => {
  if (window.location.hostname !== 'localhost') {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=.hearingtracker.com`;
  } else {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
};

export { getCookie, setCookie, eraseCookie };
