import * as React from 'react';

import { Dialog, Transition } from '@headlessui/react';

import fonts from 'components/layout/fonts';
import SvgIcon from 'components/ui/svg_icon';
import { tw } from 'lib/utils';

export interface ModalProps extends React.PropsWithChildren {
  isOpen: boolean;
  onOpen?: () => void;
  onClose: () => void;
  overlayBackgroundColor?: string;
  contentBackgroundColor?: string;
  classes?: ModalClasses;
}

interface ModalClasses {
  overlay?: string;
  panelContainer?: string;
  panel?: string;
}

const Root = ({ isOpen, onOpen, onClose, children, classes, overlayBackgroundColor, contentBackgroundColor }: ModalProps) => {
  return (
    <Transition appear show={isOpen} as={React.Fragment} afterEnter={onOpen}>
      <Dialog as="div" className="relative z-[1000]" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={tw('fixed inset-0 bg-navy', classes?.overlay)} style={overlayBackgroundColor ? { backgroundColor: overlayBackgroundColor } : {}} />
        </Transition.Child>
        {/* do not add overflow-y here to make modal scrollable instead pass classes to classes.panel 
        ex. overflow-y-auto max-h-[calc(100vh-9rem)] or example in <FiltersModal /> */}
        <div className="fixed inset-0">
          <div className={tw('flex h-[100svh] min-h-full items-center justify-center text-center', classes?.panelContainer)}>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={tw(
                  'w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all',
                  classes?.panel,
                  fonts.hankenGrotesk.className
                )}
                style={contentBackgroundColor ? { backgroundColor: contentBackgroundColor } : {}}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

interface CloseButtonProps {
  onClose: () => void;
}
const CloseButton = ({ onClose, className }: React.ComponentProps<'i'> & CloseButtonProps) => {
  return (
    <button onClick={onClose} className="flex items-center justify-center pl-[6px]">
      <SvgIcon icon="xmark-thin" className={className} />
    </button>
  );
};

export const Modal = Object.assign(Root, {
  CloseButton,
});
