/* eslint-disable @next/next/no-img-element */
/*
 * Do not use directly. This component is used by the Image component to build the actual
 * img tag and its attributes. It can handle fixed or responsive images.
 */
import React, { ReactElement } from 'react';

import startCase from 'lodash/startCase';

import logger from 'lib/logger';
import { default as initTracer } from 'lib/tracer';

import { ImageProps, ImgProps } from './types';
import useImageLoader from './use-image-loader';

const log = logger({ category: 'OptimizedImage' });

const buildSrc = (props: ImageProps): string => {
  const { image, sizingProps } = props;
  const { toUrl, nativeWidth = 1366, nativeHeight = 768, quality, trim } = sizingProps || {};
  const src = image as string;
  if (toUrl) {
    return toUrl({ baseUrl: src, width: nativeWidth, height: nativeHeight, quality, trim });
  }
  return src;
};

const buildSrcSets = (props: ImageProps): string[] => {
  const { image, sizingProps: sz, tracer: t } = props;
  const src = image as string;
  const sizingProps = sz!;
  const tracer = t!;
  const { resolutionCount = 5, toUrl, quality, trim } = sizingProps;
  tracer!.trace('buildSrcSets: %o', { src, sizingProps });

  const maxWidth = sizingProps.maxWidth || Math.min(sizingProps.nativeWidth || 1366, 1366);
  if (sizingProps.minWidth && maxWidth && sizingProps.minWidth > maxWidth) {
    tracer.trace('Image has invalid dimensions');
  }
  const minWidth = (maxWidth && sizingProps.minWidth && sizingProps.minWidth > maxWidth ? maxWidth : sizingProps.minWidth) || 300;

  const srcSets = [];

  if (!toUrl) {
    tracer.trace('No toUrl so just returning one src set');
    srcSets.push(`${src} 100vw`);
    return srcSets;
  }

  // Always include minWidth
  srcSets.push(`${toUrl({ baseUrl: src, width: minWidth, quality, trim })} ${minWidth}w`);
  if (resolutionCount < 2) {
    tracer.trace('%o: rc < 2', src);
    // Only one was requested
    return srcSets;
  }
  if (/svg|data/.test(src)) {
    tracer.trace('%o: svg/data', src.slice(0, 20));
    // only one res for svg/base64
    return srcSets;
  }
  const diff = maxWidth - minWidth;
  if (diff === 0) {
    tracer.trace('%o: diff === 0', src);
    // just one needed
    return srcSets;
  }
  if (resolutionCount === 2) {
    tracer.trace('rc === 2 || diff < 2: %o', { src, resolutionCount, diff });
    // just min and max needed
    srcSets.push(`${toUrl({ baseUrl: src, width: maxWidth, quality, trim })} ${maxWidth}w`);
    return srcSets;
  }
  // 200px is about what we're shooting for so default of 300 - 1366 with 5 resolutions
  // gets us that, if the difference is lower than 200, just return the min and max.
  const pixelWidth = Math.max(200, Math.floor((maxWidth - minWidth) / (resolutionCount - 1)));
  let currentWidth = minWidth + pixelWidth;
  tracer.trace('width: %o', { src, resolutionCount, diff, pixelWidth, currentWidth });
  while (currentWidth < maxWidth) {
    srcSets.push(`${toUrl({ baseUrl: src, width: currentWidth, quality, trim })} ${currentWidth}w`);
    currentWidth += pixelWidth;
  }
  // Final one for maxWidth
  srcSets.push(`${toUrl({ baseUrl: src, width: maxWidth, quality, trim })} ${maxWidth}w`);
  return srcSets;
};

// Fixed-size images use pixel density srcset, e.g. url 1x, url 2x
const fixed = (props: ImageProps): ImgProps => {
  const { image, sizingProps, imgProps } = props;
  const src = image as string;
  const { fixedWidth, fixedHeight, nativeWidth, nativeHeight, resolutionCount = 2, aspectRatio, toUrl, quality, trim } = sizingProps!;

  if (!fixedWidth) {
    log.warn('Fixed image specified but no fixedWidth supplied: %s', image);
  }

  const width = fixedWidth || nativeWidth || 300;
  let height = fixedHeight || nativeHeight;
  if ((!height || height === 'auto') && aspectRatio) {
    height = Math.floor(width / aspectRatio);
  }
  if (!height || (height !== 'auto' && isNaN(height))) {
    height = 'auto';
  }

  const srcs = [];
  if (toUrl) {
    srcs.push(toUrl({ baseUrl: src!, width, height, quality, trim }));
    for (let i = 1; i < resolutionCount; ++i) {
      srcs.push(toUrl({ baseUrl: src!, width: width * i * 2, height: height === 'auto' ? height : height * i * 2, quality, trim }));
    }
  } else {
    srcs.push(src);
  }

  const srcSet = srcs.length === 1 ? undefined : srcs.map((s, i) => `${s} ${i + 1}x`).join(',');
  return {
    width: nativeWidth || width, // This needs to be native width ideally so the browser doesn't have to scale the image initially; I think
    height: nativeHeight || height,
    src: buildSrc(props),
    srcSet,
    style: { width: `${width}px`, height: `${height}px`, maxWidth: '100%', aspectRatio: aspectRatio || undefined, ...imgProps?.style },
  };
};

// Responsive images use media query srcset, e.g. url 100w, url 200w,...
const responsive = (props: ImageProps): ImgProps => {
  const { image, tracer, imgProps, sizingProps } = props;
  if (typeof image === 'string' && /data.*base64/.test(image as string)) {
    return {
      src: image as string,
    };
  }
  const srcSets = buildSrcSets(props);
  const { nativeWidth, nativeHeight, aspectRatio, minWidth, sizes = '100vw' } = sizingProps!;

  tracer!.trace('srcsets for responsive: %o', srcSets);
  const src = buildSrc(props);
  return {
    src,
    width: nativeWidth || minWidth,
    height: nativeHeight || 'auto',
    srcSet: srcSets.join(','),
    sizes,
    style: { maxWidth: '100%', aspectRatio: aspectRatio || undefined, ...imgProps?.style },
  };
};

const sizingAttributes = (props: ImageProps): ImgProps => {
  if (props.sizingProps?.fixed) {
    return fixed(props);
  }
  return responsive(props);
};

export const PreloadLink = (props: ImageProps) => {
  const srcSets = buildSrcSets(props);
  return <link rel="preload" as="image" imageSrcSet={srcSets.join(',')} />;
};

const defaultImgProps: ImgProps = {
  loading: 'lazy',
  sizes: '100vw',
};

const OptimizedImage = (props: ImageProps): ReactElement | null => {
  const { image, sizingProps, imgProps, origin, onLoaded, tracer = initTracer(), blockRobots } = props;
  const ref = React.createRef<HTMLImageElement>();
  const onLoad = useImageLoader({ ref, origin, onLoaded });
  try {
    tracer.trace('Optimizing image: %o', props);
    const src = image as string;

    // Note: supposedly Firefox ignores the loading attribute unless it's before src
    const allProps = {
      ...defaultImgProps,
      ...imgProps,
      onLoad,
      'data-origin': origin,
      src,
    };

    if (sizingProps) {
      Object.assign(allProps, { ...sizingAttributes(props) });
    }

    if (!allProps.alt && allProps.src && !/imgix/.test(allProps.src)) {
      try {
        allProps.alt = startCase(allProps.src.split('/').slice(-1)[0].split('.')[0]);
      } catch (ex) {
        log.debug('could not parse %s for default alt', src);
      }
    }
    if (blockRobots) {
      allProps.src = `${allProps.src}${allProps.src.includes('?') ? '&robots=block' : '?robots=block'}`;
    }
    if (process.env.NODE_ENV !== 'production') {
      // Object.assign(allProps, { 'data-attributes': JSON.stringify({ ...allProps, src: allProps.src.slice(0, 50) }) });
    }
    return <img ref={ref} {...allProps} />;
  } catch (ex: unknown) {
    console.error(ex);
    if ((ex as Error).message !== undefined) {
      tracer.trace('Exception in optimized image: %s', (ex as Error).message);
    }
    return null;
  } finally {
    log.silly(tracer.dump());
  }
};

export default OptimizedImage;
