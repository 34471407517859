import React, { useState } from 'react';

import { motion } from 'framer-motion';

import fonts from 'components/layout/fonts';
import PriceButton from 'components/widgets/price-button';
import PriceLink from 'components/widgets/price-link';
import { cx } from 'lib/utils';
import type { Model, Release } from 'types/release';

interface CallToActionBarProps {
  models: Model[];
  release: Release;
  releaseName: string;
}

export const CallToActionBar: React.FC<CallToActionBarProps> = ({ release }) => {
  const [showBar, setShowBar] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // If user scrolls down and the bar is hidden, show it
      if (currentScrollY > lastScrollY && !showBar) {
        setShowBar(true);
      }
      // If user scrolls up and the bar is visible, hide it
      else if (currentScrollY < lastScrollY && showBar) {
        setShowBar(false);
      }

      // Update the last scroll position
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY, showBar]);

  React.useEffect(() => {
    // Add padding to body so CTA bar will not overlap with footer
    document.body.style.paddingBottom = '70px';

    return () => {
      document.body.style.paddingBottom = '';
    };
  }, []);

  return (
    <motion.div
      initial={{ y: '0%' }}
      animate={{ y: showBar ? '0%' : '100%' }}
      transition={{
        transform: showBar ? { duration: 0.8, type: 'tween', ease: [0.22, 1, 0.36, 1] } : { duration: 0.3, type: 'tween', ease: 'easeIn' },
      }}
      className={cx(fonts.hankenGrotesk.className, 'fixed bottom-0 left-0 right-0 z-1000 bg-cream px-5 py-2')}
    >
      <div className="flex items-center justify-between">
        <div className="py-2">
          <h2 className="text-xl tracking-tight text-navy">{release.full_name}</h2>
          <div className="text-base font-light tracking-tight text-lapis">
            <PriceLink
              linkPrefix="Starting at "
              release={release}
              price={release.price}
              origin="call-to-action-bar"
              position="product-page-mobile-sticky-footer-link"
            />
          </div>
        </div>
        <PriceButton
          release={release}
          price={release.price}
          noGeo
          origin="product/mobile-footer"
          position="product-page-mobile-sticky-footer"
          className="w-auto"
        />
      </div>
    </motion.div>
  );
};
