import { useState, useEffect, useCallback, useRef } from 'react';

import { CircleProgress } from 'components/bloks/n4/images/circle_progress';
import SvgIcon from 'components/ui/svg_icon';
import { useAudio } from 'hooks';
import logger from 'lib/logger';
import { cx } from 'lib/utils';

import styles from './audio_button.module.scss';

import { AUDIO_SCENES } from '../constants';

const log = logger({ category: 'compare-audio' });

export const AudioButton = ({ sceneId, url }) => {
  const audioRef = useRef(null);
  const animationFrameId = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  const scene = AUDIO_SCENES.find((s) => s.id === sceneId);
  const { currentlyPlaying, setCurrentlyPlaying } = useAudio({ sceneId });

  const handlePlayButton = (customTime = null) => {
    if (!audioRef.current) return;
    currentlyPlaying?.audio?.pause();
    if (typeof customTime === 'number' && customTime !== null) {
      audioRef.current.currentTime = customTime;
    } else if (currentlyPlaying?.hasSceneChanged) {
      audioRef.current.currentTime = 0;
    } else if (currentlyPlaying?.audio) {
      audioRef.current.currentTime = currentlyPlaying.audio.currentTime;
    }
    log.debug('media: %o', audioRef.current);
    log.debug('sceneId: %s', sceneId);
    log.debug('scene: %o', scene);
    log.debug('currentTime: %o', audioRef.current.currentTime);
    audioRef.current.play();
    setIsPlaying(true);
    setCurrentlyPlaying({
      audio: audioRef.current,
    });
  };

  const handlePauseButton = useCallback(() => {
    if (!audioRef.current) return;
    if (isPlaying) audioRef.current.pause();
    cancelAnimationFrame(animationFrameId.current);
    setIsPlaying(false);
  }, [isPlaying]);

  /*
  useEffect(() => {
    if (previousScene !== sceneId && audioRef.current) {
      setCurrentlyPlaying({
        ...(currentlyPlaying || {}),
        hasSceneChanged: true,
      });
    }
  }, [sceneId, previousScene, currentlyPlaying, setCurrentlyPlaying]);
  */

  const updateProgress = useCallback(() => {
    const { currentTime, duration } = audioRef.current;
    let progress = duration ? (currentTime / duration) * 100 : 0;
    if (progress === 100) progress = 0;
    setProgressValue(progress);
  }, []);

  const handleButtonClick = () => (isPlaying ? handlePauseButton() : handlePlayButton());

  useEffect(() => {
    const audioEl = audioRef.current;
    if (audioEl) {
      audioEl.addEventListener('ended', handlePauseButton);
      audioEl.addEventListener('pause', handlePauseButton);
      audioEl.addEventListener('timeupdate', updateProgress);
    }
    return () => {
      if (audioEl) {
        audioEl.removeEventListener('ended', handlePauseButton);
        audioEl.removeEventListener('pause', handlePauseButton);
        audioEl.removeEventListener('timeupdate', updateProgress);
      }
    };
  }, [handlePauseButton, updateProgress]);

  return (
    <button type="button" className={styles['ht-audio-button']} onClick={handleButtonClick} aria-label="play or pause audio">
      <CircleProgress progress={progressValue} />
      <div aria-label="play or pause audio" className={cx(styles.button)}>
        {isPlaying ? <SvgIcon icon="pause" alt="pause" /> : <SvgIcon icon="play" alt="play" />}
      </div>
      <audio ref={audioRef} data-scene={sceneId} src={url} type="audio/m4a" preload="none" />
    </button>
  );
};
