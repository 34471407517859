import React from 'react';

import { createFormat } from '@edge-runtime/format';
import dayjs from 'dayjs';

import logger from 'lib/logger';

const format = createFormat();
const log = logger({ category: 'error-boundary' });

const logRemotely = async (message) => {
  try {
    log.error(message);
    const url = process.env.VERCEL_GIT_COMMIT_REF === 'main' ? 'https://logs.hearingtracker.com' : 'https://logs-staging.hearingtracker.com';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Vercel-Key': process.env.REMOTE_LOG_KEY,
      },
      body: JSON.stringify({ timestamp: dayjs().toISOString(), message }),
    };
    return fetch(url, options);
  } catch (ex) {
    console.error('error sending logs: %o', ex);
  }
  return null;
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  /*
  static getDerivedStateFromError(error) {
    logRemotely(format('error-boundary: getDerivedstate: %o', { error, path: window.location.pathname }));
    return { hasError: true, error };
  }
  */

  componentDidCatch(error, errorInfo) {
    logRemotely(format('error-boundary: %o', { error, errorInfo, path: window.location.pathname }));
  }

  render() {
    // Check if the error is thrown
    const { hasError, error } = this.state;
    const { children } = this.props;
    if (hasError) {
      // TODO: make a nice error page but for not just send it to the 500
      throw new Error(error);
      /*
      // You can render any custom fallback UI
      return (
        <Layout>
          <h2>Something went wrong</h2>
          <p>
            We are aware and are looking into it. Please try again or reload the page to see if that helps. If you continue to have problems send an e-mail to
            info@hearingtracker.com with as much information as possible. Thanks!
          </p>
          <button type="button" onClick={() => this.setState({ hasError: false })}>
            Try again?
          </button>
        </Layout>
      );
      */
    }
    return children;
  }
}

export default ErrorBoundary;
