import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { Tab, Transition } from '@headlessui/react';
import ScrollBooster from 'scrollbooster';

import ImageWrapper from 'components/common-n4/image';
import type { ImageSource } from 'components/common-n4/image/types';
import fonts from 'components/layout/fonts';
import Price from 'components/widgets/price';
import { WINDOW_DIMENSION_TYPE, useWindowDimensions } from 'hooks/use_window_dimensions';
import logger from 'lib/logger';
import { cx, getWindowDimensionType, tw } from 'lib/utils';
import type { Release, Model, Level, ModelFeatureDetail, LevelFeatureDetail } from 'types/release';

import { MultiProductsTabPanel } from './multi-product-tab-panel';
import { SingleProductTabPanel } from './single-product-tab-panel';

const log = logger({ category: 'n4/ModelComparison' });

export const ModelComparisonContext = React.createContext<{
  release: Release | null;
  models: Model[];
  brandName: string;
  level: Level | null;
  modelFeatureDetails: ModelFeatureDetail[];
  levelFeatureDetails: LevelFeatureDetail[];
}>({
  release: null,
  models: [],
  brandName: '',
  level: null,
  modelFeatureDetails: [],
  levelFeatureDetails: [],
});

interface ModelComparisonProps {
  release: Release;
}

interface ModelComparisonTabProps {
  releaseSlug: string;
  level: Level;
  image: ImageSource;
  selected: boolean;
}

const ModelComparisonTab: React.FC<ModelComparisonTabProps> = ({ releaseSlug, level, image, selected }) => {
  return (
    <div
      className={tw(
        'flex items-center gap-4 rounded-[10px] px-[24px] pb-[8px] pt-[10px] shadow-[0_0_0_1px_#E7E9EC] lg:px-[13px] lg:py-2',
        selected && 'shadow-[0_0_0_2px_#4A7FE5] lg:shadow-[0_0_0_3px_#4A7FE5]'
      )}
    >
      <ImageWrapper
        image={image}
        sizingProps={{ fixed: true, fixedWidth: 50, fixedHeight: 50 }}
        imgProps={{ className: 'hidden lg:block' }}
        origin="expert-review"
      />
      <div className="text-left">
        <div className="lg:leading-[120%]">
          <span className="mr-1 whitespace-nowrap text-base font-medium leading-[120%] -tracking-[0.6px] text-navy md:text-md">{level.name}</span>
        </div>
        <div className="lg:leading-[140%]">
          <span className="whitespace-nowrap text-[16px] leading-[140%] -tracking-[0.48px] text-lapis lg:text-md">
            <Price
              suffix=" a pair"
              loading="Loading price..."
              releaseSlug={releaseSlug}
              levelId={level.id}
              price={level.price}
              noGeo
              origin="product/model-comparison"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export const ModelComparison: React.FC<ModelComparisonProps> = ({ release }) => {
  log.debug('release: %o', release);
  const { levels, models, image, brand, hearing_aids: headingAids, model_features: modelFeatureDetails, level_features: levelFeatureDetails } = release;
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const viewportRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLUListElement>(null);
  const { width } = useWindowDimensions();
  const dimensionType = getWindowDimensionType(width);
  const [isShowing, setIsShowing] = useState(true);

  const contextValues = React.useMemo(
    () => ({
      release,
      models: models.filter((m) => headingAids.some((ha) => ha.levelId === levels[selectedIndex].id && ha.modelId === m.id)),
      brandName: brand.name,
      level: levels[selectedIndex],
      modelFeatureDetails,
      levelFeatureDetails,
    }),
    [release, models, brand.name, levels, selectedIndex, modelFeatureDetails, levelFeatureDetails, headingAids]
  );

  const onTabClick = useCallback((index: number) => {
    setIsShowing(false);
    setTimeout(() => {
      setIsShowing(true);
      setSelectedIndex(index);
    }, 100);
  }, []);

  useEffect(() => {
    let sb: ScrollBooster;
    if (viewportRef.current && contentRef.current && dimensionType !== WINDOW_DIMENSION_TYPE.md && dimensionType !== WINDOW_DIMENSION_TYPE.mobile) {
      sb = new ScrollBooster({
        viewport: viewportRef.current,
        content: contentRef.current,
        scrollMode: 'transform',
        direction: 'horizontal',
      });
    }

    return () => {
      sb?.destroy();
    };
  }, [dimensionType]);

  return (
    <Tab.Group
      selectedIndex={selectedIndex}
      onChange={onTabClick}
      as="section"
      className={cx(
        fonts.hankenGrotesk.className,
        'flex max-w-[1344px] flex-col gap-9 rounded-[20px] bg-white px-[20px] py-[42px] shadow-xl lg:gap-10 lg:px-0 lg:pb-16 lg:pt-12'
      )}
    >
      {levels.length > 1 && (
        <div className="flex flex-col gap-4 border-b border-neutral-300 pb-[32px] lg:mx-12 lg:block lg:pb-8">
          <h2 className="text-[16px] -tracking-[0.48px] text-navy/80 lg:mb-4 lg:text-base">Select Technology Level</h2>
          <div className="overflow-auto lg:overflow-hidden" ref={viewportRef}>
            <Tab.List as="ul" className="flex flex-row gap-5 p-[2px] lg:items-center lg:gap-5 lg:px-1 lg:py-1" ref={contentRef}>
              {levels.map((level, index) => (
                <li key={level.id}>
                  <Tab className={tw('w-full outline-0 lg:w-max', index === levels.length - 1 && 'pr-1')}>
                    {({ selected }) => <ModelComparisonTab releaseSlug={release.slug} level={level} image={image} selected={selected} />}
                  </Tab>
                </li>
              ))}
            </Tab.List>
          </div>
        </div>
      )}
      <ModelComparisonContext.Provider value={contextValues}>
        <Tab.Panels as="section">
          {levels.map((level, index) => (
            <Transition
              key={level.id}
              as={Fragment}
              show={isShowing && selectedIndex === index}
              enter="transform transition duration-[400ms]"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transform duration-200 transition ease-in-out"
              leaveFrom="opacity-100 rotate-0 scale-100"
              leaveTo="opacity-0 scale-95 "
            >
              <Tab.Panel unmount={false}>
                {contextValues.models.length > 1 ? (
                  <MultiProductsTabPanel selectedIndex={selectedIndex} />
                ) : (
                  <SingleProductTabPanel releaseSlug={release.slug} level={level} />
                )}
              </Tab.Panel>
            </Transition>
          ))}
        </Tab.Panels>
      </ModelComparisonContext.Provider>
    </Tab.Group>
  );
};
