import { cx } from 'lib/utils';

import styles from './skeleton.module.scss';

/**
 * @param {number} count - how many of these skeletons should be rendered.
 * @param {number} height - either set height in parent or if height can be dynamic set an explicit 'placeholder' height here.
 * @param {boolean} isCircle - renders skeleton as a circle.
 *
 * Notes on width:
 * if parent of this component has display: flex, make sure to set parent to flex: 1 as well,
 * otherwise set parent width of how much you'd like skeleton to expand.
 */
export const Skeleton = ({ count = 1, height = '100%', isCircle = false }) =>
  Array.from({ length: count }).map((_, index) => (
    <div
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      aria-hidden
      style={{ height }}
      className={cx(styles.skeleton, isCircle && styles['skeleton--circle'])}
    />
  ));
