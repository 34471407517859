import * as React from 'react';

import { Gallery } from 'components/common-n4/gallery';
import IconPlay from 'images/white-play-button.svg';

import { ProviderPopupLayout } from '../../layout';
import { StepIndicator } from '../../step-indicator';
import { ContentProps } from '../../types';

export const Accepted: React.FC<ContentProps> = () => {
  // const response = state.searchResponse!;
  const [modalShowing, setModalShowing] = React.useState(false);

  const onClose = () => {
    setModalShowing(false);
  };

  return (
    <ProviderPopupLayout currentSlide={4}>
      <div className="flex flex-col items-center justify-between gap-10 px-7 py-10 lg:gap-0 lg:px-6 lg:py-6">
        <StepIndicator steps={4} currentStep={4} />
        <div className="">
          <div className="flex items-center justify-center text-center text-[32px] font-medium leading-9 tracking-tight text-navy lg:mt-10">
            We&apos;re on it!
          </div>
          <div className="mt-[14px] text-center text-[18px] font-light leading-6 tracking-tight text-navy-80 lg:mt-4">
            You&apos;ll get a call very shortly (usually within minutes), from our preferred discount partner, <b>ZipHearing</b>.
          </div>
          <div className="mt-[14px] text-center text-[14px] font-light leading-6 tracking-tight text-navy-80 lg:mt-6 lg:px-3 lg:text-[18px]">
            <b>ZipHearing</b> has a proven track record of delivering high-quality prescription hearing aids at discounted prices—with excellent local care.
          </div>
          <hr className="my-[28px] self-stretch border-t border-navy-10 lg:my-6" />
          <div className="mt-[14px] text-center text-[14px] font-light leading-6 tracking-tight text-navy-80 lg:mt-4 lg:px-3 lg:text-[18px]">
            While you wait, we recommend watching the video below to learn how ZipHearing works.
          </div>
        </div>

        <button className="" onClick={() => setModalShowing(true)}>
          <div className="w-100 flex self-stretch lg:mt-6">
            <div className="flex items-center gap-4 rounded-[10px] border border-navy-10 p-2">
              <div className="relative h-[56px] w-[96px] rounded bg-navy-10 bg-[url('/images/design/how-zip-hearing-works.jpeg')] bg-cover">
                <div className="absolute bottom-1 right-1 h-[20px] w-[20px] rounded-full">
                  <IconPlay className="shadow-xl" />
                </div>
              </div>
              <div className="text-black">
                <div className="text-sm tracking-tight">How ZipHearing Works</div>
                <div className="text-[8px] -tracking-[0.24px] lg:text-[10px]">01:01</div>
              </div>
            </div>
          </div>
        </button>
      </div>
      <Gallery
        isOpen={modalShowing}
        onClose={onClose}
        media={[
          {
            type: 'vimeo',
            videoId: '709867616',
          },
        ]}
        classes={{ panel: 'max-w-7xl' }}
      />
    </ProviderPopupLayout>
  );
};
