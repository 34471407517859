import { Hanken_Grotesk as HankenGrotesk, Mulish } from 'next/font/google';

const mulish = Mulish({
  subsets: ['latin'],
  preload: true,
  adjustFontFallback: true,
  fallback: ['Trebuchet MS', 'Tahoma', 'Arial'],
  variable: '--mulish-font',
  display: 'swap',
});

const hankenGrotesk = HankenGrotesk({
  subsets: ['latin'],
  preload: true,
  adjustFontFallback: true,
  fallback: ['Trebuchet MS', 'Tahoma', 'Arial'],
  variable: '--hankenGrotesk-font',
  display: 'swap',
});

const fonts = { hankenGrotesk, mulish };
export default fonts;
