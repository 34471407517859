import * as React from 'react';

export const AudioContext = React.createContext(null);

export const AudioProvider = ({ children }) => {
  const [currentlyPlaying, setCurrentlyPlaying] = React.useState(null); // audioRef.current from Audio
  const [sceneId, setSceneId] = React.useState(null);
  const [hasSceneChanged, setHasSceneChanged] = React.useState(false);

  React.useEffect(() => {
    setHasSceneChanged(true);
  }, [sceneId]);

  // Toggle the scene changed flag back after they start playing again
  React.useEffect(() => {
    setHasSceneChanged(false);
  }, [currentlyPlaying]);

  const contextValues = React.useMemo(
    () => ({
      currentlyPlaying,
      setCurrentlyPlaying,
      sceneId,
      setSceneId,
      hasSceneChanged,
    }),
    [currentlyPlaying, setCurrentlyPlaying, sceneId, setSceneId, hasSceneChanged]
  );

  return <AudioContext.Provider value={contextValues}>{children}</AudioContext.Provider>;
};
