import * as React from 'react';

import { storyblokEditable } from '@storyblok/react';

import logger from 'lib/logger';
import { cx } from 'lib/utils';

import styles from './table.module.scss';

const log = logger({ category: 'n4/Table' });

const Table = ({ blok, story, ...props }) => {
  log.debug('blok: %o', { blok, props });
  const { thead, tbody } = blok.table;
  const rows = tbody && tbody.filter((c) => c.component === '_table_row');
  const tableContainerRef = React.useRef(null);
  const captionRef = React.useRef(null);

  // p tag needs to be outside due to scrolling and other styles applied to container
  // since p tag and container need to be unrelated easier to style with js
  React.useEffect(() => {
    if (tableContainerRef.current && captionRef.current) {
      const tableWidth = tableContainerRef.current.offsetWidth;

      captionRef.current.style.width = `${tableWidth}px`;
    }
  }, []);

  return (
    <section className={cx(styles.table, blok.className)} {...storyblokEditable(blok)}>
      <div className={styles.wrapper}>
        <div ref={tableContainerRef} className={styles.container}>
          <table>
            {thead && (
              <thead>
                <tr>
                  {thead.map((cell) => (
                    <th key={cell._uid}>{cell.value}</th>
                  ))}
                </tr>
              </thead>
            )}
            {tbody && (
              <tbody>
                {rows.map((row) => (
                  <tr key={row._uid}>
                    {row.body.map((cell) => (
                      <td key={cell._uid}>{cell.value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {blok.caption ? (
          <p ref={captionRef} className={styles.captions}>
            <span>{blok.caption}</span>
          </p>
        ) : null}
      </div>
    </section>
  );
};

export default Table;
