import * as React from 'react';

import { CircleSpinner } from 'components/bloks/n4/images/circle-spinner';

import { ProviderPopupLayout } from '../../layout';
import { ContentProps } from '../../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Submitting: React.FC<ContentProps> = ({ dispatch, state }) => {
  return (
    <ProviderPopupLayout>
      <div className="flex min-h-[43vh] flex-col items-center justify-center py-6 lg:h-full lg:min-h-0 lg:px-10">
        <div className="flex flex-1 flex-col items-center justify-center gap-7">
          <CircleSpinner size="132" />
          <div className="text-base font-light leading-5 tracking-tight text-navy-90">Submitting your contact information</div>
        </div>
      </div>
    </ProviderPopupLayout>
  );
};
