import { CircleProgress } from 'components/bloks/n4/images/circle_progress';
import { cx } from 'lib/utils';

import styles from './circle-score.module.scss';

export const CircleScore = ({ progress, amount, className, isRight = false, isSmall, origin }) => (
  <section
    className={cx(
      styles.score,
      isRight && styles['score--right'],
      isSmall && styles['score--small'],
      isRight && isSmall && styles['score--right-small'],
      className
    )}
  >
    <CircleProgress progress={progress} />
    <div className={styles['logo-text']}>
      <div className={styles.logo} />
      <span>Score</span>
    </div>
    <span data-origin={origin} className={styles.amount}>
      {amount.toFixed ? amount.toFixed(1) : amount}
    </span>
  </section>
);
