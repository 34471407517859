import { ClassNameValue } from 'tailwind-merge';

import SvgIcon from 'components/ui/svg_icon';
import { cx } from 'lib/utils';

interface StarsProps {
  max: number;
  value: number;
  className?: ClassNameValue;
  starClassName?: ClassNameValue;
}

const Stars: React.FC<StarsProps> = ({ max, value, className, starClassName }) => {
  const stars = [];
  for (let i = 1; i < max + 1; i += 1) {
    if (value >= i) {
      stars.push(
        <li key={i} className={cx('star', starClassName)}>
          <SvgIcon icon="star-active" />
        </li>
      );
    } else {
      stars.push(
        <li key={i} className={cx('star', starClassName)}>
          <SvgIcon icon="star-inactive" />
        </li>
      );
    }
  }
  return (
    <ul className={['cursor-pointer', 'ht-stars', className].filter((c) => !!c).join(' ')} data-score={value}>
      {stars}
    </ul>
  );
};

export default Stars;
