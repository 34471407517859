export const AUDIO_SCENES = [
  {
    id: 'cafe',
    default: true,
    title: 'Cafe',
    env: 'Cafe 1',
    environment: 'noisy', // Used in newer stuff
    url: 'https://d26t98c74zewbt.cloudfront.net/cafe-1_2tv1_open-ear_normal.mp4',
    time: 0,
    pop: 'Recordings were made using the background sound of a busy cafe.',
  },
  {
    id: 'office',
    default: true,
    title: 'Office',
    env: 'Office',
    environment: 'quiet',
    url: 'https://d26t98c74zewbt.cloudfront.net/office_2tv2_open-ear_normal.mp4',
    time: 0,
    pop: 'Recordings were made using the background sound of a quiet office.',
  },
  {
    id: 'music',
    default: true,
    title: 'Music',
    env: 'Streaming Music - Country Rock',
    environment: 'music',
    url: 'https://d26t98c74zewbt.cloudfront.net/countryrock_ref-3.mp4',
    time: 0,
    pop: 'Recordings were made while streaming music to the hearing aids via Bluetooth.',
  },
];
