import * as React from 'react';

interface ReviewMessageProps {
  reviewMessage: string;
  onReviewMessageChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const ReviewMessage: React.FC<ReviewMessageProps> = ({ reviewMessage, onReviewMessageChange }) => {
  return (
    <div className="mt-[37px] flex-1 px-[20px] py-2 laptop:px-[112px] laptop:pb-[80px]">
      <textarea
        value={reviewMessage}
        onChange={onReviewMessageChange}
        rows={7}
        className="w-full rounded-[10px] border border-navy-10 p-4"
        placeholder="Type your message..."
      />
    </div>
  );
};
