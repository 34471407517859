import { useEffect, useReducer } from 'react';

import { useSession } from 'hooks/use_session';
import { fetchApi } from 'lib/ht_api';
import logger from 'lib/logger';

import { Button } from '../button';
import { Loader } from '../loader';
import { Modal } from '../modal';

const log = logger({ category: 'auth' });

const reducer = (state, action) => {
  log.debug('action: %o', action);
  const { type, ...rest } = action;
  switch (type) {
    case 'cancel':
      return { ...state, ...rest, mode: 'cancel', isLoading: false, password: '', token: '', error: '' };
    case 'signin':
      return { ...state, ...rest, mode: 'signin', isLoading: false };
    case 'register':
      return { ...state, ...rest, mode: 'register', isLoading: false, error: '' };
    case 'signing-in':
      return { ...state, ...rest, mode: 'signin', isLoading: true, error: '' };
    case 'signin-success':
      return { ...state, ...rest, mode: 'signin-success', isLoading: false, token: '', password: '', error: '' };
    case 'forgot':
      return { ...state, ...rest, mode: 'forgot', isLoading: false, error: '' };
    case 'sending-reset-link':
      return { ...state, ...rest, mode: 'forgot', isLoading: true, token: '', password: '', error: '' };
    case 'reset':
      return { ...state, ...rest, mode: 'reset', isLoading: false };
    case 'resetting':
      return { ...state, ...rest, mode: 'reset', isLoading: true, error: '' };
    case 'reset-success':
      return { ...state, ...rest, mode: 'reset-success', isLoading: false, error: '', token: '', password: '' };
    case 'change':
      return { ...state, ...rest, error: null, isLoading: false };
    case 'clearError':
      return { ...state, ...rest, error: null };
    default:
      log.error('Unknown action: %o', action);
  }
  return state;
};

const modes = {
  signin: { title: 'Sign In', caption: 'Enter your email address and password to sign in', showPassword: true },
  forgot: { title: 'Forgot Password', caption: 'Enter the email address you used to register and we will send a reset code', submitLabel: 'Send Reset Code' },
  reset: { title: 'Reset Password', caption: 'Enter your email, the code we sent, and your new password', showPassword: true, showToken: true },
  register: { title: 'Sign Up', caption: 'Enter an email address and password to register for an account', showPassword: true },
};

const Form = ({ state, dispatch, handleSubmit }) => {
  const options = modes[state.mode];

  const handleForgot = () => dispatch({ type: 'forgot' });
  const handleSignin = () => dispatch({ type: 'signin' });
  const handleReset = () => dispatch({ type: 'reset' });
  const handleRegister = () => dispatch({ type: 'register' });
  const handleCancel = () => dispatch({ type: 'cancel' });
  const handleChange = (field) => (e) => dispatch({ type: 'change', [field]: e.target.value });

  const disabled = !state.email && (!options.showPassword || !state.password) && (!options.showToken || !state.token);

  return (
    <div>
      <h1 className="text-2xl">{options.title}</h1>
      <div style={{ marginBottom: 10 }}>{options.caption}</div>
      <div style={{ visibility: state.error ? 'visible' : 'hidden', color: '#c00', marginBottom: 10 }}>{state.error}</div>
      <div style={{ visibility: state.message ? 'visible' : 'hidden', color: '#0c0', marginBottom: 10 }}>{state.message}</div>
      <form onSubmit={handleSubmit} disabled={disabled} style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 4, marginLeft: 20 }}>
          <label htmlFor="auth-email">E-mail</label>
          <input type="text" id="auth-email" style={{ borderWidth: 1, padding: 4 }} value={state.email} onChange={handleChange('email')} />
        </div>
        {options.showToken && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 4, marginLeft: 20 }}>
            <label htmlFor="auth-token">Code from email</label>
            <input type="text" id="auth-token" style={{ borderWidth: 1, padding: 4 }} value={state.token} onChange={handleChange('token')} />
          </div>
        )}
        {options.showPassword && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 4, marginLeft: 20 }}>
            <label htmlFor="auth-password">{state.mode === 'reset' ? 'New ' : ''} Password</label>
            <input type="password" id="auth-password" style={{ borderWidth: 1, padding: 4 }} value={state.password} onChange={handleChange('password')} />
          </div>
        )}
        <div style={{ display: 'flex', gap: 10, marginTop: 20 }}>
          {state.isLoading && <Loader />}
          <Button.Primary onClick={handleSubmit} buttonType="submit" disabled={disabled}>
            {options.submitLabel || options.title}
          </Button.Primary>
          {state.mode !== 'forgot' && state.mode !== 'register' && (
            <Button.Secondary onClick={handleForgot}>{options.showToken ? 'Request New Code' : 'Forgot Password'}</Button.Secondary>
          )}
          {state.mode !== 'signin' && state.mode !== 'register' && <Button.Secondary onClick={handleSignin}>I Remember!</Button.Secondary>}
          {state.mode !== 'reset' && state.mode !== 'register' && <Button.Secondary onClick={handleReset}>I have a code</Button.Secondary>}
          {state.mode !== 'register' && <Button.Secondary onClick={handleRegister}>Sign Up</Button.Secondary>}
          {state.mode === 'register' && <Button.Secondary onClick={handleSignin}>Sign In</Button.Secondary>}
          <Button.Secondary onClick={handleCancel}>Cancel</Button.Secondary>
        </div>
      </form>
    </div>
  );
};

const Login = ({ state, dispatch }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = state;
    if (!email || !password) {
      dispatch({ type: 'signin', error: 'Email and password are required' });
      return;
    }
    dispatch({ type: 'signing-in' });
    const result = await fetchApi({ path: 'auth/signin', method: 'POST', variables: { email, password }, fallback: 'friendly', origin: 'auth/index.js' });
    if (result.error) {
      console.error(result.exception || result.error);
      dispatch({ type: 'signin', error: result.error });
    } else {
      dispatch({ type: 'signin-success', user: result.user });
    }
  };

  return <Form state={state} dispatch={dispatch} handleSubmit={handleSubmit} />;
};

const Forgot = ({ state, dispatch }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = state;
    if (email) {
      // go ahead and send link
      dispatch({ type: 'sending-reset-link' });
      const result = await fetchApi({ path: 'auth/forgot_password', method: 'POST', variables: { email }, fallback: 'friendly', origin: 'auth/index.js' });
      if (result.error) {
        log.error(result.exception || result.error);
        dispatch({ type: 'forgot', error: result.error });
      } else {
        dispatch({ type: 'reset' });
      }
    } else {
      dispatch({ type: 'forgot' });
    }
  };

  return <Form state={state} dispatch={dispatch} handleSubmit={handleSubmit} />;
};

const Reset = ({ state, dispatch }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, token, password } = state;
    if (!(email && token && password)) {
      dispatch({ type: 'reset', error: 'Please enter your e-mail and password and the code from the email we sent' });
      return;
    }
    dispatch({ type: 'resetting' });
    const result = await fetchApi({
      path: 'auth/reset_password',
      method: 'POST',
      variables: { email, token, password },
      fallback: 'friendly',
      origin: 'auth/index.js',
    });
    if (result.error) {
      log.error(result.exception || result.error);
      dispatch({ type: 'reset', error: result.error });
    } else {
      dispatch({ type: 'reset-success', user: result.user });
    }
  };

  return <Form state={state} dispatch={dispatch} handleSubmit={handleSubmit} />;
};

const Success = ({ state, dispatch }) => {
  useEffect(() => {
    setTimeout(() => dispatch({ type: 'cancel' }), 2000);
  }, [state, dispatch]);

  if (state.mode === 'reset-success') {
    return <div>Password was successfully reset. You are being automatically logged in.</div>;
  }

  return <div>Password accepted. You are being logged in.</div>;
};

const Register = ({ state, dispatch }) => {
  const handleSubmit = async () => {};

  return <Form state={state} dispatch={dispatch} handleSubmit={handleSubmit} />;
};

const Auth = ({ isOpen, setIsOpen }) => {
  const email = typeof localStorage === 'undefined' ? '' : localStorage.getItem('ht-user-email') || '';
  const { setUser } = useSession();
  const [state, dispatch] = useReducer(reducer, { mode: 'signin', email, password: '', token: '' });

  log.debug('state: %o', state);

  useEffect(() => {
    if (state.mode === 'cancel') {
      dispatch({ type: 'signin' }); // reset to initial
      setIsOpen(false);
    }
  }, [state.mode, setIsOpen, dispatch]);

  useEffect(() => {
    setUser((oldUser) => {
      if (oldUser?.email === state.user?.email) {
        return oldUser;
      }
      return state.user;
    });
  }, [state.user, setUser]);

  let view = <Login state={state} dispatch={dispatch} />;
  if (/success/.test(state.mode)) {
    view = <Success state={state} dispatch={dispatch} />;
  } else if (/forgot/.test(state.mode)) {
    view = <Forgot state={state} dispatch={dispatch} />;
  } else if (/reset/.test(state.mode)) {
    view = <Reset state={state} dispatch={dispatch} />;
  } else if (/register/.test(state.mode)) {
    view = <Register state={state} dispatch={dispatch} />;
  } else if (/signin/.test(state.mode)) {
    view = <Login state={state} dispatch={dispatch} />;
  } else {
    return null; // cancel
  }

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div className="px-8 py-8">{view}</div>
    </Modal>
  );
};

export default Auth;
