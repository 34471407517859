import * as FeatureCtaCardComponents from './feature-cta-card-components';

export type FeatureCtaCardProps = React.PropsWithChildren<
  FeatureCtaCardComponents.RootProps & FeatureCtaCardComponents.ActionProps & FeatureCtaCardComponents.TitleProps
>;

export const FeatureCtaCard: React.FC<FeatureCtaCardProps> = ({ variant, extended, image, hint, url, title, className }) => {
  return (
    <FeatureCtaCardComponents.Root variant={variant} extended={extended} image={image} className={className} url={url}>
      <FeatureCtaCardComponents.Title title={title} />
      <FeatureCtaCardComponents.Action hint={hint} url={url} />
    </FeatureCtaCardComponents.Root>
  );
};
