import * as React from 'react';

import { CircleProgress } from 'components/bloks/n4/images/circle_progress';
import SvgIcon from 'components/ui/svg_icon';
import { useAudio } from 'hooks';
import { formatSecondsToTime } from 'lib/utils';
import type { Audio } from 'types/release';

interface CircularAudioPlayerProps {
  audio: Audio | undefined;
  title: string;
}

interface AudioPlayerProps {
  isPlaying?: boolean;
  handlePause?: () => void;
  handlePlay?: () => void;
  progress: number;
}

interface UseAudio {
  currentlyPlaying: { id: number | string | undefined; audio: HTMLAudioElement };
  setCurrentlyPlaying: React.Dispatch<React.SetStateAction<{ id: number | string | undefined; audio: HTMLAudioElement } | null>>;
  hasSceneChanged: boolean;
  // setHasSceneChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ isPlaying, handlePause, handlePlay, progress }) => (
  <button type="button" onClick={isPlaying ? handlePause : handlePlay} aria-label={isPlaying ? 'play audio' : 'pause audio'} className="relative">
    <CircleProgress size="64" progress={progress * 100} isStatic />
    <figure className="absolute-center">
      <SvgIcon icon={isPlaying ? 'pause' : 'play'} className="ml-[4px] mt-[2px] h-[21px] w-[16px]" />
    </figure>
  </button>
);

const CircularAudioPlayer: React.FC<CircularAudioPlayerProps> = ({ audio = {}, title }) => {
  const audioRef = React.useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState('0:00');
  const [duration, setDuration] = React.useState('0:00');
  const [progress, setProgress] = React.useState(0);

  const { currentlyPlaying, setCurrentlyPlaying, hasSceneChanged /*, setHasSceneChanged */ }: UseAudio = useAudio();

  const updateProgress = React.useCallback(() => {
    if (!audioRef.current) return;
    setCurrentTime(formatSecondsToTime(audioRef.current.currentTime));
    setProgress(audioRef.current.currentTime / audioRef.current.duration);
  }, []);

  const handlePlay = () => {
    if (!audioRef.current) return;

    // if there is any audio playing, pause it.
    currentlyPlaying?.audio?.pause();
    // audioRef.current?.pause();

    let updatedTime = 0;
    if (hasSceneChanged || currentlyPlaying.audio.currentTime === currentlyPlaying.audio.duration) {
      audioRef.current.currentTime = 0;
      // setHasSceneChanged(false);
    }
    // cancel progress bar animation of any last existing audio.
    else if (audioRef.current) {
      audioRef.current.currentTime = currentlyPlaying.audio.currentTime;
      updatedTime = currentlyPlaying.audio.currentTime;
    }
    // throttle current time updates to every one second to avoid extra re-renders
    const timeFormatted = formatSecondsToTime(updatedTime);
    setCurrentTime(timeFormatted);

    // play the audio
    audioRef.current.play();
    // local state knows audio is playing
    setIsPlaying(true);
    // context knows audio is playing so another Audio component can cancel.
    setCurrentlyPlaying({
      id: audio.id,
      audio: audioRef.current,
    });
  };

  const handlePause = React.useCallback(() => {
    if (!audioRef.current) return;

    // to avoid calling pause method twice unnecessarily when another audio element calls to pause this method via currentlyPlaying?.pause();
    if (isPlaying) audioRef.current.pause();

    setIsPlaying(false);
  }, [isPlaying]);

  const handleMetadataLoaded = () => {
    if (audioRef.current && audioRef.current.duration) {
      setDuration(formatSecondsToTime(audioRef.current.duration));
    }
  };

  React.useEffect(() => {
    const audioEl = audioRef.current;

    if (audioEl) {
      audioEl.addEventListener('ended', handlePause);
      // to trigger handlePause when another Audio component triggers pause in the DOM to the audio in this component.
      audioEl.addEventListener('pause', handlePause);
      audioEl.addEventListener('timeupdate', updateProgress);
    }

    return () => {
      if (audioEl) {
        audioEl.removeEventListener('ended', handlePause);
        audioEl.removeEventListener('pause', handlePause);
        audioEl.removeEventListener('pause', updateProgress);
      }
    };
  }, [handlePause, updateProgress]);

  if (!audio?.url) return null;

  return (
    <>
      <div>
        <div className="text-[20px] -tracking-[0.6px] lg:text-xl lg:-tracking-[0.54px]">{title}</div>
        <div className="text-base -tracking-[0.42px] text-[#545454] lg:text-sm">
          {currentTime} / {duration}
        </div>
      </div>
      <div className="flex items-center">
        <AudioPlayer isPlaying={isPlaying} handlePause={handlePause} handlePlay={handlePlay} progress={progress} />
      </div>
      {/* note: tried preload=metadata and that loads the whole thing which is fine for one, but not for 20 */}
      <audio ref={audioRef} src={audio.url} preload="none" onLoadedMetadata={handleMetadataLoaded} />
    </>
  );
};

export default CircularAudioPlayer;
