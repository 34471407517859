import { storyblokEditable } from '@storyblok/react';

import logger from 'lib/logger';
import { cx } from 'lib/utils';

// Can share from container if there are not many styles, otherwise use separate module per component
import styles from './home-container.module.scss';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'n4/home/video' });

const Video = ({ blok, story, ...props }) => {
  log.debug('blok: %o', { blok, props });
  return (
    <section className={cx(styles['home-video'], blok.className)} {...storyblokEditable(blok)} style={{ border: '1px solid maroon', margin: 20 }}>
      <h3>Split Video</h3>
      <ul>
        <li>left text: {blok.text_left}</li>
        <li>right text 1: {blok.text_right_1}</li>
        <li>right text 2: {blok.text_right_2}</li>
        <li>right text 3: {blok.text_right_3}</li>
        {/* TODO: populate videos
        <li>video left: {JSON.stringify(blok.video_left)}</li>
        <li>video right: {JSON.stringify(blok.video_right)}</li>
        */}
      </ul>
    </section>
  );
};

export default Video;
