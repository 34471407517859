import React from 'react';

import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { motion } from 'framer-motion';

import { useApiData } from 'hooks';
import logger from 'lib/logger';
import { track } from 'lib/tracking';
import { cx } from 'lib/utils';

import styles from './panel_tab.module.scss';

const log = logger({ category: 'nav/PanelTab' });

/* eslint-disable-next-line no-unused-vars */
const PanelTab = ({ blok, story, id, ...props }) => {
  const apiData = useApiData();
  log.debug('blok: %o', blok);

  React.useEffect(() => {
    if (blok.title === 'Featured') {
      blok.items.forEach((item) => {
        const product = apiData.models[item.form_factor];
        if (product) {
          const properties = {
            release: product.release.slug,
          };
          track({ event: `nav-product--impression`, eventType: 'impression', origin: window.location.pathname, properties });
        }
      });
    }
  }, [blok, apiData.models]);

  const { items } = blok;
  const isProducts = blok.items?.[0].component === 'nav-product';
  const isLarge = blok.large;

  const expand =
    story?.full_slug?.endsWith('components/nav') &&
    !/no-expand/.test(story.content.debug) &&
    (typeof window === 'undefined' || !/no-expand/.test(window.location.search));

  return (
    <motion.section
      key={id}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.75 }}
      className={cx(styles['panel-tab'], isLarge && styles['panel-tab--large'], isProducts && styles['panel-tab--products'])}
      style={expand ? { maxHeight: 2000 } : {}}
      {...storyblokEditable(blok)}
    >
      {items.map((item) => (
        <StoryblokComponent blok={item} story={story} {...props} key={item._uid} {...storyblokEditable(item)} />
      ))}
    </motion.section>
  );
};

export default PanelTab;
