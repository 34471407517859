import React, { forwardRef, ReactNode } from 'react';

import { ClassNameValue } from 'tailwind-merge';

import { tw } from 'lib/utils';

import styles from './styled-scrollbar.module.scss';

interface StyledScrollbarProps {
  height?: number;
  children: ReactNode;
  className: ClassNameValue;
}

export const StyledScrollbar = forwardRef<HTMLDivElement, StyledScrollbarProps>(({ height, children, className }, ref) => {
  return (
    <div className={tw(styles['scrollbar-container'], 'relative overflow-y-auto', className)} style={{ height }} ref={ref}>
      {children}
    </div>
  );
});

StyledScrollbar.displayName = 'StyledScrollbar';
