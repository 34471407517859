import { storyblokEditable, type SbBlokData } from '@storyblok/react';

import { Testimonials as Testimonial } from './testimonials';

interface TestimonialProps extends SbBlokData {
  text: string;
  title: string;
  name: string;
  image: {
    filename: string;
  };
}

interface TestimonialBlok extends SbBlokData {
  title: string;
  description: string;
  testimonials: TestimonialProps[];
}

interface TestimonialsProps {
  blok: TestimonialBlok;
}

const Testimonials: React.FC<TestimonialsProps> = ({ blok }) => {
  const testimonialCardList = blok.testimonials.map((card: TestimonialProps) => ({
    text: card.text,
    title: card.title,
    name: card.name,
    image: card.image.filename,
    blok: card,
  }));

  return (
    <section {...storyblokEditable(blok)}>
      <Testimonial title={blok.title} description={blok.description} buttonText="View all" testimonialList={testimonialCardList} />
    </section>
  );
};

export default Testimonials;
