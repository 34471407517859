// ===> TL

export const LEVEL_TO_ICON_MAP = {
  level1: 'signal-duotone',
  level2: 'signal-strong-duotone',
  level3: 'signal-good-duotone',
  level4: 'signal-fair-duotone',
  level5: 'signal-week-duotone',
};

export const LEVEL_TO_MESSAGE_MAP = {
  level1: 'This is the highest end',
  level2: 'This is a step down from the highest end',
  level3: 'This is two steps down from the highest end',
  level4: 'This is three steps down from the highest end',
  level5: 'This is four+ steps down from the highest end',
};

// ===> sidebar filters

export const FILTERS_TYPE = {
  BRAND: 'brand',
  SOFTWARE: 'software',
  HARDWARE: 'hardware',
  PURCHASE_OPTIONS: 'purchase',
  LAB_TESTED: 'filters',
  LOSS: 'loss',
  STYLE: 'style',
};

// ===> audio modal

export const BASE_AUDIO_URL = 'https://a.storyblok.com/f/45415/x/';

export const AUDIO_SCENES = [
  {
    id: 'cafe',
    title: 'Busy Café',
    env: 'Cafe 1',
    url: '874eb50de8/cafe-1_2tv1_open-ear_normal.m4a',
    time: 0,
    pop: 'Recordings were made using the background sound of a busy cafe.',
  },
  {
    id: 'office',
    title: 'Quiet Office',
    env: 'Office',
    url: '16351534ff/office_2tv2_open-ear_normal.m4a',
    time: 0,
    pop: 'Recordings were made using the background sound of a quiet office.',
  },
  {
    id: 'music',
    title: 'Streaming Music',
    env: 'Streaming Music - Country Rock',
    url: '531bdb6634/countryrock_ref-2.m4a',
    time: 0,
    pop: 'Recordings were made while streaming music to the hearing aids via Bluetooth.',
  },
];

export const AUDIO_SETTINGS = {
  off: {
    id: 'off',
    title: 'No Hearing Aid',
    pop: "Audio was recorded without hearing aids in the manikin's ears.",
  },
  initial: {
    id: 'initial',
    title: 'Initial Fitting',
    pop: 'Recordings were made after the device was setup using a basic method.',
  },
  tuned: {
    id: 'tuned',
    title: 'Tuned Fitting',
    pop: 'Recordings were made after the device was setup using a advanced clinical method.',
  },
};
