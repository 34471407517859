import { HearingAid, Model, Price, Release } from 'types/release';
export enum SearchResponseType {
  partner = 'partner',
  fallback = 'fallback',
  subscribe = 'subscribe',
}

export enum Step {
  intro = 'intro', // intro screen
  zip = 'zip', // zip entry screen
  searching = 'searching', // searching screen
  phone = 'phone', // phone entry screen
  disclaimer = 'disclaimer', // just one more thing screen
  submitting = 'submitting', // phone entry screen or loading screen
  accepted = 'accepted', // lead submitted/success screen
  fallback = 'fallback', // fallback provider found screen
  subscribe = 'subscribe', // no providers found/subscribe screen
  subscribing = 'subscribing', // subscribing screen (if we need loader, otherwise stay on subscribe)
  subscribed = 'subscribed', // subscribe success screen
}

export enum Event {
  ButtonViewed = 'ButtonViewed',
  ButtonClicked = 'ButtonClicked',
  IntroRead = 'IntroRead',
  ZipCodeSubmitted = 'ZipCodeSubmitted',
  ZipHearingAvailable = 'ZipHearingAvailable',
  PhoneSubmitted = 'PhoneSubmitted',
  DisclaimerAccepted = 'DisclaimerAccepted',
  LeadSuccess = 'LeadSuccess',
  Subscribe = 'Subscribe',
  Restart = 'Restart',
  Fallback = 'Fallback',
  NoProviders = 'NoProviders',
  SubscribeSuccess = 'SubscribeSuccess',
  Error = 'Error',
}

export enum UserAction {
  start = 'start',
  restart = 'restart',
  change = 'change',
  submit = 'submit',
  cancel = 'cancel',
}

export enum SystemAction {
  set_segment = 'set_segment',
  error = 'error',
  search_response = 'search_response',
  submit_response = 'submit_response',
  subscribe_response = 'subscribe_response',
}

export enum ErrorCode {
  user_error_no_zip = 'user_error_no_zip',
  user_error_bad_zip = 'user_error_bad_zip',
  user_error_no_phone = 'user_error_no_phone',
  user_error_bad_phone = 'user_error_bad_phone',
  user_error_no_email = 'user_error_no_email',
  user_error_bad_email = 'suser_error_bad_email',
  search_error_system = 'search_error_system',
  search_error_zip = 'search_error_zip',
  submit_error_phone = 'submit_error_phone',
  submit_error_system = 'submit_error_system',
  subscribe_error_system = 'subscribe_error_system',
  subscribe_error_no_email = 'subscribe_error_no_email',
  subscribe_error_bad_email = 'subscribe_error_bad_email',
}

export interface Error {
  message: string;
  field?: string;
  postalCode?: string;
  phone?: string;
  code: ErrorCode;
}

export interface SearchResponse {
  message?: string;
  caption?: string;
  disclaimer?: string;
  buttonText?: string;
  providersFound?: number;
  error?: string;
  type: SearchResponseType;
  provider?: string;
  location?: string;
  trustPilotLink?: string;
  trustPilotText?: string;
  purchaseLink?: string;
}

export interface SubmitResponse {
  status: number;
  message: string;
  buttonText?: string;
  caption?: string;
  disclaimer?: string;
  leadId: number;
  error?: string;
  provider?: string;
  trustPilotLink?: string;
  trustPilotText?: string;
}

export interface SubscribeResponse {
  message: string;
  buttonText?: string;
  caption?: string;
  error?: string;
}

export type Distance = 25 | 50 | 75 | 100 | 150;

export interface StateUpdate {
  postalCode?: string;
  distance?: Distance;
  phone?: string;
  email?: string;
  searchResponse?: SearchResponse;
  submitResponse?: SubmitResponse;
  subscribeResponse?: SubscribeResponse;
  error?: Error;
  segment?: number;
  segmentOrigin?: 'button' | 'link';
  position?: string;
}

export interface State extends StateUpdate {
  step?: Step;
  release: Release;
  model?: Model;
  hearingAid?: HearingAid;
  price: Price;
  segment: number;
  segmentOrigin: 'button' | 'link';
  position: string;
  origin: string;
}

export interface Action {
  type: UserAction | SystemAction;
  payload?: StateUpdate;
}

export interface ContentProps {
  dispatch: React.Dispatch<Action>;
  state: State;
  handleClose?: () => boolean;
}
