// top-level container for N4 footer - should only be used on the n4/footer page
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import logger from 'lib/logger';
import { cx } from 'lib/utils';

import styles from './footer.module.scss';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'n4/footer' });

const FooterContainer = ({ blok, story, ...props }) => {
  log.debug('blok: %o', blok);
  // components/footer is the page itself; making it work so you can see your
  // changes in sb without having to go to a real page.
  return (
    <section className={cx(styles['n4-footer-container'], blok.className)} {...storyblokEditable(blok)}>
      <section>
        {blok.blocks
          ? blok.blocks.filter((b) => !b.hidden).map((b) => <StoryblokComponent blok={b} story={story} {...props} key={b._uid} {...storyblokEditable(b)} />)
          : null}
      </section>
    </section>
  );
};

export default FooterContainer;
