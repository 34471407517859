export const LogoSvg = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 28" fill="none" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M2.80889 20.935C-0.56485 15.7965 -0.152614 8.19718 4.01921 3.73891C5.77463 1.86241 9.45695 -0.588868 11.9209 1.43368C12.5817 1.98338 13.0538 2.72628 13.2707 3.55801C13.8931 6.19632 11.5577 8.70553 10.0591 11.203C9.38697 12.2739 8.96501 13.4827 8.82457 14.7392C8.68413 15.9958 8.82881 17.2678 9.24786 18.4607C9.66692 19.6536 10.3496 20.7367 11.245 21.6294C12.1404 22.5221 13.2256 23.2013 14.4198 23.6167C16.1031 24.2447 16.4749 25.8192 15.4945 26.6691C15.14 26.9493 14.7305 27.1517 14.2927 27.2632C13.8549 27.3747 13.3985 27.3929 12.9532 27.3164C8.96981 26.9311 4.961 24.2117 2.80889 20.9374V20.935Z"
      fill="#4A7FE5"
    />
    <path
      // eslint-disable-next-line max-len
      d="M15.7954 20.0346C17.6547 20.0346 19.1621 18.5273 19.1621 16.6679C19.1621 14.8086 17.6547 13.3013 15.7954 13.3013C13.936 13.3013 12.4287 14.8086 12.4287 16.6679C12.4287 18.5273 13.936 20.0346 15.7954 20.0346Z"
      fill="#4A7FE5"
    />
  </svg>
);
