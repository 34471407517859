import React, { useEffect, useMemo, useState } from 'react';

// top-level container for N4 pages
import * as Popover from '@radix-ui/react-popover';
import { StoryblokComponent, useStoryblokState } from '@storyblok/react';
import NextLink from 'next/link';

import { Gallery } from 'components/common-n4/gallery';
import ImageWrapper from 'components/common-n4/image';
import { ShareButtons } from 'components/common-n4/share-buttons';
import PriceLink from 'components/widgets/price-link';
import { useApiData } from 'hooks';
import { WINDOW_DIMENSION_TYPE, useWindowDimensions } from 'hooks/use_window_dimensions';
import HTLogo from 'images/hearing-tracker-logo.svg';
import IconInfo from 'images/icon-info.svg';
import IconSoundWave from 'images/icon-soundwave.svg';
import logger from 'lib/logger';
import { findBlok, loadRelease } from 'lib/storyblok';
import { cx, getWindowDimensionType, tw } from 'lib/utils';

import styles from './product-container.module.scss';

import { Accessories } from './accessories';
import { CallToActionBar } from './call-to-action-bar';
import { CustomerReviews } from './customer-reviews';
import { ExpertReview } from './expert-review';
import { ModelComparison } from './model-comparison';
import { ProductScorecard } from './product-scorecard';
import { Section } from './section';
import { SoundPerformanceLabResults } from './sound-performance-lab-results';

import { Tooltip, TooltipSubtitle } from '../tooltip';

const log = logger({ category: 'n4/ProductContainer' });

const TopRated = ({ className }) => (
  <div className={tw(styles['top-rated'], 'flex h-[48px] w-[150px] items-center gap-2 px-3', className)}>
    <HTLogo />
    <div className="flex flex-col">
      <span className="-mt-[2px] text-[15px] font-[600] -tracking-[0.1]">Excellent</span>
      <span className="text-[12px] leading-tight tracking-tight">Expert Reviewed</span>
    </div>
  </div>
);

const ShowAllPhotos = ({ onClick, className }) => (
  <button type="button" onClick={onClick} className={tw('rounded-xl border border-gray bg-white px-6 py-3', className)}>
    <span className="text-md font-medium tracking-tight text-black">View Photos</span>
  </button>
);

const MediaCount = ({ onClick, className, total }) => (
  <div className={tw('rounded-3xl bg-white px-[10px] py-1 ', className)} onClick={onClick}>
    <span className="text-base font-medium md:text-sm">1/{total}</span>
  </div>
);

const getHeaderImages = ({ story, release }) => {
  const productConfig = findBlok(story, 'n4-product-config');
  if (productConfig && productConfig?.header_images?.length) {
    return productConfig.header_images;
  }
  return release?.header_images;
};

const ProductContainer = ({ initialStory, product, ...props }) => {
  const [release, setRelease] = useState(product);
  const story = useStoryblokState(initialStory);
  const api = useApiData();
  const [headerImages, setHeaderImages] = useState(getHeaderImages({ story, release }));
  const fillImages = release.header_images_fill_containers === true;
  const [galleryShowing, setGalleryShowing] = useState(false);
  log.debug('release: %o', release);

  const { width } = useWindowDimensions();
  const dimensionType = getWindowDimensionType(width);

  useEffect(() => {
    const fn = async () => {
      await loadRelease({ story, api, log });
      setRelease(api.release);
      setHeaderImages(getHeaderImages({ release: api.release, story }));
    };
    fn();
  }, [story, api]);

  const onGalleryClose = () => {
    setGalleryShowing(false);
  };

  // This will contain all images from the page eventually
  const media = useMemo(
    () => headerImages?.map((image) => ({ caption: image.caption || release.full_name, image, type: 'ht-image' })),
    [headerImages, release.full_name]
  );

  const hearingAidsWithScores = useMemo(() => release.hearing_aids.filter((hearingAid) => hearingAid.scores.total_score), [release.hearing_aids]);

  const shouldDisplaySoundPerformance = useMemo(() => {
    // TODO: probably should not rely on this being first - find the one with audios, should also be primary: true
    const hearingAid = release.hearing_aids[0];
    return !!hearingAid?.scores.noisy && !!hearingAid?.scores?.quiet && !!hearingAid?.scores?.music;
  }, [release.hearing_aids]);

  return (
    <div className="product-container">
      <section className="product-page-header container lg:max-w-none">
        <div className="flex items-center justify-between pb-6 sm:mt-5">
          <div className="flex items-center gap-4">
            {release.image && (
              <figure className="rounded-[10px] bg-white p-1 shadow-xs">
                <ImageWrapper
                  image={release.image}
                  sizingProps={{ fixed: true, fixedWidth: 64, fixedHeight: 64 }}
                  imgProps={{ alt: release.full_name, className: '' }}
                  origin="product-container"
                />
              </figure>
            )}
            <div>
              <span className="block text-[24px] font-medium leading-7 tracking-tight text-navy">{release.full_name}</span>
              <span className="mt-[0.4rem] block text-lg tracking-tight text-lapis md:mt-[0.15rem]">
                <PriceLink release={release} price={release.price} origin="product-container" position="product-page-top-link" />
              </span>
            </div>
          </div>
          <div className="hidden flex-col gap-3 lg:flex">
            <span className="text-base leading-5 text-navy">Share</span>
            <ShareButtons.Primary />
          </div>
        </div>
        {headerImages &&
          (fillImages ? (
            <div className="relative flex max-h-[320px] gap-4 laptop:max-h-[480px] desktop-xl:max-h-[600px]">
              {headerImages.slice(0, 2).map((image, index) => (
                <div
                  className={cx(
                    index === 0 ? 'flex-[1_0_100%] desktop-xl:flex-[1_0_58%]' : 'flex-[1_1_0%] desktop-xl:flex-[1_0_42%]',
                    'flex justify-center rounded-2xl bg-white shadow-xs md:flex-1'
                  )}
                  key={image.imgix || image._uid || image.id}
                >
                  <ImageWrapper
                    image={image}
                    imgProps={{ loading: 'eager', className: 'object-cover w-full h-full rounded-2xl' }}
                    origin="product-container-header"
                  />
                  {index === 1 && (
                    <>
                      <div className="absolute bottom-0 right-0 top-0 flex flex-col items-end justify-between">
                        <TopRated className={tw('mr-2.5 mt-3.5 md:mr-[7px]', (release.score || 0) < 4 && 'invisible')} />
                        <ShowAllPhotos
                          className="mb-5 hidden md:mr-5 md:block"
                          onClick={() => {
                            setGalleryShowing(true);
                          }}
                        />
                      </div>
                      <div
                        className="absolute bottom-0 left-0 right-0 top-0 mb-5 mr-2.5 flex cursor-pointer items-end justify-end md:hidden"
                        onClick={() => {
                          setGalleryShowing(true);
                        }}
                      >
                        <MediaCount total={headerImages.length} />
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="relative flex max-h-[320px] gap-4 laptop:max-h-[480px]">
              {headerImages.slice(0, 2).map((image, index) => (
                <div
                  className={cx(
                    index === 0 ? 'flex-[1_0_100%] desktop-xl:flex-[1_0_58%]' : 'flex-[1_1_0%] desktop-xl:flex-[1_0_41%]',
                    'flex justify-center rounded-2xl bg-white py-8 shadow-xs lg:flex-1 laptop:py-10'
                  )}
                  key={image.imgix || image._uid || image.id}
                >
                  <ImageWrapper image={image} imgProps={{ loading: 'eager', className: 'object-contain' }} origin="product-container-header" />
                  {index === 1 && (
                    <>
                      <div className="absolute bottom-0 right-0 top-0 flex flex-col items-end justify-between">
                        <TopRated className={tw('mr-2.5 mt-3.5 lg:mr-[10px]', (release.score || 0) < 4 && 'invisible')} />
                        <ShowAllPhotos
                          className="mb-5 hidden lg:mr-5 lg:block"
                          onClick={() => {
                            setGalleryShowing(true);
                          }}
                        />
                      </div>
                      <div
                        className="absolute bottom-0 left-0 right-0 top-0 mb-5 mr-2.5 flex cursor-pointer items-end justify-end lg:hidden"
                        onClick={() => {
                          setGalleryShowing(true);
                        }}
                      >
                        <MediaCount total={headerImages.length} />
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          ))}
      </section>
      <div className={cx(styles['n4-product-container'], 'container lg:max-w-[1344px]')}>
        <section>
          <div
            className={`flex flex-col gap-10 product-sidebar:flex-row product-sidebar:justify-between ${
              headerImages.length > 0 ? 'mt-8 laptop:mt-10' : 'mt-2 laptop:mt-10'
            }`}
          >
            <section
              className={tw(
                styles.article,
                'order-2 max-w-[640px] lg:max-w-[600px] laptop:max-w-[640px] product-sidebar:order-none product-sidebar-down:max-w-[520px]',
                'article-within-product'
              )}
            >
              {story && <StoryblokComponent blok={story.content} story={story} {...props} />}
            </section>
            <section className={tw(styles.scorecard, 'order-1')}>
              <ProductScorecard release={release} className="sticky top-[80px] sm:min-w-[376px]" />
            </section>
          </div>
        </section>

        {hearingAidsWithScores?.length > 0 && (
          <Section title="Expert Review" id="expert-review">
            <ExpertReview hearingAids={hearingAidsWithScores} models={release.models} />
          </Section>
        )}

        {shouldDisplaySoundPerformance && (
          <section className="mt-20 border-t border-neutral-300 pt-12">
            <div className="pb-8">
              <h2 className="text-[24px] font-medium text-navy lg:text-[2rem]">Sound Performance Lab Results</h2>
              <span className="flex items-center text-base -tracking-[0.48px] text-neutral-600">
                Recordings provided by <IconSoundWave className="ml-1.5 mr-[3px] inline" />{' '}
                <span className="font-bold text-navy antialiased">
                  <NextLink className="text-navy hover:underline" target="_blank" href="https://www.hearadvisor.com">
                    {' '}
                    HearAdvisor
                  </NextLink>
                </span>
                <Popover.Root>
                  <Popover.Trigger asChild>
                    <button className="ml-1.5" type="button">
                      <IconInfo />
                    </button>
                  </Popover.Trigger>
                  <Popover.Portal>
                    <Popover.Content sideOffset={5} side="top">
                      <Tooltip>
                        <TooltipSubtitle>
                          HearAdvisor provides audio recordings and objective laboratory performance data. All hearing aids are fitted and performance-tested
                          for mild sloping to moderate hearing loss. All audio samples cutoff above 10kHz.
                        </TooltipSubtitle>
                      </Tooltip>
                    </Popover.Content>
                  </Popover.Portal>
                </Popover.Root>
              </span>
            </div>
            <SoundPerformanceLabResults release={release} labResultImages={release.lab_result_images || []} />
          </section>
        )}

        <Section title={`${release.full_name} Model Comparison`}>
          <ModelComparison release={release} />
        </Section>

        {release.models.some(({ accessories }) => accessories.length > 0) && (
          <Section title="Accessories" titleClassName="pb-0">
            <Accessories models={release.models} releaseName={release.full_name} />
          </Section>
        )}

        <Section>
          <CustomerReviews
            models={release.models}
            levels={release.levels}
            releaseName={release.full_name}
            releaseSlug={release.slug}
            image={release.image}
            userScore={release.user_score}
          />
        </Section>
      </div>
      <Gallery isOpen={galleryShowing} onClose={onGalleryClose} media={media} />
      {dimensionType === WINDOW_DIMENSION_TYPE.mobile && release.price && <CallToActionBar release={release} releaseName={release.full_name} />}
    </div>
  );
};

export default ProductContainer;
