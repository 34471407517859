const VALID_BUTTON_PROPS = [
  'autoFocus',
  'disabled',
  'form',
  'formAction',
  'formEncType',
  'formMethod',
  'formNoValidate',
  'formTarget',
  'type',
  'value',
  'onClick',
  'onFocus',
  'onBlur',
  'onMouseDown',
  'onMouseUp',
  'onMouseEnter',
  'onMouseLeave',
  'onKeyDown',
  'onKeyUp',
  'onKeyPress',
  'accessKey',
  'className',
  'id',
  'lang',
  'dir',
  'tabIndex',
  'title',
  'data-testid',
  'aria-label',
  'aria-labelledby',
];

const AUDIO_ENVIRONMENTS = {
  NOISY: 'noisy',
  QUIET: 'quiet',
  MUSIC: 'music',
};

export const HEARING_AID_MODEL_SCORE_TOTAL = 5;

export { VALID_BUTTON_PROPS, AUDIO_ENVIRONMENTS };
