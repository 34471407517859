import React, { useCallback, useEffect, useRef, useState } from 'react';

import logger from 'lib/logger';

const log = logger({ category: 'youtube' });

const onPlayerReady = (event) => {
  // event.target is the player
  const player = event.target;
  const videoId = player.getVideoData().video_id;
  if (new URLSearchParams(window.location.search).get('resize') === 'true') {
    const containerId = `c-${videoId}`;
    const el = document.getElementById(containerId);
    if (el) {
      el.style.width = '';
      el.style.height = '';
      el.style.display = 'block';
    }
  }
};

let counter = 0;

export const GalleryYoutube = ({ emblaApi, videoId, index }) => {
  const [ready, setReady] = useState(false);
  const playerRef = useRef(null);

  const onSelect = useCallback(() => {
    if (!ready) {
      return;
    }
    if (!playerRef.current && index === emblaApi.selectedScrollSnap()) {
      playerRef.current = new window.YT.Player(`c-${videoId}`, {
        videoId,
        playerVars: {
          playsinline: 1,
          enablejsapi: 1,
        },
        events: {
          onReady: onPlayerReady,
        },
      });
    }
    if (index === emblaApi.previousScrollSnap() && index !== emblaApi?.selectedScrollSnap()) {
      playerRef.current.pauseVideo();
    }
  }, [ready, index, emblaApi, videoId]);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect();
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  useEffect(() => {
    if (!videoId) {
      return;
    }

    if (window.youtubeReady) {
      setReady(true);
    } else {
      window.onYouTubeIframeAPIReady = () => {
        window.youtubeReady = true;
        log.debug('youtube ready');
        setReady(true);
      };
      // In case there are multiple videoes, our ready callback is only
      // called once so we need to keep checking for a bit.
      const checkFn = () => {
        if (window.youtubeReady) {
          setReady(true);
        } else {
          counter += 1;
          if (counter < 10) {
            setTimeout(checkFn, 1000);
          } else {
            // TODO: need to do better than this
            // We don't start the clock until they scroll over one
            // so we can't use a timer. Need some way of notifying
            // all videos that it's ready. Just assuming it is for now.
            setReady(true);
          }
        }
      };
      setTimeout(checkFn, 2000);
    }
  }, [videoId]);

  return (
    <div className="aspect-video overflow-hidden rounded-2xl">
      <div id={`c-${videoId}`} className="video_container h-full w-[800px] max-w-full" />
    </div>
  );
};
