import * as React from 'react';

import { CircleSpinner } from 'components/bloks/n4/images/circle-spinner';
import { Button } from 'components/common-n4/button';

import { ProviderPopupLayout } from '../../layout';
import { ContentProps } from '../../types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Searching: React.FC<ContentProps> = ({ dispatch, state }) => {
  return (
    <ProviderPopupLayout>
      <div className="flex min-h-[43vh] flex-col items-center justify-center px-7 py-10 lg:h-full lg:min-h-0 lg:px-10 lg:py-6">
        <div className="flex flex-1 flex-col items-center justify-center gap-7">
          <CircleSpinner size="132" />
          <div className="text-base font-light leading-5 tracking-tight text-navy-90">Searching for local brand providers</div>
        </div>
        <div className="self-stretch">
          <Button.LinkSecondary
            id={'submit-button'}
            className="!w-full !justify-center !px-6 !py-[10px] !text-base !tracking-tight !text-navy"
            // onClick={handleSubmit}
            // isDisabled={state.step === Step.searching}
          >
            Cancel
          </Button.LinkSecondary>
        </div>
      </div>
    </ProviderPopupLayout>
  );
};
