import { storyblokEditable } from '@storyblok/react';

import ImageWrapper from 'components/common-n4/image';
import logger from 'lib/logger';

import styles from './image.module.scss';

const log = logger({ category: 'hero' });

const N4Ad = ({ blok }) => {
  log.debug('blok: %o', blok);

  return (
    <section className={styles['n4-image']} {...storyblokEditable(blok)}>
      <figure className={styles.image}>
        <ImageWrapper
          image={blok.image || blok.filename}
          imgProps={{
            alt: blok.image?.alt,
            loading: 'eager',
            style: { width: 'auto', height: '100%', borderRadius: '1.25rem' },
          }}
          origin="n4-ad"
        />
      </figure>
    </section>
  );
};

export default N4Ad;
