// Hero element container for N4 pages
import { storyblokEditable } from '@storyblok/react';

import { Button } from 'components/common-n4/button';
import ImageWrapper from 'components/common-n4/image';
import logger from 'lib/logger';
import { findBlok } from 'lib/storyblok';
import { cx } from 'lib/utils';

import styles from './hero.module.scss';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'n4/Hero' });

const Container = ({ blok, story, ...props }) => {
  log.debug('blok: %o', { blok, story, props });

  if (!blok?.image?.filename) return null;

  // If the page doesn't have an article, the hero title is an h1 for SEO
  const useH1 = !findBlok(story, 'article');

  const handleScrollToArticle = () => {
    const articleSection = document.getElementById('article');

    if (!articleSection) return;

    window.scrollTo({
      top: articleSection.offsetTop,
      behavior: 'smooth',
    });
  };

  const { image } = blok;

  const imagePosition = blok?.image_position?.replace(';', '');

  return (
    <section className={cx(styles['n4-hero'], blok.className, blok.component)} {...storyblokEditable(blok)}>
      <figure className={styles.image}>
        <ImageWrapper
          image={image}
          imgProps={{ loading: 'eager', alt: image.alt || blok.title, style: { width: '100%', height: '100%', objectPosition: imagePosition || 'center' } }}
          origin="n4-hero"
        />
      </figure>
      <section className={styles.content}>
        {blok.page_type_text ? <span className={styles['top-title']}>{blok.page_type_text}</span> : null}
        <section className={styles['content-data']}>
          {useH1 ? <h1 className={styles.title}>{blok.title || story.name}</h1> : <span className={styles.title}>{blok.title}</span>}
          <span className={styles.subtitle}>{blok.subtitle}</span>
          <section className={styles.action}>
            <span className={styles.text}>Jump to Article</span>
            <Button.Icon onClick={handleScrollToArticle} className={styles.jump} icon="arrow-down-regular" />
          </section>
        </section>
      </section>
      <section className={styles.teaser}>
        <p className={styles['teaser-text']}>{blok.teaser_text}</p>
        <section className={styles.action} onClick={handleScrollToArticle}>
          <span className={cx(styles.text, styles['text--alt'])}>Continue Reading</span>
          <Button.Icon className={cx(styles.jump, styles['jump--alt'])} icon="arrow-down-regular" />
        </section>
      </section>
    </section>
  );
};

export default Container;
