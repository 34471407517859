import lodashMerge from 'lodash/merge';

import { cx } from 'lib/utils';

/**
 * `propped` is a function that lets you set default props on a component.
 * For a more advanced case, you might just want to return a function
 * and return the component.
 *
 * Takes a component and returns a new component that merges the props passed to
 * the new component with the props passed to the original component.
 *
 */
export const propped = (Component, baseProps, merge = lodashMerge) => {
  const StyledComponent = (nextProps) => {
    const props = merge({}, baseProps, nextProps);

    // merge classNames in a slightly different way
    props.className = cx(baseProps?.className, nextProps?.className);

    return <Component {...props} />;
  };

  const componentName = Component.displayName || Component.name || 'Component';

  StyledComponent.displayName = `Propped(${componentName})`;

  return StyledComponent;
};
