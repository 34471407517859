import { storyblokEditable, type SbBlokData } from '@storyblok/react';

import { ImageSource } from 'components/common-n4/image/types';
import { cx } from 'lib/utils';

import styles from './multi-cta.module.scss';

import { FeatureCtaCard } from '../feature-cta-card/feature-cta-card';

export interface MultiCtaCardProps extends SbBlokData {
  image: ImageSource;
  link: {
    cached_url: string;
  };
  link_text: string;
  title: string;
  wide: boolean;
}

interface MultiCtaCardsProps {
  cards: MultiCtaCardProps[];
}

export const MultiCtaCards: React.FC<MultiCtaCardsProps> = ({ cards }) => {
  return (
    <div className={styles.container}>
      {cards.map((card: MultiCtaCardProps) => (
        <div key={card._uid} className={cx(styles.card, styles[`${card.wide ? 'card--wide' : 'card--narrow'}`])} {...storyblokEditable(card)}>
          <FeatureCtaCard extended {...card} hint={card.link_text} url={card.link.cached_url} />
        </div>
      ))}
    </div>
  );
};
