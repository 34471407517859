import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import logger from 'lib/logger';

const log = logger({ category: 'NestedBlocks' });

const NestedBlocks = ({ blok, story, variant, ...props }) => {
  const trackingProps = {};
  let bloks = blok.blocks ? blok.blocks.filter((b) => !b.hidden) : [];
  if (blok.ab_test_name) {
    const index = variant.charCodeAt() - 'A'.charCodeAt();
    bloks = [bloks[index]];
    trackingProps['data-gtm_ab_test_name'] = blok.ab_test_name;
    trackingProps['data-gtm_ab_test_variant'] = variant;
  }
  log.debug('variant: %o', { variant, num: bloks.length, test: blok.ab_test_name });
  return (
    <div className={`ht-nested-blocks ${blok.className || ''}`}>
      {bloks.map((b) => (
        <div key={b._uid} {...trackingProps}>
          <StoryblokComponent blok={b} story={story} {...props} {...storyblokEditable(b)} />
        </div>
      ))}
    </div>
  );
};

export default NestedBlocks;
