// Not currently used, but there is a storyblok blok for it
// Need to fix price if we are going to use
import { useEffect, useRef, useState } from 'react';

import { storyblokEditable } from '@storyblok/react';
import NextLink from 'next/link';

import HtLink from 'components/common-n4/ht-link';
import ImageWrapper from 'components/common-n4/image';
import PopoverIcon from 'components/ui/popover_icon';
import Stars from 'components/widgets/stars';
import { useWindowDimensions } from 'hooks/use_window_dimensions';
import logger from 'lib/logger';

import styles from './slider.module.scss';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'FeaturedReleases' });

const FeaturedReleases = ({ blok, story, featuredReleases, ...props }) => {
  const [hideCurrency, setHideCurrency] = useState(false);
  log.silly('blok: %o, story: %o, props: %o', blok, story, props);
  const imageTemplate = blok.imageTemplate ? blok.imageTemplate[0] : {};
  const { width: windowWidth } = useWindowDimensions();
  const [slidePosition, setSlidePostion] = useState(0);
  const [countIterations, setCountIterations] = useState(0);
  const wrapperDiv = useRef();
  const scrollDiv = useRef();
  let slideWidth;
  const scrollWidth = () => {
    if (windowWidth > 320) {
      slideWidth = 320;
    }
    // if (windowWidth > 767) {
    //   slideWidth = 300;
    // }
    if (windowWidth > 991) {
      slideWidth = 420;
    }
    return featuredReleases.length * slideWidth;
  };
  let leftPadding;
  if (windowWidth > 320) leftPadding = 20;
  if (windowWidth > 575) leftPadding = (windowWidth - 540) / 2 + 12;
  if (windowWidth > 767) leftPadding = (windowWidth - 720) / 2 + 12;
  if (windowWidth > 991) leftPadding = (windowWidth - 960) / 2 + 12;
  if (windowWidth > 1199) leftPadding = (windowWidth - 1140) / 2 + 12;
  if (windowWidth > 1399) leftPadding = (windowWidth - 1200) / 2 + 12;

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 5;
  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };
  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const iterationsNum = Math.floor((scrollWidth() - (windowWidth - leftPadding)) / slideWidth);
  const lastIteration = Math.abs((iterationsNum - (scrollWidth() - (windowWidth - leftPadding)) / slideWidth) * slideWidth);
  function nextSlide() {
    if (iterationsNum === 0) {
      setSlidePostion(lastIteration);
      setCountIterations(countIterations + 1);
      return;
    }
    if (countIterations < iterationsNum) {
      setSlidePostion(slidePosition + slideWidth);
      setCountIterations(countIterations + 1);
    } else if (countIterations === iterationsNum) {
      console.warn(slidePosition, lastIteration);
      setSlidePostion(slidePosition + lastIteration);
      setCountIterations(countIterations + 1);
    } else {
      setSlidePostion(slidePosition);
    }
  }
  function prevSlide() {
    if (iterationsNum >= 0) {
      setSlidePostion(slidePosition - slideWidth);
      setCountIterations(countIterations - 1);
    } else if (countIterations === iterationsNum) {
      setSlidePostion(slidePosition - lastIteration);
      setCountIterations(countIterations - 1);
    }
  }
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      nextSlide();
    }
    if (isRightSwipe) {
      prevSlide();
    }
  };
  useEffect(() => {
    if (slidePosition < 0) {
      setSlidePostion(0);
      setCountIterations(0);
    }
    if (typeof navigator !== 'undefined') {
      setHideCurrency(navigator.language === 'en-US');
    }
  }, [slidePosition]);
  return (
    <section {...storyblokEditable(blok)} className="ht-featured-releases">
      <div className="ht-featured-releases-wrapper">
        <header className="ht-featured-releases-section_header">
          <h2>{blok.title}</h2>
          <p className="h3">{blok.subtitle}</p>
        </header>
      </div>

      <article>
        <div
          className={styles.releaseSlider}
          ref={wrapperDiv}
          onTouchStart={(e) => onTouchStart(e)}
          onTouchMove={(e) => onTouchMove(e)}
          onTouchEnd={(e) => onTouchEnd(e)}
        >
          <div
            ref={scrollDiv}
            className={styles.scrollDiv}
            style={{
              width: scrollWidth(),
              transform: `translate3d(-${slidePosition}px, 0px, 0px)`,
            }}
          >
            {(featuredReleases || []).map((release) => (
              <div key={release.id} className={styles.releaseSlideWrap}>
                <div className={`${styles.featuredReleasesCard} ht-featured-releases-card`}>
                  <div className="ht-featured-releases-card-image">
                    <figure className="media">
                      <HtLink href={release.path} data-event-name="featured-release--click" data-event-release-id={release.id} data-event-button="image">
                        <ImageWrapper image={imageTemplate || release.image} imgProps={{ alt: release.name }} origin="featured-releases" />
                      </HtLink>
                    </figure>
                  </div>

                  <div className="ht-featured-releases-card-body">
                    <header>
                      <h3>{release.name}</h3>
                    </header>
                    <div className="price-wrapper" style={{ visibility: release.formatted_price ? 'visible' : 'hidden' }}>
                      <span className="price">
                        {release.formatted_price} {!hideCurrency && <span className="currency">{release.currency}</span>}
                      </span>
                      <PopoverIcon
                        title="Average retail prices"
                        className="info-mark"
                        content={`
                          <div>
                            Average retail <strong>price for one</strong> unit for the lowest technology level.
                            Based on prices submitted by 25 local hearing healthcare practices. Submitted prices
                            are typically inclusive of generous warranties and local services.
                          </div>
                        `}
                      />
                    </div>
                    <p style={{ visibility: release.formatted_price ? 'visible' : 'hidden' }}>
                      <span className="new-badge">New</span>
                      <span className="average-savings">Average savings of {release.average_savings}%</span>
                    </p>
                    <div className="rating">
                      {release.review_count > 0 && release.user_score && (
                        <>
                          <Stars max={5} value={release.user_score} count={release.review_count} />
                          <p className="review-count">{`${release.review_count} review${release.review_count === 1 ? '' : 's'}`}</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.sliderControls}>
          <div className={styles.prevSlide} onClick={prevSlide} />
          <div className={styles.nextSlide} onClick={nextSlide} />
          {/* <div className="featured-release-scrollbar" /> */}
          <div className={styles.sliderCta}>
            <NextLink prefetch={false} href="/hearing-aids">
              View all reviews
            </NextLink>
          </div>
        </div>
      </article>
    </section>
  );
};

export default FeaturedReleases;
