import { storyblokEditable } from '@storyblok/react';

import ImageWrapper from 'components/common-n4/image';
import logger from 'lib/logger';
import { cx } from 'lib/utils';

// Can share from container if there are not many styles, otherwise use separate module per component
import styles from './home-container.module.scss';

const log = logger({ category: 'n4/home/hero-carousel-item' });

const HeroCarouselItem = ({ blok, story, ...props }) => {
  log.debug('blok: %o', { blok, props });
  return (
    <section className={cx(styles['hero-carousel-item'], blok.className)} {...storyblokEditable(blok)} style={{ border: '1px solid magenta', margin: 40 }}>
      <h3>Hero Carousel Item</h3>
      <ul>
        <li>title: {blok.title}</li>
        <li>small text: {blok.small_text}</li>
        <li>url: {blok.link.cached_url}</li>
      </ul>
      <ImageWrapper image={blok.image} imgProps={{ style: { width: 200, height: 'auto' } }} origin="home-hero-carousel-item" />
    </section>
  );
};

export default HeroCarouselItem;
